import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import moment from "moment";

import {APP_CONST} from "../../../../../AppComponents/AppConstants";
import {isEmpty} from "../../../../../core/components/DLComponentHelper";

import {
    setFromDate,
    setSearchKeyword,
    setToDate,
    resetClientListingFilters,
    setSkipCount,
    setLimitCount,
    setPageCount,
    setClientsListingRowPerPageCount,
    getClientListing,
    setCategory,
    setCRMForListing,
    setAccountType,
    setLocationForListing
} from "../../clientListingAction";
import {setLeadsRowPerPageCount} from "../../../../../leads/LeadsActions";
import {
    getAccountTypeOptions,
    getCategoryOptions
} from "../../../../ClientsActions";

import ListingForManagementView from "./ListingForManagementView";
import {getCRMs, getLocations} from "../../../../ClientsActions";

const ListingForManagement = (props) => {
    const [clientsListingState, setClientsListingState] = useState({
        clientList: [],
        listingCount: 0,
        skipCount: 0,
        limitCount: 50,
        isShowFilterMobile: false,

        UIState: '',
        errorInApi: '',
        isShowLoader: false,
        filters: {
            keyword: '',
            fromDate: '',
            toDate: '',
            category: [],
            type: [],
            location: [],
            CRMId: []
        },
        accountCategories: [],
        accountTypesDropdownValues: [],
        accountCategoriesDropdownValues: [],
        CRMDropdownValues: [],
        locationDropdownValues: []
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    useEffect(() => {
        getMasterData()
    }, []);

    useEffect(() => {
        getClientList();
    }, [props.clientsFilter]);

    const getMasterData = () => {
        getCategoryOptions()
            .then(categoryRes => {
                if (categoryRes.success) {
                    const categoryData = categoryRes?.data?.categoryType;
                    getAccountTypeOptions()
                        .then(accountTypeRes => {
                            if (accountTypeRes.success) {
                                const accountTypeData = accountTypeRes?.data?.accountType;
                                getCRMs().then((CRMRes) => {
                                    if (CRMRes?.success) {
                                        const CRMData = CRMRes?.data?.advisoryUsers;

                                        getLocations().then((locationsRes) => {
                                            if (locationsRes?.success) {
                                                const locationData = locationsRes?.data?.locations;

                                                setClientsListingState(prevState => ({
                                                    ...prevState,

                                                    accountCategories: categoryData,
                                                    accountTypesDropdownValues: setValuesForDropdown(accountTypeData, "name", "id"),
                                                    accountCategoriesDropdownValues: setValuesForDropdown(categoryData, "name", "id"),
                                                    CRMDropdownValues: setValuesForCRMDropdown(CRMData, "firstName", "id"),
                                                    locationDropdownValues: setValuesForDropdown(locationData, "description", "id"),
                                                }))
                                            }
                                        })
                                    }})
                            }
                        })
                }
            })
    };

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        let list = [{label: "All", value: "ALL"}]
        if (isEmpty(data)) {
            return;
        }
        data.map((item) => {
            list.push({label: item?.[labelToken], value: item?.[valueToken]})
        });
        return list;
    };

    const setValuesForCRMDropdown = (data, labelToken, valueToken) => {
        let list = [{label: "All", value: "ALL"}]
        if (isEmpty(data)) {
            return;
        }
        data.map((item) => {
            list.push({label: item?.[labelToken] + " " + item?.lastName, value: item?.[valueToken]})
        });
        return list;
    };

    const resetClientListing = () => {
        resetClientListingFilters();
    };

    const handleToggleMobileFilter = (value) => {
        setClientsListingState((prevStep) => ({
            ...prevStep,
            isShowFilterMobile: value
        }));
    };

    const getPaginationNextButtonStatus = () => {
        const {listingCount, skipCount, limitCount} = clientsListingState;
        let val = false;
        if (listingCount <= rowsPerPage) {
            val = true;
        } else if ((skipCount + limitCount) >= listingCount) {
            val = true;
        }
        return val;
    };

    const resetPagination = () => {
        setLeadsRowPerPageCount(50);
        setLimitCount(50);
        setPageCount(0);
        setSkipCount(0);
    };

    const handleChangeRowsPerPage = (event) => {
        setClientsListingState(prevState => ({
            ...prevState,
            isSearchFilterUpdated: true
        }));
        setClientsListingRowPerPageCount(parseInt(event.target.value, 10));
        setLimitCount(parseInt(event.target.value, 10));
        setPageCount(0);
        setSkipCount(0);
    };

    const handleChangePage = (event, newPage) => {
        let {limitCount, skipCount} = clientsListingState;
        const isForward = newPage > page;
        if (isForward) {
            skipCount = skipCount + rowsPerPage;
            limitCount = rowsPerPage
        } else {
            skipCount = skipCount - rowsPerPage;
            limitCount = rowsPerPage
        }

        setSkipCount(skipCount);
        setLimitCount(limitCount);
        setPageCount(newPage);
    };

    const generateFilter = (filter) => {
        let filterObject = {};
        if (!isEmpty(filter.keyword)) {
            filterObject.keyword = filter.keyword;
        }
        if (!isEmpty(filter.toDate)) {
            filterObject.toDate = filter.toDate;
        }
        if (!isEmpty(filter.fromDate)) {
            filterObject.fromDate = filter.fromDate;
        }
        if (!isEmpty(filter.CRMId) && filter.CRMId[0] !== "ALL") {
            filterObject.CRMId = filter.CRMId;
        }
        if (!isEmpty(filter.category) && filter.category[0] !== "ALL") {
            filterObject.category = filter.category;
        }
        if (!isEmpty(filter.type) && filter.type[0] !== "ALL") {
            filterObject.type = filter.type;
        }
        if (!isEmpty(filter.location) && filter.location[0] !== "ALL") {
            filterObject.location = filter.location;
        }
        return filterObject;
    };

    const getClientList = () => {
        const {clientsFilter} = props;

        setClientsListingState(prevState => ({
            ...prevState,
            isShowLoader: true,
        }));

        let payload = {
            "skipCount": clientsFilter.skipCount,
            "limitCount": clientsFilter.limitCount,
            "filters": generateFilter(clientsFilter)
        };

        getClientListing(payload)
            .then(res => {
                if (res?.success) {
                    setClientsListingState(prevState => ({
                        ...prevState,
                        clientList: res.data?.accounts,
                        listingCount: res.data?.count,
                        skipCount: clientsFilter.skipCount,
                        limitCount: clientsFilter.limitCount,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_AVAILABLE,
                    }));
                    setPage(isEmpty(clientsFilter.page) ? 0 : clientsFilter.page);
                    setRowsPerPage(isEmpty(clientsFilter.rowsPerPage) ? 50 : clientsFilter.rowsPerPage)

                } else {
                    setClientsListingState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    }));
                }
            })
    };

    const onChangeFilter = (name, value) => {
        resetPagination();
        if (name === 'keyword') {
            setClientsListingState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    keyword: value,
                }
            }));
            setSearchKeyword(value);
            return;
        }

        if (name === 'fromDate') {
            let fromDate = isEmpty(value) ? null : moment(value).format('YYYY-MM-DD');
            setClientsListingState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    fromDate: fromDate
                }
            }));
            setFromDate(fromDate);
            return;
        }

        if (name === 'toDate') {
            let toDate = isEmpty(value) ? null : moment(value).format('YYYY-MM-DD');
            setClientsListingState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    toDate: toDate,
                }
            }));
            setToDate(toDate);
        }
        if (name === 'CRMId') {
            setClientsListingState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    CRMId: [value]
                }
            }));
            setCRMForListing([value]);
        }
        if (name === 'category') {
            setClientsListingState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    category: [value]
                }
            }));
            setCategory([value]);
        }
        if (name === 'type') {
            setClientsListingState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    type: [value]
                }
            }));
            setAccountType([value]);
        }
        if (name === 'location') {
            setClientsListingState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    location: [value]
                }
            }));
            setLocationForListing([value]);
        }
    };

    const getCategoryValue = (selectedStatus) => {
        const {accountCategories} = clientsListingState;
        if (isEmpty(selectedStatus) || isEmpty(accountCategories)) {
            return '-'
        }
        const status = accountCategories.find(status => status.id === selectedStatus);
        return status ? status.name : '-';

    };

    const getCRMNameById = (id) => {
        const {CRMDropdownValues} = clientsListingState
        let name = "";
        if (isEmpty(id)) {
            return "";
        }
        CRMDropdownValues.forEach((item) => {
            if (id === item?.value) {
                name = item?.label
            }
        })
        return name;
    };

    const handleRefreshButton=()=>{
        getMasterData();
        getClientList();
    };

    return (
        <ListingForManagementView
            {...props}
            {...clientsListingState}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangeFilter={onChangeFilter}
            handleChangePage={handleChangePage}
            resetClientListing={resetClientListing}
            handleRefreshButton={handleRefreshButton}
            handleToggleMobileFilter={handleToggleMobileFilter}
            getPaginationNextButtonStatus={getPaginationNextButtonStatus}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            getCategoryValue={getCategoryValue}
            getCRMNameById={getCRMNameById}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    clientsFilter: state.clientsState.clientsFilter,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(ListingForManagement);
