import {createSlice} from "@reduxjs/toolkit";

const appSlice = createSlice({
    name: 'NFTReducer',
    initialState: {
        NFTFilter: {
            keyword: '',
            fromDate: '',
            toDate: '',
            requestType:['ALL'],
            status: ['ALL'],
            CRMId: ['ALL'],
            skipCount: 0,
            limitCount: 50,
            page:0,
            rowsPerPage: 50
        },
        CRMDetails: []
    },
    reducers: {
        setSearchKeywordValue (state,action) {
            state.NFTFilter.keyword = action.payload;
        },

        setFromDateValue (state,action) {
            state.NFTFilter.fromDate = action.payload;
        },

        setToDateValue (state,action) {
            state.NFTFilter.toDate = action.payload;
        },

        setStatusValue (state,action) {
            state.NFTFilter.status = action.payload;
        },

        setTypeOfRequestValue(state, action) {
            state.NFTFilter.requestType = action.payload;
        },

        setCRMIdValue(state, action) {
            state.NFTFilter.CRMId = action.payload;
        },

        setSkipCountValue(state,action){
            state.NFTFilter.skipCount = action.payload;
        },

        setLimitCountValue(state,action){
            state.NFTFilter.limitCount = action.payload;
        },

        setPageValue(state,action){
            state.NFTFilter.page = action.payload;
        },

        setRowsPerPageValue(state,action){
            state.NFTFilter.rowsPerPage = action.payload;
        },

        resetAllFilters (state,action) {
            state.NFTFilter = {
                ...state.NFTFilter,
                keyword:'',
                fromDate:'',
                toDate:'',
                status:['ALL'],
                requestType:['ALL'],
                CRMId: ['ALL'],
                skipCount: 0,
                page:0,
            };
        },
        setCRMDetails(state,action) {
            state.CRMDetails = action.payload;
        },
    }
});

export const {setStatusValue, setSearchKeywordValue, setFromDateValue, setToDateValue, resetAllFilters, setCRMIdValue,
    setRowsPerPageValue, setPageValue,setLimitCountValue,setSkipCountValue,setTypeOfRequestValue} = appSlice.actions;
export default appSlice.reducer

