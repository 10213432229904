import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {isEmpty} from "../../../core/components/DLComponentHelper";
import {APP_CONST} from "../../../AppComponents/AppConstants";

import {
    setSearchKeyword, getPortfolioListing
} from "../../portfolioAction";

import DLLoader from "../../../core/components/DLLoader/DLLoader";
import PortfolioListingView from "./portfolioListingView";

const PortfolioListing = (props) => {
    const [portfolioListingState, setPortfolioListingState] = useState({
        portfolioListing: [],
        listingCount: 0,
        portfolioName: '',
        portfolioId: '',
        UIState: '',
        isShowLoader: false,
        filters: {
            keyword: ''
        },
        isDeletePortfolioModalOpen : false,
        isCopyPortfolioModalOpen : false
    });

    useEffect(() => {
        getPortfolios();
    }, [props.portfolioFilter]);

    const generateFilter = (filter) => {
        let filterObject = {};
        if (!isEmpty(filter.keyword)) {
            filterObject.keyword = filter.keyword;
        }
        return filterObject;
    };

    const getPortfolios = () => {
        const {profile, portfolioFilter} = props;

        setPortfolioListingState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            "filters": generateFilter(portfolioFilter)
        };

        getPortfolioListing(payload, profile.advisoryId)
            .then(res => {
                if (res?.success) {
                    setPortfolioListingState(prevState => ({
                        ...prevState,
                        portfolioListing: res?.data,
                        listingCount: res.data?.count,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_AVAILABLE,
                    }));
                } else {
                    setPortfolioListingState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    }));
                }
            })
    };

    const onChangeFilter = (name, value) => {
        if (name === 'keyword') {
            setPortfolioListingState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    keyword: value,
                }
            }));
            setSearchKeyword(value);
        }

    };

    const selectedOption = (value, id, portfolioName) =>{
        if(value === "DELETE"){
            handleOpenDeletePortfolioModal(id, portfolioName)
        }
        if(value === "COPY"){
            handleOpenCopyPortfolioModal(id, portfolioName)
        }
        if(value === "EDIT"){
            props.history.push("/createPortfolio-Management",{requestType : 'EXISTING', portfolioId : id})
        }
    }

    const handleOpenDeletePortfolioModal = (id, portfolioName) => {
        setPortfolioListingState((prevState) => ({
            ...prevState,
            isDeletePortfolioModalOpen : true,
            portfolioName: portfolioName,
            portfolioId: id
        }));
    };

    const handleCloseDeletePortfolioModal = () => {
        setPortfolioListingState((prevState) => ({
            ...prevState,
            isDeletePortfolioModalOpen : false,
            portfolioName: '',
            portfolioId: ''
        }));
    };

    const handleOpenCopyPortfolioModal = (id, portfolioName) => {
        setPortfolioListingState((prevState) => ({
            ...prevState,
            isCopyPortfolioModalOpen : true,
            portfolioName: portfolioName,
            portfolioId: id
        }));
    };

    const handleCloseCopyPortfolioModal = () => {
        setPortfolioListingState((prevState) => ({
            ...prevState,
            isCopyPortfolioModalOpen : false,
            portfolioName: '',
            portfolioId: ''
        }));
    };

    const handleRefreshButton = () => {
        getPortfolios();
    };

    const redirectToDetailsScreen = (id) => {
        props.history.push('/nf-transaction-details/' + id);
    };

    if (isEmpty(portfolioListingState.UIState)) {
        return (<DLLoader type={"screen"} isVisible={true}/>);
    }

    return (
        <PortfolioListingView
            {...props}
            {...portfolioListingState}
            redirectToDetailsScreen={redirectToDetailsScreen}
            handleRefreshButton={handleRefreshButton}
            onChangeFilter={onChangeFilter}
            selectedOption={selectedOption}
            handleCloseDeletePortfolioModal={handleCloseDeletePortfolioModal}
            handleCloseCopyPortfolioModal={handleCloseCopyPortfolioModal}
            getPortfolios={getPortfolios}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    portfolioFilter: state.portfolioState.portfolioFilter,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(PortfolioListing);
