import React, {useEffect, useState} from 'react';
import PANView from "./PANView";
import {connect} from "react-redux";
import {updatePanCardData, getPANCardData, getDocAssociateData} from "../../../OnboardingActions";
import {isEmpty} from "../../../../utils/validations";
import {isAlphabetic, isIncludeSpecialCharacters, trimValues, validateAge, validatePAN} from "../../../../utils/helper";
import moment from "moment";
import store from "../../../../store";
import {showToastMessage} from "../../../../AppComponents/AppActions";

const PAN = (props) => {

    const [PANState, setPANState] = useState({
        PANInformation: {
            nameAsPerPAN: '',
            firstName: '',
            middleName: '',
            lastName: '',
            PANNumber: '',
            dateOfBirth: ''
        },
        fathersDetails: {
            parentType: "FATHER",
            firstName: '',
            middleName: '',
            lastName: ''
        },
        document: {
            documentType:'PAN_CARD',
            documentCategory:'PAN_CARD',
            originalFileName : '',
            uploadedFileName : '',
            filePath: '',
            fileName: '',
            PAN_CARD: '',
            id: ''
        },
        errors: {
            PANInformation: {
                nameAsPerPAN: '',
                firstName: '',
                middleName: '',
                lastName: '',
                PANNumber: '',
                dateOfBirth: ''
            },
            fathersDetails: {
                parentType: "FATHER",
                firstName: '',
                middleName: '',
                lastName: ''
            },
            document:{
                PAN_CARD: ''
            }
        },
        responseErrors: [],
        totalErrors: 0,
        scrollToFieldId: '',
        isLoaderVisible: false,
        PANNumberByPerson: '',
        downloadFileName: '',
    });

    useEffect(() => {
        getPANData();
    }, [props.selectedScreenObj]);

    useEffect(()=>{
        let docData = mapDocumentData(props.selectedScreenObj);
        const {errors} = PANState;
        const {isFromOnboardingFlow} = props;
        setPANState(prevState => ({
            ...prevState,
            document : docData,
            errors : {
                ...errors,
                document: {
                    PAN_CARD: (isEmpty(docData?.fileName) && !isFromOnboardingFlow) ? 'Please upload PAN card' : ''
                }
            }
        }));
    },[props.selectedScreenObj.documentTypes]);

    const mapDocumentData = (selectedScreenObj) => {
        let selectedDocumentTypes = selectedScreenObj.documentTypes;

        const {document} = PANState;
        let docData = {...document};
        if(!isEmpty(selectedDocumentTypes)){
            //get PAN document using id from documentTypes
            let PANdocument = selectedDocumentTypes.find((document)=> document.id === 'PAN_CARD');
            if(!isEmpty(PANdocument.documents)){
                let documents = PANdocument.documents;
                docData.filePath = isEmpty(documents[0].imageFilePaths) ? '' : documents[0].imageFilePaths[0];
                docData.fileName = documents[0].fileName;
                docData.id = documents[0].id;
            }else{
                docData.filePath = '';
                docData.fileName = '';
                docData.id = '';
            }
        }
        return docData
    };

    const getPANData = () => {
        const {selectedScreenObj, getUplodedDocuments, onboardingApplicationId} = props;
        getPANCardData(selectedScreenObj.personId, onboardingApplicationId)
            .then(response => {
            getUplodedDocuments(selectedScreenObj?.screenKey, selectedScreenObj?.personId);
            if (response?.success) {
                const updatedPANData = getMappedData(response.data, false);
                setPANState(prevState => ({
                    ...prevState,
                    ...updatedPANData,
                    isLoaderVisible: false,
                }));
            }
        });
    };

    const getMappedData = (response, isFromAssociate) => {
        const {errors, PANNumberByPerson} = PANState;

        if (isEmpty(response)) {
            return {...PANState}
        }
        return {
            PANInformation: mapPANInformation(response),
            fathersDetails: mapfathersDetails(response?.parents),
            errors: {
                ...mapPANInformationErrors(errors, response),
                ...mapfathersDetailsErrors(errors, response?.parents),
            },
            scrollToFieldId: getFirstErrorFieldId({
                ...mapPANInformationErrors(errors, response),
                ...mapfathersDetailsErrors(errors, response?.parents),
            }),
            PANNumberByPerson: isEmpty(response?.iinPANNumber) ? isFromAssociate ? PANNumberByPerson : '' : response?.iinPANNumber
        };
    };

    const mapPANInformationErrors = (errors, PANDetails) => {
        const {isFromOnboardingFlow} = props;
        let errorPANInformationObj = {...errors.PANInformation};
        if (!isEmpty(PANDetails?.nameAsPerPAN)) {
            errorPANInformationObj.nameAsPerPAN = ''
        }
        if (!isEmpty(PANDetails?.firstName)) {
            errorPANInformationObj.firstName = ''
        }
        if (!isEmpty(PANDetails?.middleName)) {
            errorPANInformationObj.middleName = ''
        }
        if (!isEmpty(PANDetails?.lastName)) {
            errorPANInformationObj.lastName = ''
        }
        if (!isEmpty(PANDetails?.dateOfBirth)) {
            errorPANInformationObj.dateOfBirth = ''
        }
        if (!isEmpty(PANDetails?.PANNumber)) {
            errorPANInformationObj.PANNumber = ''
        }

        if(!isFromOnboardingFlow) {
            return {
                PANInformation: validatePANInformation({
                    PANInformation : PANDetails,
                    errors: errorPANInformationObj
                }, 'SUBMIT')
            };
        }

        return {
            PANInformation: errorPANInformationObj
        };
    };

    const mapfathersDetailsErrors = (error, response) => {
        const {isFromOnboardingFlow} = props;
        let errorfathersDetailsObj = {...error.fathersDetails};
        if(isEmpty(response)){
            return {
                fathersDetails: errorfathersDetailsObj,
            };
        }

        const fathersDetails = response.find(p => p.parentType === 'FATHER');

        if (!isEmpty(fathersDetails?.firstName)) {
            errorfathersDetailsObj.firstName = ''
        }
        if (!isEmpty(fathersDetails?.middleName)) {
            errorfathersDetailsObj.middleName = ''
        }
        if (!isEmpty(fathersDetails?.lastName)) {
            errorfathersDetailsObj.lastName = ''
        }
        if(!isFromOnboardingFlow && !isEmpty(response)) {
            return {
                fathersDetails: validateFathersInformation({
                    fathersDetails : response[0],
                    errors: errorfathersDetailsObj
                },'SUBMIT')
            };
        }
        return {
            fathersDetails: errorfathersDetailsObj,
        };
    };

    const getDownloadImageFileName = (firstName,lastName) => {
        const {document} = PANState;
        let downloadFileName = '';
        if(!isEmpty(firstName)){
            downloadFileName = firstName + '_'
        }
        if(!isEmpty(lastName)){
            downloadFileName = downloadFileName + lastName + '_'
        }
        if(!isEmpty(downloadFileName)){
            downloadFileName = downloadFileName + 'PAN'
        }
        return isEmpty(downloadFileName) ? document?.fileName : downloadFileName
    };

    const mapPANInformation = (PANDetails) => {
        let personDetails = PANDetails;
        return {
            nameAsPerPAN: isEmpty(personDetails?.nameAsPerPAN) ? '' : personDetails.nameAsPerPAN,
            firstName: isEmpty(personDetails?.firstName) ? '' : personDetails.firstName,
            middleName: isEmpty(personDetails?.middleName) ? '' : personDetails.middleName,
            lastName: isEmpty(personDetails?.lastName) ? '' : personDetails.lastName,
            PANNumber: isEmpty(personDetails?.PANNumber) ? '' : personDetails.PANNumber,
            dateOfBirth: isEmpty(personDetails?.dateOfBirth) ? '' : personDetails.dateOfBirth,
        };
    };

    const mapfathersDetails = (PANDetails) => {
        const {fathersDetails} = PANState;
        if(isEmpty(PANDetails)){
            return fathersDetails;
        }

        const updatedFathersDetails = PANDetails.find(p => p.parentType === 'FATHER');

        if(isEmpty(updatedFathersDetails)){
            return {
                parentType: "FATHER",
                nameAsPerPAN: '',
                firstName: '',
                middleName: '',
                lastName: ''
            }
        }
        return {
            parentType: "FATHER",
            nameAsPerPAN : isEmpty(updatedFathersDetails?.nameAsPerPAN) ? '' : updatedFathersDetails.nameAsPerPAN,
            firstName: isEmpty(updatedFathersDetails?.firstName) ? '' : updatedFathersDetails.firstName,
            middleName: isEmpty(updatedFathersDetails?.middleName) ? '' : updatedFathersDetails.middleName,
            lastName: isEmpty(updatedFathersDetails?.lastName) ? '' : updatedFathersDetails.lastName
        };
    };

    const validatePANInformation = (componentState, name) => {
        const {PANInformation, errors} = componentState;
        const {firstName, middleName, lastName, PANNumber, dateOfBirth, nameAsPerPAN} = PANInformation;

        let errorObj = {...errors.PANInformation};

        if (name === 'nameAsPerPAN' || name === 'SUBMIT') {
            if (isEmpty(nameAsPerPAN)) {
                errorObj.nameAsPerPAN = 'Please enter "Name as per PAN"'
            } else if ((isIncludeSpecialCharacters(nameAsPerPAN) || isAlphabetic(nameAsPerPAN))) {
                errorObj.nameAsPerPAN = 'Please enter alphabets only'
            }else if (firstName?.length > 60) {
                errorObj.firstName = 'Please enter 60 alphabets only'
            }else {
                errorObj.nameAsPerPAN = ''
            }
            if (name === 'nameAsPerPAN') {
                return errorObj
            }
        }

        if (name === 'firstName' || name === 'SUBMIT') {
            if (isEmpty(firstName)) {
                errorObj.firstName = 'Please enter first name'
            } else if (isIncludeSpecialCharacters(firstName) || isAlphabetic(firstName)) {
                errorObj.firstName = 'Please enter alphabets only'
            } else if (firstName?.length > 20) {
                errorObj.firstName = 'Please enter 20 alphabets only'
            } else {
                errorObj.firstName = ''
            }
            if (name === 'firstName') {
                return errorObj
            }
        }

        if (name === 'middleName' || name === 'SUBMIT') {
            if ((isIncludeSpecialCharacters(middleName) || isAlphabetic(middleName)) && !isEmpty(middleName)) {
                errorObj.middleName = 'Please enter alphabets only'
            } else if (middleName?.length > 20) {
                errorObj.middleName = 'Please enter 20 alphabets only'
            } else {
                errorObj.middleName = ''
            }
            if (name === 'middleName') {
                return errorObj
            }
        }

        if (name === 'lastName' || name === 'SUBMIT') {
            if (isEmpty(lastName)) {
                errorObj.lastName = 'Please enter last name.'
            } else if (isIncludeSpecialCharacters(lastName) || isAlphabetic(lastName)) {
                errorObj.lastName = 'Please enter alphabets only'
            } else if (lastName?.length > 20) {
                errorObj.lastName = 'Please enter 20 alphabets only'
            } else {
                errorObj.lastName = ''
            }
            if (name === 'lastName') {
                return errorObj
            }
        }

        if (name === 'dateOfBirth' || name === 'SUBMIT') {
            let isMinorApplicant = validateAge(dateOfBirth, 18);
            if (isEmpty(dateOfBirth)) {
                errorObj.dateOfBirth = 'Please enter date of birth'
            } else if (isMinorApplicant) {
                errorObj.dateOfBirth = 'Applicant age must be above 18'
            } else {
                errorObj.dateOfBirth = ''
            }
            if (name === 'dateOfBirth') {
                return errorObj
            }
        }

        if (name === 'PANNumber' || name === 'SUBMIT') {
            if (isEmpty(PANNumber)) {
                errorObj.PANNumber = 'Please enter PAN number'
            } else if (!validatePAN(PANNumber)) {
                errorObj.PANNumber = 'Please enter valid PAN number'
            } else if (PANNumber?.length !== 10) {
                errorObj.PANNumber = 'PAN number must be of 10 characters'
            } else {
                errorObj.PANNumber = ''
            }
            if (name === 'PANNumber') {
                return errorObj
            }
        }
        return errorObj
    };

    const validateFathersInformation = (componentState, name) => {
        const {fathersDetails, errors} = componentState;
        const {firstName, middleName, lastName} = fathersDetails;

        let errorObj = {...errors.fathersDetails};

        if (name === 'firstName' || name === 'SUBMIT') {
            if (isEmpty(firstName)) {
                errorObj.firstName = 'Please enter first name'
            } else if (isIncludeSpecialCharacters(firstName) || isAlphabetic(firstName)) {
                errorObj.firstName = 'Please enter alphabets only'
            } else if (firstName?.length > 20) {
                errorObj.firstName = 'Please enter 20 alphabets only'
            } else {
                errorObj.firstName = ''
            }
            if (name === 'firstName') {
                return errorObj
            }
        }

        if (name === 'middleName' || name === 'SUBMIT') {
            if ((isIncludeSpecialCharacters(middleName) || isAlphabetic(middleName)) && !isEmpty(middleName)) {
                errorObj.middleName = 'Please enter alphabets only'
            } else if (middleName?.length > 20) {
                errorObj.middleName = 'Please enter 20 alphabets only'
            } else {
                errorObj.middleName = ''
            }
            if (name === 'middleName') {
                return errorObj
            }
        }

        if (name === 'lastName' || name === 'SUBMIT') {
            if (isEmpty(lastName)) {
                errorObj.lastName = 'Please enter last name.'
            } else if (isIncludeSpecialCharacters(lastName) || isAlphabetic(lastName)) {
                errorObj.lastName = 'Please enter alphabets only'
            } else if (lastName?.length > 20) {
                errorObj.lastName = 'Please enter 20 alphabets only'
            } else {
                errorObj.lastName = ''
            }
            if (name === 'lastName') {
                return errorObj
            }
        }
        return errorObj
    };

    const validateChanges = (componentState, name, fieldType) => {

        const {errors} = componentState;
        let errorObj = errors;

        if (fieldType === 'PANInformation' || isEmpty(fieldType)) {
            errorObj.PANInformation = validatePANInformation(componentState, name);
        }

        if (fieldType === 'fathersDetails' || isEmpty(fieldType)) {
            errorObj.fathersDetails = validateFathersInformation(componentState, name);
        }

        errorObj.document = {
            PAN_CARD: ''
        };

        return errorObj;
    };

    const getFirstErrorFieldId = (errors) => {
        let sectionOrderToSort = ['PANInformation', 'fathersDetails'];
        for (const section of sectionOrderToSort) {
            if (errors[section]) {
                for (const key in errors[section]) {
                    const error = errors[section][key];
                    if (error && typeof error === 'object' && 'label' in error && error.label !== 'type') {
                        return section + '-' + error.label + '-id';
                    }
                    if (typeof error === 'string' && error && key !== 'type' && key !== 'parentType') {
                        return section + '-' + key + '-id';
                    }
                }
            }
        }
        return 'pan-information';
    };

    const assignResponseError = (response, stateErrors) => {
        const errorResponse = response?.validationErrors;
        let newStateErrors = {...stateErrors};

        const {responseErrors} = PANState;
        let tempResponseErrors = responseErrors;
        if (isEmpty(errorResponse)) { //handled state if reponse status is failed
            setPANState(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    ...newStateErrors,
                },
                isLoaderVisible: false,
                scrollToFieldId: getFirstErrorFieldId(stateErrors),
                responseErrors: [],
                totalErrors: 0,
            }));
            return
        }

        errorResponse.forEach((error) => { //assign each field by section name
            const section = error.section;

            if (!newStateErrors[section]) {
                return;
            }

            if (isEmpty(error.fields)) {
                if (section === 'fathersDetails') { //validate if all fields of father details are empty
                    newStateErrors = {
                        ...newStateErrors,
                        ...validateFathersInformation(PANState)
                    }
                }
                tempResponseErrors.push(error.message)
            }
            else if ((error.fields)?.length > 1) { //add page level validation error
                tempResponseErrors.push(error.message)
            } else {
                //assign each field error to respected field and log if invalid field is found
                error.fields.forEach(field => {
                    newStateErrors[section][field] = error.message;
                });
            }
        });
        let errors = {
            ...PANState.errors,
            ...newStateErrors,
        };
        setPANState(prevStep => ({
            ...prevStep,
            errors: errors,
            responseErrors: tempResponseErrors,
            totalErrors: tempResponseErrors?.length,
            scrollToFieldId: isEmpty(tempResponseErrors) ? getFirstErrorFieldId(errors) : 'page-level-errors',
            isLoaderVisible: false,
            PANInformation: trimValues(PANState?.PANInformation),
            fathersDetails: trimValues(PANState?.fathersDetails)
        }));
    };

    const onNextClick = () => {
        const {handleClickNext, isProceedWithErrors, accountId, selectedScreenObj, saveFailureErrorMessage,
            isFromOnboardingFlow, onboardingApplicationId} = props;

        setPANState(prevStep => ({
            ...prevStep,
            isLoaderVisible: true,
        }));

        const validateObject = validateChanges(PANState, 'SUBMIT');

        let errorId = '';
        let panDataObj = {...PANState.PANInformation};
        panDataObj.parents = [PANState.fathersDetails];
        panDataObj.personId = selectedScreenObj.personId;
        panDataObj.accountId = accountId;
        panDataObj.onboardingApplicationId = onboardingApplicationId;
        panDataObj = trimValues(panDataObj);

        updatePanCardData(panDataObj, true)
            .then(res => {
                if (res.success) {
                    if (isProceedWithErrors) {
                        handleClickNext(true, selectedScreenObj?.personId);
                        setPANState(prevStep => ({
                            ...prevStep,
                            isLoaderVisible: true,
                            PANInformation: trimValues(PANState?.PANInformation),
                            fathersDetails: trimValues(PANState?.fathersDetails)
                        }));
                        return;
                    }
                    if (isEmpty(res.data?.validationErrors)) {
                        handleClickNext(true, selectedScreenObj?.personId);
                        setPANState(prevStep => ({
                            ...prevStep,
                            isLoaderVisible: false,
                            PANInformation: trimValues(PANState?.PANInformation),
                            fathersDetails: trimValues(PANState?.fathersDetails)
                        }));
                    } else {
                        if(!isFromOnboardingFlow){
                            handleClickNext(true, selectedScreenObj?.personId);
                        }
                        assignResponseError(res.data, validateObject)
                    }
                } else {
                    store.dispatch(showToastMessage('warning', saveFailureErrorMessage));
                    let responseErrors = [];
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                        errorId = 'page-level-errors'
                    }
                    setPANState(prevStep => ({
                        ...prevStep,
                        errors: {
                            ...prevStep.errors,
                            ...validateObject,
                        },
                        responseErrors: responseErrors,
                        totalErrors: responseErrors?.length,
                        scrollToFieldId: errorId,
                        isLoaderVisible: false,
                        PANInformation: trimValues(PANState?.PANInformation),
                        fathersDetails: trimValues(PANState?.fathersDetails)
                    }));
                }
            })
    };

    const onSaveAndCloseClick = () => {
        const {profile, accountId, selectedScreenObj, handleClickSaveAndClose, saveFailureErrorMessage, onboardingApplicationId} = props;
        setPANState(prevStep => ({
            ...prevStep,
            isLoaderVisible: true
        }));
        let errorId = '';

        let panDataObj = {...PANState.PANInformation};
        panDataObj.parents = [PANState.fathersDetails];
        panDataObj.personId = selectedScreenObj.personId;
        panDataObj.accountId = accountId;
        panDataObj.onboardingApplicationId = onboardingApplicationId;

        updatePanCardData(panDataObj, true)
            .then(res => {
                if (res.success) {
                    handleClickSaveAndClose();

                } else {
                    store.dispatch(showToastMessage('warning', saveFailureErrorMessage));

                    let responseErrors = [];
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                        errorId = 'page-level-errors'
                    }
                    setPANState(prevStep => ({
                        ...prevStep,
                        responseErrors: responseErrors,
                        totalErrors: responseErrors?.length,
                        scrollToFieldId: errorId,
                        isLoaderVisible: false
                    }));
                }
            })
    };

    const handleChange = (name, value, fieldType) => {
        if(name === 'scrollToFieldId'){
            setPANState(prevStep => ({
                ...prevStep,
                scrollToFieldId: value
            }));
            return
        }

        let updatedCompState = {...PANState};
        if (fieldType === "PANInformation") {

            if (name === 'dateOfBirth') {
                updatedCompState.PANInformation.dateOfBirth = isEmpty(value) ? null : moment(value).format('YYYY-MM-DD');
            } else {
                if(!isEmpty(value)){
                    value = value.toUpperCase();
                }
                updatedCompState.PANInformation[name] = value;
            }

        } else if (fieldType === "fathersDetails") {
            if(!isEmpty(value)){
                value = value.toUpperCase();
            }
            updatedCompState.fathersDetails[name] = value;
        } else {
            updatedCompState[name] = value;
        }

        setPANState(prevStep => ({
            ...prevStep,
            ...updatedCompState,
        }));

        const validateObject = validateChanges(updatedCompState, name, fieldType);

        setPANState(prevStep => ({
            ...prevStep,
            errors: {
                ...prevStep.errors,
                ...validateObject
            },
        }));
    };

    const getResetErrors = () =>{
        return {
            PANInformation: {
                nameAsPerPAN: '',
                firstName: '',
                middleName: '',
                lastName: '',
                PANNumber: '',
                dateOfBirth: ''
            },
            fathersDetails: {
                parentType: "FATHER",
                firstName: '',
                middleName: '',
                lastName: ''
            },
            document:{
                PAN_CARD: ''
            }
        }
    };

    const associateDocument = (type, response) => {
        const {profile, selectedScreenObj, onboardingApplicationId} = props;
        const {document, errors} = PANState;

        if(type === 'ERROR'){
            setPANState(prevStep => ({
                ...prevStep,
                errors: {
                    ...errors,
                    document:{
                        PAN_CARD: response
                    }
                },
            }));
            return;
        }
        if(type === 'DELETE'){
            setPANState(prevStep => ({
                ...prevStep,
                responseErrors: [],
                totalErrors: 0,
            }));
            return;
        }

        setPANState(prevStep => ({
            ...prevStep,
            isLoaderVisible: true,
        }));

        const associatePayload={
            type: document.documentType,
            documentCategory: document.documentCategory,
            personId: selectedScreenObj?.personId,
            documentRequirementId: selectedScreenObj?.documentRequirementId,
            originalFileName: response?.originalFileName,
            uploadedFileName: response?.uploadedFileName,
            onboardingApplicationId: onboardingApplicationId
        };


        getDocAssociateData(associatePayload)
            .then((res) => {
                if (res.success) {
                    const mappedState = getMappedData(res.data?.documentDetails, true);

                    setPANState(prevStep => ({
                        ...prevStep,
                        ...mappedState,
                        document: {
                            ...document,
                            filePath: (isEmpty(res.data?.imageFilePaths)) ? '' : res.data?.imageFilePaths[0],
                            fileName: res.data?.fileName,
                            id: res.data?.id
                        },
                        errors : getResetErrors(),
                        isLoaderVisible: false,
                        responseErrors: [],
                        totalErrors: 0,
                    }));
                } else {
                    let responseErrors = [];
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                    }
                    let defaultPANState = getResetErrors();
                    setPANState(prevStep => ({
                        ...prevStep,
                        ...defaultPANState,
                        errors : getResetErrors(),
                        responseErrors: responseErrors,
                        totalErrors: responseErrors?.length,
                        isLoaderVisible: false,
                        document: {
                            ...document,
                            filePath: '',
                            id: ''
                        },
                    }));
                }
            })
    };


    return (
        <PANView {...props}
                 {...PANState}
                 onNextClick={onNextClick}
                 onSaveAndCloseClick={onSaveAndCloseClick}
                 handleChange={handleChange}
                 associateDocument={associateDocument}
                 getDownloadImageFileName={getDownloadImageFileName}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    accountId: state.onboardingFlowState.accountId,
    onboardingApplicationId: state.onboardingFlowState.onboardingApplicationId
});

export default connect(mapStateToProps, {})(PAN);
