import React, {useEffect} from 'react';
import DLText from '../../../core/components/DLText/DLText';
import DLInputTextField from '../../../core/components/DLInputTextField/DLInputTextField';
import DLButton from '../../../core/components/DLButton/DLButton';
import DLDropDown from '../../../core/components/DLDropdown/DLDropDown';
import {isEmpty} from '../../../utils/validations';
import DLLoader from "../../../core/components/DLLoader/DLLoader";

import '../../../assets/css/app.css'
import {debounce} from "../../../core/components/DLComponentHelper";
import appTheme from "../../../assets/appTheme";
import DLRadioGroup from "../../../core/components/DLRadioGroup/DLRadioGroup";
import {detailOriented} from "../../OnboardingConsts";
import {numberWithCommas} from "../../../utils/helper";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const FinancialProfileView = (props) => {

    useEffect(() => {
        const {scrollToFieldId} = props;
        if (!isEmpty(scrollToFieldId)) {
            const element = document.getElementById(scrollToFieldId);

            if (!isEmpty(element)) {
                element.scrollIntoView({behavior: 'smooth', block: 'center'});
                handleScrollChangeWithDebounce()
            }
        }
        return () => {
            window.scrollTo(0, 0)
        }
    }, [props.scrollToFieldId]);

    const handleScrollChangeWithDebounce = debounce(() => {
        props.handleChange('scrollToFieldId', '')
    }, 1000);

    const getMenuList = () => {
        const{dropdownValues, handleChange} = props;
        const{riskProfiles} = dropdownValues;

        if (riskProfiles?.length === 0) {
            return (
                <MenuItem id={'menu-item'} disabled={true}>
                    <DLText id={''}
                            text={'No data available'}
                            fontSize={"sm"}
                            fontWeight={"semi-bold"}
                            marginBottom={"none"}
                    />
                </MenuItem>
            )

        } else {
            return (
                <div>
                    <div style={{overflowX: "auto" }}>
                        {riskProfiles.map((item, index) => {
                            return (
                                <MenuItem key={item.value}
                                          id={index+'menu-item'+item.value}
                                          value={item.value}
                                          onClick={()=>{
                                              handleChange('riskProfileId', item.value, 'accountCategorization')
                                          }} >
                                    {
                                        <div className={'mb-1 text-break'}
                                             style={{ width: '98%', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                                        <span>
                                            <DLText id={''}
                                                    text={item.label}
                                                    fontSize={"sm"}
                                                    fontWeight={"semi-bold"}
                                                    marginBottom={"none"}
                                            />
                                        </span>
                                            <span className='text-break'>
                                            <DLText id={''}
                                                    text={item.description}
                                                    fontSize={"xs"}
                                                    fontWeight={"normal"}
                                                    marginBottom={"none"}
                                                    fontColor={"grayDark"}
                                            />
                                        </span>
                                        </div>
                                    }
                                </MenuItem>
                            );
                        })}
                    </div>
                </div>)


        }
    }

    const getRiskProfileFont = (value) =>{
        const {getRiskProfile} = props;
        return(
            <DLText id={''}
                    text={getRiskProfile(value)}
                    fontWeight={"normal"}
                    fontSize={"sm"}
            />
        )
    }

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5, // adjust based on your needs
                width: 250, // adjust based on your needs
            },
        },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left", // aligns the panel to the left
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left", // aligns the panel to the left
        },
    };

    const getRiskProfileDropdown = () =>{
        const {accountCategorization, errors} = props;
        const {riskProfileId} = accountCategorization;
        return(
            <div className={"mb-3"}>
                <DLText id={"risk-profile"}
                        text={"Risk Profile"}
                        fontSize={"xs"}
                        fontColor={errors.accountCategorization.riskProfileId !== ''? "danger" : "grayDark"}
                />
                <form className={''} autoComplete="off">
                <FormControl style={{width :' 100%'}}
                             error={errors.accountCategorization.riskProfileId !== ''}
                >
                    <Select
                        value={riskProfileId}
                        style={{height: "36px"}}
                        MenuProps={MenuProps}
                        renderValue={(value) => getRiskProfileFont(value)}
                    >
                        {getMenuList()}
                    </Select>
                    {errors.accountCategorization.riskProfileId !== '' &&
                    <div className={'ps-3'}>
                        <DLText id={"error-message"}
                                text={errors.accountCategorization.riskProfileId}
                                fontSize={"xs"}
                                fontColor={"danger"}
                        />
                    </div>
                    }
                </FormControl>
                    </form>
            </div>
        )
    }

    const renderInvestmentDetails = () => {
        const {handleChange, errors, accountInvestment} = props;
        const {objective, initialInvestmentAmount, initialSIPAmount} = accountInvestment;

        return (<div id={'investment'}>
            <div className='section-title' id={'investment'}>
                Investment
            </div>
            <div className='panel'>
                <div id='accountInvestment-objective-id' className={isTabUI() ? 'w-75 ' : 'w-50 w-md-75 w-sm-100'}>
                    <DLInputTextField
                        id={'objective-input-field'}
                        label={'Objective (Optional)'}
                        rows={3}
                        fullWidth
                        maxCharLength={301}
                        isClearable={false}
                        isMulti={true}
                        disableHelperMessageHeight={true}
                        onChangeValue={(e) => {
                            handleChange('objective', e, 'accountInvestment')
                        }}
                        value={objective}
                        error={errors.accountInvestment.objective !== ''}
                        helperMessage={errors.accountInvestment.objective}
                    />
                </div>
                <div className={isTabUI() ? 'd-flex w-75 gap-3' : 'd-flex w-50 w-md-75 w-sm-100 gap-3'}>
                    <div className='w-100' id='accountInvestment-initialInvestmentAmount-id'>
                        <DLInputTextField id={'initial-investment-amount-input-field'}
                                          inputType={'number'}
                                          label={'Initial Investment Amount (Optional)'}
                                          maxCharLength={10}
                                          isClearable={false}
                                          isRequired={true}
                                          marginBottom={'md'}
                                          disableHelperMessageHeight={true}
                                          onChangeValue={(e) => {
                                              handleChange('initialInvestmentAmount', e, 'accountInvestment')
                                          }}
                                          value={initialInvestmentAmount}
                                          error={errors.accountInvestment.initialInvestmentAmount !== ''}
                                          helperMessage={errors.accountInvestment.initialInvestmentAmount}
                        />
                    </div>
                    <div className='w-100' id='accountInvestment-initialSIPAmount-id'>
                        <DLInputTextField id={'initial-SIP-amount-input-field'}
                                          inputType={'number'}
                                          label={'Initial SIP Amount (Optional)'}
                                          maxCharLength={10}
                                          isClearable={false}
                                          isRequired={true}
                                          disableHelperMessageHeight={true}
                                          marginBottom={'md'}
                                          onChangeValue={(e) => {
                                              handleChange('initialSIPAmount', e, 'accountInvestment')
                                          }}
                                          value={initialSIPAmount}
                                          error={errors.accountInvestment.initialSIPAmount !== ''}
                                          helperMessage={errors.accountInvestment.initialSIPAmount}
                        />
                    </div>
                </div>
            </div>
        </div>);
    };

    const renderCurrentFinancialsDetails = () => {
        const {handleChange, errors, accountFinancial, dropdownValues} = props;
        const {applicableIncome} = dropdownValues;

        const {primaryHolderIncomeCode} = accountFinancial;

        return (<div className='mt-2'>
            <div className='section-title'
                 id={'current-financials'}>
                Current Financials
            </div>
            <div className='panel'>
                <div className={isTabUI() ? 'd-flex w-75 gap-3' : 'd-flex w-50 w-sm-100 gap-3'}>
                    <div className='w-100' id='accountFinancial-primaryHolderIncomeCode-id'>
                        <DLDropDown id={'family-income-input-field'}
                                    label={'Primary Holder Income'}
                                    labelPlacement={'top'}
                                    noOptionsMessage={'No options found'}
                                    placeholder={''}
                                    options={applicableIncome}
                                    minWidth={'100%'}
                                    marginBottom={'md'}
                                    onSelect={(e) => {
                                        handleChange('primaryHolderIncomeCode', e, 'accountFinancial')
                                    }}
                                    value={primaryHolderIncomeCode}
                                    disableHelperMessageHeight={errors.accountFinancial.primaryHolderIncomeCode === ''}
                                    error={errors.accountFinancial.primaryHolderIncomeCode !== ''}
                                    helperMessage={errors.accountFinancial.primaryHolderIncomeCode}
                                    isRequired={true}
                                    isClearable={false}
                        />
                    </div>
                    <div className='w-100'/>
                </div>
            </div>
        </div>);
    };

    const renderPlanningAndGoalsDetails = () => {
        const {handleChange, errors, accountGoal, accountFinancial, dropdownValues} = props;
        const {yearsForRetirement} = dropdownValues;

        const {shortTermGoal, midTermGoal, longTermGoal} = accountGoal;

        const {primaryHolderExpectedRetirementYear, primaryHolderMonthlyRequirementPostRetirement} = accountFinancial;

        return (<div className='mt-2'>
            <div className='section-title'
                 id={'planning-and-goals'}>
                Planning And Goals
            </div>
            <div className='panel'>
                <div className='pb-0'>
                    <div id='accountGoal-shortTermGoal-id'
                         className={isTabUI() ? 'w-75 ' : 'w-50 w-sm-100'}>
                        <DLInputTextField
                            id={'shortTermGoals-input-field'}
                            label={'Short Term Goals (Optional)'}
                            maxCharLength={301}
                            isClearable={false}
                            isMulti={true}
                            rows={3}
                            disableHelperMessageHeight={true}
                            onChangeValue={(e) => {
                                handleChange('shortTermGoal', e, 'accountGoal')
                            }}
                            value={shortTermGoal}
                            error={errors.accountGoal.shortTermGoal !== ''}
                            helperMessage={errors.accountGoal.shortTermGoal}
                        />
                    </div>

                    <div id='accountGoal-midTermGoal-id'
                         className={isTabUI() ? 'w-75 ' : 'w-50 w-sm-100 '}>
                        <DLInputTextField
                            id={'midTermGoals-input-field'}
                            label={'Mid Term Goals (Optional)'}
                            maxCharLength={301}
                            isClearable={false}
                            isMulti={true}
                            rows={3}
                            disableHelperMessageHeight={true}
                            onChangeValue={(e) => {
                                handleChange('midTermGoal', e, 'accountGoal')
                            }}
                            value={midTermGoal}
                            error={errors.accountGoal.midTermGoal !== ''}
                            helperMessage={errors.accountGoal.midTermGoal}
                        />
                    </div>

                    <div id='accountGoal-longTermGoal-id'
                         className={isTabUI() ? 'w-75 ' : 'w-50 w-sm-100 '}>
                        <DLInputTextField
                            id={'investment-objective-input-field'}
                            label={'Long Term Goals (Optional)'}
                            rows={3}
                            isMulti={true}
                            isClearable={false}
                            maxCharLength={301}
                            disableHelperMessageHeight={true}
                            onChangeValue={(e) => {
                                handleChange('longTermGoal', e, 'accountGoal')
                            }}
                            value={longTermGoal}
                            error={errors.accountGoal.longTermGoal !== ''}
                            helperMessage={errors.accountGoal.longTermGoal}
                        />
                    </div>
                </div>
                <div className='pt-2 pb-0'>
                    <div
                        className={isTabUI() ? 'd-flex w-75 align-items-center gap-3' : 'd-flex align-items-center w-50 w-sm-100 gap-3'}>
                        <div id='accountFinancial-primaryHolderExpectedRetirementYear-id'
                             className='w-100 '>
                            <DLDropDown
                                id={'annual-income-input-field'}
                                label={'Expected Year of Retirement (Optional)'}
                                labelPlacement={'top'}
                                noOptionsMessage={'No options found'}
                                placeholder={''}
                                options={yearsForRetirement}
                                minWidth={'100%'}
                                marginBottom={'md'}
                                onSelect={(e) => {
                                    handleChange('primaryHolderExpectedRetirementYear', e, 'accountFinancial')
                                }}
                                value={primaryHolderExpectedRetirementYear}
                                error={errors.accountFinancial.primaryHolderExpectedRetirementYear !== ''}
                                helperMessage={errors.accountFinancial.primaryHolderExpectedRetirementYear}
                                isClearable={false}
                                disableHelperMessageHeight={true}
                            />
                        </div>
                        <div id='accountFinancial-primaryHolderMonthlyRequirementPostRetirement-id'
                             className='w-100'>
                            <DLInputTextField
                                id={'primaryHolderMonthlyRequirementPostRetirement-field'}
                                label={'Monthly Requirement Post Retirement (Optional)'}
                                inputType={'number'}
                                maxCharLength={10}
                                isClearable={false}
                                marginBottom={'md'}
                                onChangeValue={(e) => {
                                    handleChange('primaryHolderMonthlyRequirementPostRetirement', e, 'accountFinancial')
                                }}
                                value={primaryHolderMonthlyRequirementPostRetirement}
                                error={errors.accountFinancial.primaryHolderMonthlyRequirementPostRetirement !== ''}
                                helperMessage={errors.accountFinancial.primaryHolderMonthlyRequirementPostRetirement}
                                disableHelperMessageHeight={true}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div>);
    };

    const renderFinancialCategorizationNote = () => {

        return (
            <div id='financial-categorization-note'>
                <div className='align-items-center d-flex message-block'>
                    <div className='error-panel ms-0' style={{backgroundColor: appTheme.appColor.info}}>
                        <DLText id={'info'}
                                text={
                                    <>
                                        <DLText id={'info-icon'}
                                                text={<i className="fa-solid fa-circle-info pe-1" style={{color: appTheme.appColor.primary}}></i>}
                                                marginBottom={"none"}
                                                isInline={true}
                                                fontSize={'xs'}
                                        />
                                        <DLText id={'info-description'}
                                                text={'This section will not be visible to client.'}
                                                marginBottom={"none"}
                                                isInline={true}
                                                fontSize={'xs'}
                                        />
                                    </>}
                                marginBottom={"none"}
                                fontSize={'xs'}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderFinancialCategorization = () => {
        const {handleChange, errors, accountCategorization, dropdownValues} = props;
        const {categories, riskProfiles, profiles} = dropdownValues;

        const {
            categoryId, riskProfileId, maximumAmount, CRMNote, financialProfileId, cashflowTypeId, spendingHabitId, decisionMakingStyleId,
            investmentStyleId, moneyManagementStyleId, detailOrientation, realEstateToNetworthRatioId, insuranceToIncomeRatioId
        } = accountCategorization;

        return (<div className='mt-2'>
            <div className='section-title'
                 id={'financial-categorization'}>
                Financial Categorization
            </div>
            <div className='panel'>
                {
                    renderFinancialCategorizationNote()
                }

                <div className='pt-2'>
                    <div className='pt-2 pb-0'>
                        <div
                            className={isTabUI() ? 'd-flex w-75 align-items-start gap-3' : 'd-flex align-items-start w-50 w-sm-100 gap-3'}>
                            <div id='accountCategorization-categoryId-id'
                                 className='w-100 '>
                                <DLDropDown
                                    id={'categoryId-dropDown'}
                                    label={'Category'}
                                    labelPlacement={'top'}
                                    noOptionsMessage={'No options found'}
                                    placeholder={''}
                                    options={categories}
                                    minWidth={'100%'}
                                    onSelect={(e) => {
                                        handleChange('categoryId', e, 'accountCategorization')
                                    }}
                                    value={categoryId}
                                    error={errors.accountCategorization.categoryId !== ''}
                                    helperMessage={errors.accountCategorization.categoryId}
                                    disableHelperMessageHeight={true}
                                    isClearable={false}
                                    isRequired={true}
                                />
                            </div>
                            <div id='accountCategorization-financialProfileId-id'
                                 className='w-100'>
                                <DLDropDown
                                    id={'financialProfileId-dropDown'}
                                    label={'Profile'}
                                    labelPlacement={'top'}
                                    noOptionsMessage={'No options found'}
                                    placeholder={''}
                                    options={profiles}
                                    minWidth={'100%'}
                                    onSelect={(e) => {
                                        handleChange('financialProfileId', e, 'accountCategorization')
                                    }}
                                    value={financialProfileId}
                                    error={errors.accountCategorization.financialProfileId !== ''}
                                    helperMessage={errors.accountCategorization.financialProfileId}
                                    disableHelperMessageHeight={true}
                                    isClearable={false}
                                    isRequired={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div id='accountCategorization-riskProfileId-id'
                         className={isTabUI() ? 'tabWidthSingleField ' : 'w-25 w-sm-100 '}>
                        {getRiskProfileDropdown()}
                    </div>

                    <div id='accountCategorization-maximumAmount-id'
                         className={isTabUI() ? 'tabWidthSingleField ' : 'w-25 w-sm-100 '}>
                        <DLInputTextField
                            id={'maximum-amount-input-field'}
                            label={'Maximum Investment Potential'}
                            inputType={'number'}
                            maxCharLength={12}
                            isClearable={false}
                            disableHelperMessageHeight={true}
                            onChangeValue={(e) => {
                                handleChange('maximumAmount', e, 'accountCategorization')
                            }}
                            value={numberWithCommas(maximumAmount)}
                            error={errors.accountCategorization.maximumAmount !== ''}
                            helperMessage={errors.accountCategorization.maximumAmount}
                            isRequired={true}
                        />
                    </div>

                    <div id='accountCategorization-cashflowTypeId-id'
                         className={isTabUI() ? 'tabWidthSingleField mb-3' : 'w-50 w-sm-100 mb-3'}>
                        <DLText id={''}
                                text={'Cashflow'}
                                fontSize={"xs"}
                                fontColor={errors.accountCategorization.cashflowTypeId !== '' ? "danger" : "grayDark"}
                                marginBottom={"xs"}
                        />
                        <div style={{marginLeft: '10px'}}>
                            <DLRadioGroup
                                id={'hours-radioBtn'}
                                options={dropdownValues.cashFlowOptions}
                                label={''}
                                value={cashflowTypeId}
                                type={'inline'}
                                labelFontSize={'sm'}
                                labelFontColor={'black'}
                                onChangeValue={(e) => {
                                    handleChange('cashflowTypeId', e, 'accountCategorization')
                                }}
                                error={errors.accountCategorization.cashflowTypeId !== ''}
                                helperMessage={errors.accountCategorization.cashflowTypeId}
                                helperMessageColor={"danger"}
                                disableHelperMessageHeight={true}
                                disableDescriptionMessageHeight={true}
                            />
                        </div>
                    </div>

                    <div id='accountCategorization-spendingHabitId-id'
                         className={isTabUI() ? 'tabWidthSingleField mb-3' : 'w-50 w-sm-100 mb-3'}>
                        <DLText id={''}
                                text={'Spending Habit'}
                                fontSize={"xs"}
                                fontColor={errors.accountCategorization.spendingHabitId !== ''? "danger" : "grayDark"}
                                marginBottom={"xs"}
                        />
                        <div style={{marginLeft: '10px'}}>
                            <DLRadioGroup
                                id={'hours-radioBtn'}
                                options={dropdownValues.spendingHabitsOptions}
                                label={''}
                                value={spendingHabitId}
                                type={'inline'}
                                labelFontSize={'sm'}
                                labelFontColor={'black'}
                                onChangeValue={(e) => {
                                    handleChange('spendingHabitId', e, 'accountCategorization')
                                }}
                                error={errors.accountCategorization.spendingHabitId !== ''}
                                helperMessage={errors.accountCategorization.spendingHabitId}
                                helperMessageColor={"danger"}
                                disableHelperMessageHeight={true}
                                disableDescriptionMessageHeight={true}
                            />
                        </div>
                    </div>

                    <div id='accountCategorization-realEstateToNetworthRatioId-id'
                         className={isTabUI() ? 'tabWidthSingleField ' : 'w-25 w-sm-100 '}>
                        <DLDropDown
                            id={'spent-on-real-estate-dropDown'}
                            label={'Real Estate To Net Worth Ratio'}
                            labelPlacement={'top'}
                            noOptionsMessage={'No options found'}
                            placeholder={''}
                            options={dropdownValues.spentOnRealEstateOptions}
                            minWidth={'100%'}
                            onSelect={(e) => {
                                handleChange('realEstateToNetworthRatioId', e, 'accountCategorization')
                            }}
                            value={realEstateToNetworthRatioId}
                            error={errors.accountCategorization.realEstateToNetworthRatioId !== ''}
                            helperMessage={errors.accountCategorization.realEstateToNetworthRatioId}
                            isClearable={false}
                            isRequired={true}
                            disableHelperMessageHeight={true}
                        />
                    </div>

                    <div id='accountCategorization-insuranceToIncomeRatioId-id'
                         className={isTabUI() ? 'tabWidthSingleField ' : 'w-25 w-sm-100 '}>
                        <DLDropDown
                            id={'insuranceToIncomeRatioId-dropDown'}
                            label={'Insurance To Income Ratio'}
                            labelPlacement={'top'}
                            noOptionsMessage={'No options found'}
                            placeholder={''}
                            options={dropdownValues.spentOnInsuranceOptions}
                            minWidth={'100%'}
                            onSelect={(e) => {
                                handleChange('insuranceToIncomeRatioId', e, 'accountCategorization')
                            }}
                            value={insuranceToIncomeRatioId}
                            error={errors.accountCategorization.insuranceToIncomeRatioId !== ''}
                            helperMessage={errors.accountCategorization.insuranceToIncomeRatioId}
                            isClearable={false}
                            isRequired={true}
                            disableHelperMessageHeight={true}
                        />
                    </div>

                    <div id='accountCategorization-decisionMakingStyleId-id'
                         className={isTabUI() ? 'mb-3' : 'w-50 w-sm-100 mb-3'}>
                        <DLText id={''}
                                text={'Decision Making Style'}
                                fontSize={"xs"}
                                fontColor={errors.accountCategorization.decisionMakingStyleId !== ''? "danger" : "grayDark"}
                                marginBottom={"xs"}
                        />
                        <div style={{marginLeft: '10px'}}>
                            <DLRadioGroup
                                id={'hours-radioBtn'}
                                options={dropdownValues.personalityTraits}
                                label={''}
                                value={decisionMakingStyleId}
                                type={'inline'}
                                labelFontSize={'sm'}
                                labelFontColor={'black'}
                                onChangeValue={(e) => {
                                    handleChange('decisionMakingStyleId', e, 'accountCategorization')
                                }}
                                error={errors.accountCategorization.decisionMakingStyleId !== ''}
                                helperMessage={errors.accountCategorization.decisionMakingStyleId}
                                helperMessageColor={"danger"}
                                disableHelperMessageHeight={true}
                                disableDescriptionMessageHeight={true}
                            />
                        </div>
                    </div>

                    <div id='accountCategorization-investmentStyleId-id'
                         className={isTabUI() ? 'mb-3' : 'w-50 w-sm-100 mb-3'}>
                        <DLText id={''}
                                text={'Investment Style'}
                                fontSize={"xs"}
                                fontColor={errors.accountCategorization.investmentStyleId !== ''? "danger" : "grayDark"}
                                marginBottom={"xs"}
                        />
                        <div style={{marginLeft: '10px'}}>
                            <DLRadioGroup
                                id={'hours-radioBtn'}
                                options={dropdownValues.decisionApproaches}
                                label={''}
                                value={investmentStyleId}
                                type={'inline'}
                                labelFontSize={'sm'}
                                labelFontColor={'black'}
                                onChangeValue={(e) => {
                                    handleChange('investmentStyleId', e, 'accountCategorization')
                                }}
                                error={errors.accountCategorization.investmentStyleId !== ''}
                                helperMessage={errors.accountCategorization.investmentStyleId}
                                helperMessageColor={"danger"}
                                disableHelperMessageHeight={true}
                                disableDescriptionMessageHeight={true}
                            />
                        </div>
                    </div>

                    <div id='accountCategorization-moneyManagementStyleId-id'
                         className={isTabUI() ? 'mb-3' : 'w-50 w-sm-100 mb-3'}>
                        <DLText id={''}
                                text={'Money Management Style'}
                                fontSize={"xs"}
                                fontColor={errors.accountCategorization.moneyManagementStyleId !== ''? "danger" : "grayDark"}
                                marginBottom={"xs"}
                        />
                        <div style={{marginLeft: '10px'}}>
                            <DLRadioGroup
                                id={'hours-radioBtn'}
                                options={dropdownValues.financialApproaches}
                                label={''}
                                value={moneyManagementStyleId}
                                type={'inline'}
                                labelFontSize={'sm'}
                                labelFontColor={'black'}
                                onChangeValue={(e) => {
                                    handleChange('moneyManagementStyleId', e, 'accountCategorization')
                                }}
                                error={errors.accountCategorization.moneyManagementStyleId !== ''}
                                helperMessage={errors.accountCategorization.moneyManagementStyleId}
                                helperMessageColor={"danger"}
                                disableHelperMessageHeight={true}
                                disableDescriptionMessageHeight={true}
                            />
                        </div>
                    </div>

                    <div id='accountCategorization-detailOrientation-id'
                         className={isTabUI() ? 'mb-3' : 'w-50 w-sm-100 mb-3'}>
                        <DLText id={''}
                                text={'Detail Oriented'}
                                fontSize={"xs"}
                                marginBottom={"xs"}
                                fontColor={errors.accountCategorization.detailOrientation !== ''? "danger" : "grayDark"}
                        />
                        <div style={{marginLeft: '10px'}}>
                            <DLRadioGroup
                                id={'hours-radioBtn'}
                                options={detailOriented}
                                label={''}
                                value={detailOrientation}
                                type={'inline'}
                                labelFontSize={'sm'}
                                labelFontColor={'black'}
                                onChangeValue={(e) => {
                                    handleChange('detailOrientation', e, 'accountCategorization')
                                }}
                                error={errors.accountCategorization.detailOrientation !== ''}
                                helperMessage={errors.accountCategorization.detailOrientation}
                                helperMessageColor={"danger"}
                                disableHelperMessageHeight={true}
                                disableDescriptionMessageHeight={true}
                            />
                        </div>
                    </div>

                    <div id='accountCategorization-CRMNote-id'
                         className={isTabUI() ? 'w-75' : 'w-50 w-sm-100 '}>
                        <DLInputTextField
                            id={'client-notes-input-field'}
                            label={'Client Notes'}
                            maxCharLength={301}
                            isClearable={false}
                            isMulti={true}
                            rows={4}
                            disableHelperMessageHeight={true}
                            onChangeValue={(e) => {
                                handleChange('CRMNote', e, 'accountCategorization')
                            }}
                            value={CRMNote}
                            error={errors.accountCategorization.CRMNote !== ''}
                            helperMessage={errors.accountCategorization.CRMNote}
                            isRequired={true}
                        />
                    </div>
                </div>
            </div>

        </div>);
    };


    const isTabUI = () => {
        const {isMDView, isLGView} = props;
        return isMDView || isLGView
    };


    const renderMobileInvestmentDetails = () => {
        const {handleChange, errors, accountInvestment} = props;
        const {objective, initialInvestmentAmount, initialSIPAmount} = accountInvestment;

        return (<div id={'investment'} className='block-padding'>
            <DLText
                id={'revenue'}
                type={'normal'}
                text={'Investment'}
                fontSize={'sm'}
                fontWeight={'semi-bold'}
                marginBottom={'md'}
                fontColor={'black'}
            />
            <div id='accountInvestment-objective-id'>
                <DLInputTextField
                    id={'objective-input-field'}
                    label={'Objective (Optional)'}
                    rows={3}
                    fullWidth
                    maxCharLength={301}
                    isClearable={false}
                    isMulti={true}
                    onChangeValue={(e) => {
                        handleChange('objective', e, 'accountInvestment')
                    }}
                    value={objective}
                    error={errors.accountInvestment.objective !== ''}
                    helperMessage={errors.accountInvestment.objective}
                    disableHelperMessageHeight={true}
                />
            </div>

            <div id='accountInvestment-initialInvestmentAmount-id'>
                <DLInputTextField
                    id={'initial-investment-amount-input-field'}
                    inputType={'number'}
                    label={'Initial Investment Amount (Optional)'}
                    maxCharLength={10}
                    isClearable={false}
                    onChangeValue={(e) => {
                        handleChange('initialInvestmentAmount', e, 'accountInvestment')
                    }}
                    value={initialInvestmentAmount}
                    error={errors.accountInvestment.initialInvestmentAmount !== ''}
                    helperMessage={errors.accountInvestment.initialInvestmentAmount}
                    isRequired={true}
                    disableHelperMessageHeight={true}
                />
            </div>

            <div id='accountInvestment-initialSIPAmount-id'>
                <DLInputTextField
                    id={'initial-SIP-amount-input-field'}
                    inputType={'number'}
                    label={'Initial SIP Amount (Optional)'}
                    maxCharLength={10}
                    isClearable={false}
                    onChangeValue={(e) => {
                        handleChange('initialSIPAmount', e, 'accountInvestment')
                    }}
                    value={initialSIPAmount}
                    error={errors.accountInvestment.initialSIPAmount !== ''}
                    helperMessage={errors.accountInvestment.initialSIPAmount}
                    isRequired={true}
                    disableHelperMessageHeight={true}
                />
            </div>
        </div>)
    };

    const renderMobileCurrentFinancialsDetails = () => {
        const {handleChange, errors, accountFinancial, dropdownValues} = props;
        const {applicableIncome} = dropdownValues;

        const {primaryHolderIncomeCode} = accountFinancial;

        return (<div className='block-padding'>
            <DLText
                id={'revenue'}
                type={'normal'}
                text={'Current Financials'}
                fontSize={'sm'}
                fontWeight={'semi-bold'}
                marginBottom={'md'}
                fontColor={'black'}/>

            <div id='accountFinancial-primaryHolderIncomeCode-id'>
                <DLDropDown
                    id={'family-income-input-field'}
                    label={'Primary Holder Income'}
                    isRequired={true}
                    labelPlacement={'top'}
                    noOptionsMessage={'No options found'}
                    placeholder={''}
                    options={applicableIncome}
                    minWidth={'100%'}
                    onSelect={(e) => {
                        handleChange('primaryHolderIncomeCode', e, 'accountFinancial')
                    }}
                    value={primaryHolderIncomeCode}
                    error={errors.accountFinancial.primaryHolderIncomeCode !== ''}
                    helperMessage={errors.accountFinancial.primaryHolderIncomeCode}
                    disableHelperMessageHeight={true}
                    isClearable={false}
                />
            </div>
        </div>);
    };

    const renderMobilePlanningAndGoalsDetails = () => {
        const {handleChange, errors, accountGoal, accountFinancial, dropdownValues} = props;
        const {yearsForRetirement} = dropdownValues;

        const {shortTermGoal, midTermGoal, longTermGoal} = accountGoal;
        const {primaryHolderExpectedRetirementYear, primaryHolderMonthlyRequirementPostRetirement} = accountFinancial;

        return (<div className='block-padding'>
            <DLText
                id={'revenue'}
                type={'normal'}
                text={'Planning And Goals'}
                fontSize={'sm'}
                fontWeight={'semi-bold'}
                marginBottom={'md'}
                fontColor={'black'}/>
            <div id='accountGoal-shortTermGoal-id'>
                <DLInputTextField
                    id={'shortTermGoals-input-field'}
                    label={'Short Term Goals (Optional)'}
                    maxCharLength={301}
                    isClearable={false}
                    isMulti={true}
                    rows={3}
                    disableHelperMessageHeight={true}
                    onChangeValue={(e) => {
                        handleChange('shortTermGoal', e, 'accountGoal')
                    }}
                    value={shortTermGoal}
                    error={errors.accountGoal.shortTermGoal !== ''}
                    helperMessage={errors.accountGoal.shortTermGoal}
                />
            </div>
            <div id='accountGoal-midTermGoal-id'>
                <DLInputTextField
                    id={'midTermGoals-input-field'}
                    label={'Mid Term Goals (Optional)'}
                    maxCharLength={301}
                    isClearable={false}
                    isMulti={true}
                    rows={3}
                    disableHelperMessageHeight={true}
                    onChangeValue={(e) => {
                        handleChange('midTermGoal', e, 'accountGoal')
                    }}
                    value={midTermGoal}
                    error={errors.accountGoal.midTermGoal !== ''}
                    helperMessage={errors.accountGoal.midTermGoal}
                />
            </div>
            <div id='accountGoal-longTermGoal-id'>
                <DLInputTextField
                    id={'investment-objective-input-field'}
                    label={'Long Term Goals (Optional)'}
                    maxCharLength={301}
                    isClearable={false}
                    isMulti={true}
                    rows={3}
                    disableHelperMessageHeight={true}
                    onChangeValue={(e) => {
                        handleChange('longTermGoal', e, 'accountGoal')
                    }}
                    value={longTermGoal}
                    error={errors.accountGoal.longTermGoal !== ''}
                    helperMessage={errors.accountGoal.longTermGoal}
                />
            </div>
            <div id='accountFinancial-primaryHolderExpectedRetirementYear-id'>
                <DLDropDown
                    id={'annual-income-input-field'}
                    label={'Expected Year of Retirement (Optional)'}
                    labelPlacement={'top'}
                    noOptionsMessage={'No options found'}
                    placeholder={''}
                    options={yearsForRetirement}
                    minWidth={'100%'}
                    onSelect={(e) => {
                        handleChange('primaryHolderExpectedRetirementYear', e, 'accountFinancial')
                    }}
                    value={primaryHolderExpectedRetirementYear}
                    error={errors.accountFinancial.primaryHolderExpectedRetirementYear !== ''}
                    helperMessage={errors.accountFinancial.primaryHolderExpectedRetirementYear}
                    disableHelperMessageHeight={true}
                    isClearable={false}
                />
            </div>
            <div id='accountFinancial-primaryHolderMonthlyRequirementPostRetirement-id'>
                <DLInputTextField
                    id={'primaryHolderMonthlyRequirementPostRetirement-field'}
                    label={'Monthly Requirement Post Retirement (Optional)'}
                    inputType={'number'}
                    maxCharLength={10}
                    isClearable={false}
                    onChangeValue={(e) => {
                        handleChange('primaryHolderMonthlyRequirementPostRetirement', e, 'accountFinancial')
                    }}
                    value={primaryHolderMonthlyRequirementPostRetirement}
                    error={errors.accountFinancial.primaryHolderMonthlyRequirementPostRetirement !== ''}
                    helperMessage={errors.accountFinancial.primaryHolderMonthlyRequirementPostRetirement}
                    disableHelperMessageHeight={true}
                />
            </div>
        </div>);
    };

    const renderMobileFinancialCategorization = () => {
        const {handleChange, errors, accountCategorization, dropdownValues} = props;
        const {categories, profiles} = dropdownValues;

        const {
            categoryId, riskProfileId, maximumAmount, CRMNote, financialProfileId, cashflowTypeId, spendingHabitId,
            decisionMakingStyleId, investmentStyleId, moneyManagementStyleId, detailOrientation, insuranceToIncomeRatioId,
            realEstateToNetworthRatioId
        } = accountCategorization;


        return (<div className='block-padding'>
            <DLText
                id={'revenue'}
                type={'normal'}
                text={'Financial Categorization '}
                fontSize={'sm'}
                marginBottom={'none'}
                fontWeight={'semi-bold'}
                fontColor={'black'}/>

            {renderFinancialCategorizationNote()}
            <div id={'accountCategorization-categoryId-id'}>
                <DLDropDown
                    id={'categoryId-dropDown'}
                    label={'Category'}
                    labelPlacement={'top'}
                    noOptionsMessage={'No options found'}
                    placeholder={''}
                    options={categories}
                    minWidth={'100%'}
                    onSelect={(e) => {
                        handleChange('categoryId', e, 'accountCategorization')
                    }}
                    value={categoryId}
                    error={errors.accountCategorization.categoryId !== ''}
                    helperMessage={errors.accountCategorization.categoryId}
                    disableHelperMessageHeight={true}
                    isClearable={false}
                    isRequired={true}
                />
            </div>
            <div id='accountCategorization-financialProfileId-id'
                 className='w-100'>
                <DLDropDown
                    id={'financialProfileId-dropDown'}
                    label={'Profile'}
                    labelPlacement={'top'}
                    noOptionsMessage={'No options found'}
                    placeholder={''}
                    options={profiles}
                    minWidth={'100%'}
                    onSelect={(e) => {
                        handleChange('financialProfileId', e, 'accountCategorization')
                    }}
                    value={financialProfileId}
                    error={errors.accountCategorization.financialProfileId !== ''}
                    helperMessage={errors.accountCategorization.financialProfileId}
                    disableHelperMessageHeight={true}
                    isClearable={false}
                    isRequired={true}
                />
            </div>
            <div id={'accountCategorization-riskProfileId-id'}>
                {getRiskProfileDropdown()}
            </div>
            <div id={'accountCategorization-maximumAmount-id'}>
                <DLInputTextField
                    id={'maximum-amount-input-field'}
                    label={'Maximum Investment Potential'}
                    inputType={'number'}
                    maxCharLength={12}
                    isClearable={false}
                    disableHelperMessageHeight={'none'}
                    onChangeValue={(e) => {
                        handleChange('maximumAmount', e, 'accountCategorization')
                    }}
                    value={numberWithCommas(maximumAmount)}
                    error={errors.accountCategorization.maximumAmount !== ''}
                    helperMessage={errors.accountCategorization.maximumAmount}
                    isRequired={true}
                />
            </div>
            <div id='accountCategorization-cashflowTypeId-id'
                 className={'mb-3'}>
                <DLText id={''}
                        text={'Cashflow'}
                        fontSize={"xs"}
                        fontColor={errors.accountCategorization.cashflowTypeId !== ''? "danger" : "grayDark"}
                        marginBottom={"xs"}
                />
                <div style={{marginLeft: '10px'}}>
                    <DLRadioGroup
                        id={'hours-radioBtn'}
                        options={dropdownValues.cashFlowOptions}
                        label={''}
                        value={cashflowTypeId}
                        type={'inline'}
                        labelFontSize={'sm'}
                        labelFontColor={'black'}
                        onChangeValue={(e) => {
                            handleChange('cashflowTypeId', e, 'accountCategorization')
                        }}
                        error={errors.accountCategorization.cashflowTypeId !== ''}
                        helperMessage={errors.accountCategorization.cashflowTypeId}
                        helperMessageColor={"danger"}
                        disableHelperMessageHeight={true}
                        disableDescriptionMessageHeight={true}
                    />
                </div>
            </div>

            <div id='accountCategorization-spendingHabitId-id'
                 className={'mb-3'}>
                <DLText id={''}
                        text={'Spending Habit'}
                        fontSize={"xs"}
                        fontColor={errors.accountCategorization.spendingHabitId !== ''? "danger" : "grayDark"}
                        marginBottom={"xs"}
                />
                <div style={{marginLeft: '10px'}}>
                    <DLRadioGroup
                        id={'hours-radioBtn'}
                        options={dropdownValues.spendingHabitsOptions}
                        label={''}
                        value={spendingHabitId}
                        type={'inline'}
                        labelFontSize={'sm'}
                        labelFontColor={'black'}
                        onChangeValue={(e) => {
                            handleChange('spendingHabitId', e, 'accountCategorization')
                        }}
                        error={errors.accountCategorization.spendingHabitId !== ''}
                        helperMessage={errors.accountCategorization.spendingHabitId}
                        helperMessageColor={"danger"}
                        disableHelperMessageHeight={true}
                        disableDescriptionMessageHeight={true}
                    />
                </div>
            </div>

            <div className="d-flex gap-3">
                <div id='accountCategorization-realEstateToNetworthRatioId-id'
                     className={''}>
                    <DLDropDown
                        id={'spent-on-real-estate-dropDown'}
                        label={'Real Estate To Net Worth Ratio'}
                        labelPlacement={'top'}
                        noOptionsMessage={'No options found'}
                        placeholder={''}
                        options={dropdownValues.spentOnRealEstateOptions}
                        minWidth={'100%'}
                        onSelect={(e) => {
                            handleChange('realEstateToNetworthRatioId', e, 'accountCategorization')
                        }}
                        value={realEstateToNetworthRatioId}
                        error={errors.accountCategorization.realEstateToNetworthRatioId !== ''}
                        helperMessage={errors.accountCategorization.realEstateToNetworthRatioId}
                        isClearable={false}
                        isRequired={true}
                        disableHelperMessageHeight={true}
                    />
                </div>
                <div id='accountCategorization-insuranceToIncomeRatioId-id'
                     className={''}>
                    <DLDropDown
                        id={'insuranceToIncomeRatioId-dropDown'}
                        label={'Insurance To Income Ratio'}
                        labelPlacement={'top'}
                        noOptionsMessage={'No options found'}
                        placeholder={''}
                        options={dropdownValues.spentOnInsuranceOptions}
                        minWidth={'100%'}
                        onSelect={(e) => {
                            handleChange('insuranceToIncomeRatioId', e, 'accountCategorization')
                        }}
                        value={insuranceToIncomeRatioId}
                        error={errors.accountCategorization.insuranceToIncomeRatioId !== ''}
                        helperMessage={errors.accountCategorization.insuranceToIncomeRatioId}
                        isClearable={false}
                        isRequired={true}
                        disableHelperMessageHeight={true}
                    />
                </div>
            </div>

            <div id='accountCategorization-decisionMakingStyleId-id'
                 className={'mb-3'}>
                <DLText id={''}
                        text={'Decision Making Style'}
                        fontSize={"xs"}
                        fontColor={errors.accountCategorization.decisionMakingStyleId !== ''? "danger" : "grayDark"}
                        marginBottom={"xs"}
                />
                <div style={{marginLeft: '10px'}}>
                    <DLRadioGroup
                        id={'hours-radioBtn'}
                        options={dropdownValues.personalityTraits}
                        label={''}
                        value={decisionMakingStyleId}
                        type={'inline'}
                        labelFontSize={'sm'}
                        labelFontColor={'black'}
                        onChangeValue={(e) => {
                            handleChange('decisionMakingStyleId', e, 'accountCategorization')
                        }}
                        error={errors.accountCategorization.decisionMakingStyleId !== ''}
                        helperMessage={errors.accountCategorization.decisionMakingStyleId}
                        helperMessageColor={"danger"}
                        disableHelperMessageHeight={true}
                        disableDescriptionMessageHeight={true}
                    />
                </div>
            </div>

            <div id='accountCategorization-investmentStyleId-id'
                 className={'mb-3'}>
                <DLText id={''}
                        text={'Investment Style'}
                        fontSize={"xs"}
                        fontColor={errors.accountCategorization.investmentStyleId !== ''? "danger" : "grayDark"}
                        marginBottom={"xs"}
                />
                <div style={{marginLeft: '10px'}}>
                    <DLRadioGroup
                        id={'hours-radioBtn'}
                        options={dropdownValues.decisionApproaches}
                        label={''}
                        value={investmentStyleId}
                        type={'inline'}
                        labelFontSize={'sm'}
                        labelFontColor={'black'}
                        onChangeValue={(e) => {
                            handleChange('investmentStyleId', e, 'accountCategorization')
                        }}
                        error={errors.accountCategorization.investmentStyleId !== ''}
                        helperMessage={errors.accountCategorization.investmentStyleId}
                        helperMessageColor={"danger"}
                        disableHelperMessageHeight={true}
                        disableDescriptionMessageHeight={true}
                    />
                </div>
            </div>

            <div id='accountCategorization-moneyManagementStyleId-id'
                 className={'mb-3'}>
                <DLText id={''}
                        text={'Money Management Style'}
                        fontSize={"xs"}
                        fontColor={errors.accountCategorization.moneyManagementStyleId !== ''? "danger" : "grayDark"}
                        marginBottom={"xs"}
                />
                <div style={{marginLeft: '10px'}}>
                    <DLRadioGroup
                        id={'hours-radioBtn'}
                        options={dropdownValues.financialApproaches}
                        label={''}
                        value={moneyManagementStyleId}
                        type={'inline'}
                        labelFontSize={'sm'}
                        labelFontColor={'black'}
                        onChangeValue={(e) => {
                            handleChange('moneyManagementStyleId', e, 'accountCategorization')
                        }}
                        error={errors.accountCategorization.moneyManagementStyleId !== ''}
                        helperMessage={errors.accountCategorization.moneyManagementStyleId}
                        helperMessageColor={"danger"}
                        disableHelperMessageHeight={true}
                        disableDescriptionMessageHeight={true}
                    />
                </div>
            </div>

            <div id='accountCategorization-detailOrientation-id'
                 className={'mb-3'}>
                <DLText id={''}
                        text={'Detail Oriented'}
                        fontSize={"xs"}
                        fontColor={errors.accountCategorization.detailOrientation !== ''? "danger" : "grayDark"}
                        marginBottom={"xs"}
                />
                <div style={{marginLeft: '10px'}}>
                    <DLRadioGroup
                        id={'hours-radioBtn'}
                        options={detailOriented}
                        label={''}
                        value={detailOrientation}
                        type={'inline'}
                        labelFontSize={'sm'}
                        labelFontColor={'black'}
                        onChangeValue={(e) => {
                            handleChange('detailOrientation', e, 'accountCategorization')
                        }}
                        error={errors.accountCategorization.detailOrientation !== ''}
                        helperMessage={errors.accountCategorization.detailOrientation}
                        helperMessageColor={"danger"}
                        disableHelperMessageHeight={true}
                        disableDescriptionMessageHeight={true}
                    />
                </div>
            </div>

            <div id={'accountCategorization-CRMNote-id'}>
                <DLInputTextField
                    id={'client-notes-input-field'}
                    label={'Client Notes'}
                    maxCharLength={301}
                    isClearable={false}
                    isMulti={true}
                    rows={4}
                    disableHelperMessageHeight={true}
                    onChangeValue={(e) => {
                        handleChange('CRMNote', e, 'accountCategorization')
                    }}
                    value={CRMNote}
                    error={errors.accountCategorization.CRMNote !== ''}
                    helperMessage={errors.accountCategorization.CRMNote}
                    isRequired={true}
                />
            </div>
        </div>);
    };


    const renderButtonUI = () => {
        const {onNextClick, onSaveAndCloseClick} = props;
        const {isFromOnboardingFlow} = props;
        return (
            <div className='sticky-bottom-buttons'>
                {isFromOnboardingFlow ?
                    <>
                        <DLButton
                            id={'save-close-button'}
                            styleType={'outlined'}
                            borderType={'square'}
                            buttonSize={'sm'}
                            fontSize={'sm'}
                            sideMargin={'none'}
                            onClick={() => {
                                onSaveAndCloseClick()
                            }}
                            label={'Save & Close'}
                        />
                        <DLButton id={'next-button'}
                                  buttonSize={'sm'}
                                  fontSize={'sm'}
                                  sideMargin={'none'}
                                  onClick={() => {
                                      onNextClick()
                                  }}
                                  label={"Next"}/>
                    </>
                    :
                    <DLButton id={'delete-button'}
                              buttonSize={'sm'}
                              fontSize={'sm'}
                              sideMargin={'none'}
                              onClick={() => {
                                  onNextClick()
                              }}
                              label={"Save"}/>
                }
            </div>
        );

    };

    const renderErrorUI = () => {
        const {responseErrors, totalErrors, isMobileView} = props;

        if (isEmpty(responseErrors) || totalErrors === 0) {
            return '';
        }

        return (
            <div id='page-level-errors' className={isMobileView ? 'page-container' : 'my-3'}>
                {responseErrors.slice(0, 3).map((errorMessage, index) => (
                    <div key={index} className='py-2 align-items-center d-flex message-block'>
                        <div className='error-vertical-line'/>
                        <div className='error-panel'>
                            {errorMessage}
                        </div>
                    </div>
                ))}
                {totalErrors > 3 && (
                    <div className='py-2 align-items-center d-flex'>
                        {`...${totalErrors - 3} More`}
                    </div>
                )}
            </div>
        );
    };

    const renderMobileUI = () => {
        return (
            <div style={{maxHeight: 'calc(100dvh - 230px)', overflowY: 'auto'}}>
                <div className='mb-3 bg-white'>
                    {renderMobileInvestmentDetails()}
                    <div style={{height: '5px', backgroundColor: appTheme.appColor.gray}}/>

                    {renderMobileCurrentFinancialsDetails()}
                    <div style={{height: '5px', backgroundColor: appTheme.appColor.gray}}/>

                    {renderMobilePlanningAndGoalsDetails()}
                    <div style={{height: '5px', backgroundColor: appTheme.appColor.gray}}/>

                    {renderMobileFinancialCategorization()}
                </div>
                {renderButtonUI()}
            </div>
        )
    };

    const renderDesktopUI = () => {
        const {isFromOnboardingFlow} = props;
        return (
            <div style={{
                maxHeight: isFromOnboardingFlow ? 'calc(100dvh - 200px)' : 'calc(100dvh - 200px)',
                overflowY: 'auto'
            }}>
                <div className={isFromOnboardingFlow ? 'pb-3 page-container' : 'pt-3 pb-3 page-container'}>
                    {renderErrorUI()}
                    {renderInvestmentDetails()}
                    {renderCurrentFinancialsDetails()}
                    {renderPlanningAndGoalsDetails()}
                    {renderFinancialCategorization()}
                </div>
                {renderButtonUI()}
            </div>
        )
    };


    return (
        <>
            {props.isMobileView ? renderMobileUI() : renderDesktopUI()}
            <DLLoader isVisible={props.isLoaderVisible} type={'screen'}/>
        </>
    );
};

export default FinancialProfileView;
