import React, {useEffect, useState} from 'react';

import {APP_CONST} from "../../../../../../AppComponents/AppConstants";
import {isEmpty} from "../../../../../../utils/validations";

import {getNFTHistoryDetails} from "../../../../NFTAction";
import {connect} from "react-redux";
import HistoryBlockView from "./HistoryBlockView";

const HistoryBlock = (props) => {

    const [NFTHistoryState, setNFTHistoryState] = useState({
        events: [],
        isShowLoader: false,
        UIState: '',
        responseError: '',
    });
    const {id, profile, handleHistoryBlockRefresh, isHistoryBlockRefresh, isFrom, handleMarkAsCompleted,advisoryUsers} = props;

    useEffect(() => {

        if (isFrom === "LISTING") {
            getData();
            return;
        }

        if (isHistoryBlockRefresh) {
            handleHistoryBlockRefresh(false);
            getData();
        }

    }, [props.id, isHistoryBlockRefresh]);

    const getIsContacted = (event) => {
        return event.some(item => item.type === "COMPLETED");
    };

    const getData = () => {
        setNFTHistoryState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));


        getNFTHistoryDetails(id)
            .then(res => {
                if (res?.success) {
                    setNFTHistoryState((preState) => ({
                            ...preState,
                            isShowLoader: false,
                            UIState: APP_CONST.CONTENT_AVAILABLE,
                            events: isEmpty(res?.data?.nftRequestAudit) ? [] : res?.data?.nftRequestAudit,
                        }
                    ));

                    if (!isEmpty(res?.data?.nftRequestAudit)) {
                        getIsContacted(res?.data?.nftRequestAudit) && handleMarkAsCompleted();
                    }
                } else {
                    setNFTHistoryState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_UNAVAILABLE,
                        responseError: res.__error
                    }));
                }
            })
    };

    const getAdvisoryUser = (id) => {
        let usersName = '';
        if (!isEmpty(advisoryUsers)) {
            usersName = advisoryUsers.find((item) => {
                if (item?.id === id) {
                    return item;
                }
            });
        }

        if (!isEmpty(usersName)) {
            let firstName = isEmpty(usersName?.firstName) ? '' : usersName?.firstName + ' ';
            let lastName = isEmpty(usersName?.lastName) ? '' : usersName?.lastName;
            return firstName + lastName;
        }

        return '-';
    };

    const getSentenceCase = (str) => {
        switch (str) {
            case 'COMMENT':
                return 'Remark';
            case 'IN_PROGRESS':
                return 'In progress';
            case 'COMPLETED':
                return 'Completed';
            case 'DISCARDED':
                return 'Discarded';
            case 'REMARK':
                return 'Remark';
            case 'CREATED':
                return 'Raised';
            default:
                return str;
        }
    };

    return (
        <div className='bg-white border-rounded'>
            <HistoryBlockView
                {...props}
                {...NFTHistoryState}
                getAdvisoryUser={getAdvisoryUser}
                getSentenceCase={getSentenceCase}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    CRMDetails: state.leadState.CRMDetails,
});
export default connect(mapStateToProps, {})(HistoryBlock);
