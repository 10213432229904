import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import moment from "moment";
import {APP_CONST} from "../../../AppComponents/AppConstants";
import {isEmpty} from "../../../core/components/DLComponentHelper";
import {
    setFromDate,
    setSearchKeyword,
    setToDate,
    setStatus,
    resetOnboardingListingFilters,
    setSkipCount,
    setLimitCount,
    setPageCount,
    setOnboardingListingRowPerPageCount,
    getOnboardingListing,
    setCategory,
    setAccountType,
    setCRMForListing,
    setLocationForListing,
    setApplicationStatusForListing, setBankMandateStatusForListing
} from "../onboardingListingAction";

import {setLeadsRowPerPageCount} from "../../../leads/LeadsActions";
import {
    getAccountTypeOptions,
    getCategoryOptions, getCRMs, getLocations
} from "../../OnboardingActions";
import {ECSStatuses, onboardingApplicationStatuses, onboardingStatuses} from "../../OnboardingConsts";
import ListingForManagementView from "./ListingForManagementView";

const ListingForManagement = (props) => {
    const [listingForManagementState, setListingForManagementState] = useState({
        onboardingList: [],
        listingCount: 0,
        skipCount: 0,
        limitCount: 50,
        isShowFilterMobile: false,
        isCreateModalOpen: false,
        isShowLoaderMaster: false,

        UIState: '',
        errorInApi: '',
        isShowLoader: false,
        filters: {
            keyword: '',
            fromDate: '',
            toDate: '',
            iinStatus: [],
            status: [],
            category: [],
            type: [],
            CRMId: [],
            location: [],
            ecsStatus: []
        },
        accountCategories: [],
        accountTypesDropdownValues: [],
        accountCategoriesDropdownValues: [],
        statusDropdownValues: [],
        applicationStatusDropdownValues: [],
        CRMDropdownValues: [],
        locationDropdownValues: [],
        ECSStatusDropdownValues: [],
        badgeData : {
            title : '',
            data : ''
        }
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    useEffect(() => {
        getAccountCategories()
    }, []);

    useEffect(() => {
        getOnboardingList();
    }, [props.onboardingFilter]);

    const getAccountCategories = () => {
        const {profile, onboardingFilter} = props;

        setListingForManagementState(prevState => ({
            ...prevState,
            isShowLoaderMaster: true
        }));

        getCategoryOptions()
            .then(categoryRes => {
                if (categoryRes.success) {
                    const categoryData = categoryRes?.data?.categoryType;
                    getAccountTypeOptions()
                        .then(accountTypeRes => {
                            if (accountTypeRes.success) {
                                const accountTypeData = accountTypeRes?.data?.accountType;

                                getCRMs().then((CRMRes) => {
                                    if (CRMRes?.success) {
                                        const CRMData = CRMRes?.data?.advisoryUsers;

                                        getLocations().then((locationsRes) => {
                                            if (locationsRes?.success) {
                                                const locationData = locationsRes?.data?.locations;
                                                setListingForManagementState(prevState => ({
                                                    ...prevState,
                                                    accountCategories: categoryData,
                                                    accountTypesDropdownValues: setValuesForDropdown(accountTypeData, "name", "id"),
                                                    accountCategoriesDropdownValues: setValuesForDropdown(categoryData, "name", "id"),
                                                    statusDropdownValues: setValuesForDropdown(onboardingStatuses, "label", "value"),
                                                    applicationStatusDropdownValues: setValuesForDropdown(onboardingApplicationStatuses, "label", "value"),
                                                    ECSStatusDropdownValues: setValuesForDropdown(ECSStatuses, "label", "value"),
                                                    CRMDropdownValues: setValuesForCRMDropdown(CRMData, "firstName", "id"),
                                                    locationDropdownValues: setValuesForDropdown(locationData, "description", "id"),
                                                    badgeData : applyFilterBadge(onboardingFilter, CRMData, categoryData),
                                                    isShowLoaderMaster: false
                                                }))
                                            }else{
                                                setListingForManagementState(prevState => ({
                                                    ...prevState,
                                                    isShowLoaderMaster: false
                                                }))
                                            }
                                        })
                                    }else{
                                        setListingForManagementState(prevState => ({
                                            ...prevState,
                                            isShowLoaderMaster: false
                                        }))
                                    }
                                })
                            }else{
                                setListingForManagementState(prevState => ({
                                    ...prevState,
                                    isShowLoaderMaster: false
                                }))
                            }
                        })
                }else{
                    setListingForManagementState(prevState => ({
                        ...prevState,
                        isShowLoaderMaster: false
                    }))
                }
            })
    };

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        let list = [{label: "All", value: "ALL"}]
        if (isEmpty(data)) {
            return;
        }
        data.map((item) => {
            list.push({label: item?.[labelToken], value: item?.[valueToken]})
        });
        return list;
    };

    const setValuesForCRMDropdown = (data, labelToken, valueToken) => {
        let list = [{label: "All", value: "ALL"}]
        if (isEmpty(data)) {
            return;
        }
        data.map((item) => {
            list.push({label: item?.[labelToken] + " " + item?.lastName, value: item?.[valueToken]})
        });
        return list;
    };

    const redirectToDetailsScreen = (onboardingId) => {
        props.history.push('/onboardings/' + onboardingId)
    };

    const handleNewOnboarding = () => {
        props.history.push('/onboardings/new')
    };

    const resetOnboardingListing = () => {
        resetOnboardingListingFilters();
    };

    const handleToggleMobileFilter = (value) => {
        setListingForManagementState((prevStep) => ({
            ...prevStep,
            isShowFilterMobile: value
        }));
    };

    const getPaginationNextButtonStatus = () => {
        const {listingCount, skipCount, limitCount} = listingForManagementState;
        let val = false;
        if (listingCount <= rowsPerPage) {
            val = true;
        } else if ((skipCount + limitCount) >= listingCount) {
            val = true;
        }
        return val;
    };

    const resetPagination = () => {
        setLeadsRowPerPageCount(50);
        setLimitCount(50);
        setPageCount(0);
        setSkipCount(0);
    };

    const handleChangeRowsPerPage = (event) => {
        setListingForManagementState(prevState => ({
            ...prevState,
            isSearchFilterUpdated: true
        }));
        setOnboardingListingRowPerPageCount(parseInt(event.target.value, 10));
        setLimitCount(parseInt(event.target.value, 10));
        setPageCount(0);
        setSkipCount(0);
    };

    const handleChangePage = (event, newPage) => {
        let {limitCount, skipCount} = listingForManagementState;
        const isForward = newPage > page;
        if (isForward) {
            skipCount = skipCount + rowsPerPage;
            limitCount = rowsPerPage
        } else {
            skipCount = skipCount - rowsPerPage;
            limitCount = rowsPerPage
        }

        setSkipCount(skipCount);
        setLimitCount(limitCount);
        setPageCount(newPage);
    };

    const generateFilter = (filter) => {
        let filterObject = {};

        if (!isEmpty(filter.keyword)) {
            filterObject.keyword = filter.keyword;
        }
        if (!isEmpty(filter.toDate)) {
            filterObject.toDate = filter.toDate;
        }
        if (!isEmpty(filter.fromDate)) {
            filterObject.fromDate = filter.fromDate;
        }
        if (!isEmpty(filter.iinStatus) && filter.iinStatus[0] !== "ALL") {
            filterObject.iinStatus = filter.iinStatus;
        }
        if (!isEmpty(filter.category) && filter.category[0] !== "ALL") {
            filterObject.category = filter.category;
        }
        if (!isEmpty(filter.type) && filter.type[0] !== "ALL") {
            filterObject.type = filter.type;
        }
        if (!isEmpty(filter.CRMId) && filter.CRMId[0] !== "ALL") {
            filterObject.CRMId = filter.CRMId;
        }
        if (!isEmpty(filter.location) && filter.location[0] !== "ALL") {
            filterObject.location = filter.location;
        }
        if (!isEmpty(filter.status) && filter.status[0] !== "ALL") {
            filterObject.status = filter.status;
        }
        if (!isEmpty(filter.ecsStatus) && filter.ecsStatus[0] !== "ALL") {
            filterObject.ecsStatus = filter.ecsStatus;
        }
        return filterObject;
    };

    const getOnboardingList = () => {
        const {profile, onboardingFilter} = props;

        setListingForManagementState(prevState => ({
            ...prevState,
            isShowLoader: true,
        }));

        let payload = {
            "skipCount": onboardingFilter.skipCount,
            "limitCount": onboardingFilter.limitCount,
            "filters": generateFilter(onboardingFilter)
        };

        getOnboardingListing(payload)
            .then(res => {
                if (res?.success) {
                    setListingForManagementState(prevState => ({
                        ...prevState,
                        onboardingList: res.data?.onboardingApplications,
                        listingCount: res.data?.count,
                        skipCount: onboardingFilter.skipCount,
                        limitCount: onboardingFilter.limitCount,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_AVAILABLE,
                    }));
                    setPage(isEmpty(onboardingFilter.page) ? 0 : onboardingFilter.page);
                    setRowsPerPage(isEmpty(onboardingFilter.rowsPerPage) ? 50 : onboardingFilter.rowsPerPage)

                } else {
                    setListingForManagementState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    }));
                }
            })
    };

    const onChangeFilter = (name, value) => {
        resetPagination();
        if (name === 'keyword') {
            setListingForManagementState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    keyword: value,
                }
            }));
            setSearchKeyword(value);
            return;
        }

        if (name === 'fromDate') {
            let fromDate = isEmpty(value) ? null : moment(value).format('YYYY-MM-DD');
            setListingForManagementState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    fromDate: fromDate
                }
            }));
            setFromDate(fromDate);
            return;
        }

        if (name === 'toDate') {
            let toDate = isEmpty(value) ? null : moment(value).format('YYYY-MM-DD');
            setListingForManagementState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    toDate: toDate,
                }
            }));
            setToDate(toDate);
        }
        if (name === 'iinStatus') {
            setListingForManagementState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    iinStatus: [value]
                }
            }));
            setStatus([value]);
        }
        if (name === 'category') {
            setListingForManagementState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    category: [value]
                }
            }));
            setCategory([value]);
        }
        if (name === 'type') {
            setListingForManagementState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    type: [value]
                }
            }));
            setAccountType([value]);
        }
        if (name === 'CRMId') {
            setListingForManagementState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    CRMId: [value]
                }
            }));
            setCRMForListing([value]);
        }
        if (name === 'location') {
            setListingForManagementState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    location: [value]
                }
            }));
            setLocationForListing([value]);
        }
        if (name === 'status') {
            setListingForManagementState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    status: [value]
                }
            }));
            setApplicationStatusForListing([value]);
        }
        if (name === 'ecsStatus') {
            setListingForManagementState((prevStep) => ({
                ...prevStep,
                filters: {
                    ...prevStep.filters,
                    ecsStatus: [value]
                }
            }));
            setBankMandateStatusForListing([value]);
        }
        if(name === 'title'){
            setListingForManagementState((prevStep) => ({
                ...prevStep,
                badgeData: {
                    title : '',
                    data : '',
                    filterKey: '',
                }
            }));
        }
    };

    const getCategoryValue = (selectedStatus, accountCategories) => {
        if (isEmpty(selectedStatus) || isEmpty(accountCategories)) {
            return '-'
        }
        const status = accountCategories.find(status => status.id === selectedStatus);
        return status ? status.name : '-';

    };

    const getStatus = (status) => {
        let label = ""
        onboardingStatuses.forEach((item) => {
            if (status === item?.value) {
                label = item?.label
            }
        })
        return label;
    }

    const getCRMNameById = (id) => {
        const {CRMDropdownValues} = listingForManagementState
        let name = "";
        if (isEmpty(id)) {
            return "";
        }
        CRMDropdownValues.forEach((item) => {
            if (id === item?.value) {
                name = item?.label
            }
        });
        return name;
    };

    const getCRMName = (id, AllCRM) =>{

        let CRMName = ""
        if(isEmpty(id)){
            return "";
        }
        AllCRM.forEach((item)=>{
            if(item?.id === id){
                let firstName = isEmpty(item?.firstName) ? '' : item?.firstName + ' ';
                let lastName = isEmpty(item?.lastName) ? '' : item?.lastName + ' ';
                CRMName =  firstName + lastName;
            }
        });
        return isEmpty(CRMName)? '-' : CRMName;
    };

    const applyFilterBadge = (onboardingFilter,AllCRM, accountCategories) => {
        let badgeObj = {
            title : '',
            data : '',
            filterKey: '',
        };
        if (onboardingFilter?.title === 'Category' && !isEmpty(onboardingFilter?.category)) {
            badgeObj.title= onboardingFilter?.title+ ': ';
            if(onboardingFilter?.category[0] === 'UNASSIGNED'){
                badgeObj.data = 'Unassigned'
            }else{
                badgeObj.data = getCategoryValue(onboardingFilter?.category[0], accountCategories);
            }
            badgeObj.filterKey = 'category'
        }
        if (onboardingFilter?.title === 'Ageing' && !isEmpty(onboardingFilter.agingKey)) {
            badgeObj.title= onboardingFilter?.title+ ': ';
            badgeObj.data= onboardingFilter.agingKey;
            badgeObj.filterKey = 'agingKey'
        }
        if (onboardingFilter?.title === 'Account Type' && !isEmpty(onboardingFilter.type)) {
            if((onboardingFilter.type?.[0] === 'ALL')){
                badgeObj.title= '';
                badgeObj.data= '';
                badgeObj.filterKey = '';
            }else {
                badgeObj.title= onboardingFilter?.title+ ': ';
                badgeObj.data= (onboardingFilter.type?.[0] === 'INDIVIDUAL') ? 'Individual' : 'Joint';
                badgeObj.filterKey = 'type'
            }
        }
        if (onboardingFilter?.title === 'Location' && !isEmpty(onboardingFilter?.location) && (onboardingFilter?.location[0] !== 'ALL')) {
            badgeObj.title= onboardingFilter?.title+ ': ';
            badgeObj.data = onboardingFilter?.location[0];
            badgeObj.filterKey = 'location'
        }
        return badgeObj;
    };

    const handleRefreshButton=()=>{
        getAccountCategories();
        getOnboardingList();
    };

    return (
        <ListingForManagementView
            {...props}
            {...listingForManagementState}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangeFilter={onChangeFilter}
            handleChangePage={handleChangePage}
            handleRefreshButton={handleRefreshButton}
            handleNewOnboarding={handleNewOnboarding}
            resetOnboardingListing={resetOnboardingListing}
            redirectToDetailsScreen={redirectToDetailsScreen}
            handleToggleMobileFilter={handleToggleMobileFilter}
            getPaginationNextButtonStatus={getPaginationNextButtonStatus}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            getCategoryValue={getCategoryValue}
            getStatus={getStatus}
            getCRMNameById={getCRMNameById}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    onboardingFilter: state.onboardingState.onboardingFilter,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(ListingForManagement);
