import {httpGet, httpPost, httpPut} from "../../utils/httpHelper";
import store from "../../store";
import {
    setSearchKeywordValue, setFromDateValue, setToDateValue,
    setStatusValue, resetAllFilters, setLimitCountValue,
    setPageValue, setRowsPerPageValue, setSkipCountValue, setTypeOfRequestValue, setCRMIdValue
} from "./NFTReducer"

export const getTransactionListing = (data) => {
    let URL = '/service/advisory/nftTransaction/all';

    return httpPost(URL, data)
        .then(res => {
            return res;
        });
};

export const raiseNFTRequest = (data,handleErrorInComponent) => {
    return httpPost('/service/advisory/nftTransaction', data, handleErrorInComponent).then(res => {
        return res;
    })
};

export const clientsListing = (data,handleErrorInComponent) => {
    return httpPost('/service/advisory/activatedAccount/search', data, handleErrorInComponent).then(res => {
        return res;
    })
};

export const getCategoryOptions = () => {
    return httpGet('/service/advisory/categoryType/all').then(res => {
        return res;
    });
};

export const getTypeOfRequestFilterOptions = () => {
    return httpGet('/service/advisory/nftRequestType/all').then(res => {
        return res;
    });
};

export const getAccountTypeOptions = () => {
    return httpGet('/service/advisory/accountType/all').then(res => {
        return res;
    });
};

export const getCRMs = () => {
    return httpGet('/service/advisory/advisoryUser/all?role=CRM').then(res => {
        return res;
    })
};

export const getNFTDetails = (id) => {
    return httpGet('/service/advisory/nftTransaction/?id='+ id).then(res => {
        return res;
    })
};

export const getNFTHistoryDetails = (id) => {
    return httpGet('/service/advisory/nftTransaction/history?id='+ id).then(res => {
        return res;
    })
};

export const updateMarkedAsCompleted = (data) => {
    return httpPut('/service/advisory/nftTransaction/status/completed', data, true).then(res => {
        return res;
    })
};

export const updateMarkedAsInProgress = (data) => {
    return httpPut('/service/advisory/nftTransaction/status/inProgress', data, true).then(res => {
        return res;
    })
};

export const removeNFTDetails = (data) => {
    return httpPut('/service/advisory/nftTransaction/status/discarded', data, true).then(res => {
        return res;
    })
};


export const addRemark = (data) => {
    return httpPost('/service/advisory/nftTransaction/comments', data, true).then(res => {
        return res;
    })
};


export const setSearchKeyword = (data) => {
    store.dispatch(setSearchKeywordValue(data))
};

export const setFromDate = (data) => {
    store.dispatch(setFromDateValue(data))
};

export const setToDate = (data) => {
    store.dispatch(setToDateValue(data))
};

export const setStatus = (data) => {
    store.dispatch(setStatusValue(data))
};

export const setTypeOfRequest = (data) => {
    store.dispatch(setTypeOfRequestValue(data))
};

export const setCRMId = (data) => {
    store.dispatch(setCRMIdValue(data))
};

export const resetNFTFilters = (data) => {
    store.dispatch(resetAllFilters(data))
};

export const setSkipCount = (data) => {
    store.dispatch(setSkipCountValue(data))
};

export const setLimitCount = (data) => {
    store.dispatch(setLimitCountValue(data))
};

export const setPageCount = (data) => {
    store.dispatch(setPageValue(data))
};

export const setLeadsRowPerPageCount = (data) => {
    store.dispatch(setRowsPerPageValue(data))
};