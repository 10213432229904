import {httpGet, httpPost} from "../utils/httpHelper";
import {downloadS3BucketFile, uploadData} from "../utils/FileHelper";


export const getCategoryOptions = () => {
    return httpGet('/service/advisory/categoryType/all').then(res => {
        return res;
    });
};

export const getAccountTypeOptions = () => {
    return httpGet('/service/advisory/accountType/all').then(res => {
        return res;
    });
};

export const getCRMDetails = () => {
    return httpGet('/service/advisory/advisoryUser/all').then(res => {
        return res;
    })
};

export const getAdvisoryUsers = () => {
    return httpGet('/service/advisory/advisoryUser/all').then(res => {
        return res;
    })
};

export const getCRMs = () => {
    return httpGet('/service/advisory/advisoryUser/all?role=CRM').then(res => {
        return res;
    })
};

export const getLocations = () => {
    return httpGet('/service/advisory/location/all').then(res => {
        return res;
    })
};