import React from 'react';
import {isEmpty} from "../../../../core/components/DLComponentHelper";
import {convertUTCToLocalTime} from "../../../../utils/helper";
import {renderNFTDetailsStatusBadge} from "../../NFTHelper";

import DLText from "../../../../core/components/DLText/DLText";
import DLButton from "../../../../core/components/DLButton/DLButton";
import DLLoader from "../../../../core/components/DLLoader/DLLoader";
import DLMenuOptions from "../../../../core/components/DLMenuOptions/DLMenuOptions";
import HistoryBlock from "./component/historyBlock/HistoryBlock";
import AddRemark from "./component/addRemark/AddRemark";
import RemoveNFTDetails from "./component/removeModel/RemoveNFTDetails";
import ConfirmationModel from "./component/ConfirmationModel";
import InitiateModel from "./component/InitiateModel";

const NFTDetailsView = (props) => {
    const {
        isMobileView, name, openDate, category, advisoryUserId, IINNumber, accountType, remark, status, isShowLoader, handleOpenInitiateModal,
        handleOpenAddRemarkModal, handleConfirmationModal, UIState, handleOpenRemoveModal, isMarkAsCompleted, getAdvisoryUser, typeOfTransaction
    } = props;

    const renderAddRemarkModal = () => {
        const {isAddRemarkModalOpen} = props;

        if (isAddRemarkModalOpen) {
            return (
                <AddRemark {...props}
                />
            )
        }
    };

    const getFormattedTitle = (date) => {
        const newDate = (date === '-') ? '-' : convertUTCToLocalTime(date, 'DD-MM-YYYY');
        return 'By ' + getAdvisoryUser(advisoryUserId) + ' on ' + newDate;
    };

    const getFormattedNames = (names) => {
        if(isEmpty(names)){
           return;
        }
        if (isMobileView) {
            return (

                <div style={{lineHeight:'25px'}}>
                    {names.map((fullName, index) =>
                        <DLText id={'lead-name'}
                                text={fullName}
                                marginBottom={"none"}
                        />
                    )}
                </div>
            )
        }

        return (
            <div className='d-flex gap-1'>{
                names.map((fullName, index) => <div className='d-flex'>
                        <DLText id={'lead-name'}
                                text={fullName}
                                marginBottom={"none"}
                        />
                        {index !== names.length - 1 && ", "}
                    </div>
                )}
            </div>
        )
    };


    const renderDesktopHeader = () => {
        return (
            <div className='w-100'>
                <div className='page-header d-flex justify-content-between'>
                    <div className="d-flex align-items-center">
                        <i className='fa-solid fa-chevron-left cursor fa-2xs'
                           style={{width: 10}}
                           onClick={() => {
                               props.history.goBack()
                           }}
                        />
                        <div className="ps-2">
                            Non-Financial Transaction: {typeOfTransaction}
                        </div>
                    </div>

                </div>
                <div className='d-flex align-items-center mb-3 page-container '>
                    <div className='pe-2 border-end-black d-flex '>
                        <DLText id={'ac-details-name'}
                                fontWeight={'semi-bold'}
                                text={accountType + '-' + IINNumber}
                                marginBottom={"none"}
                        />
                    </div>
                    <div className='px-2 border-end-black cursor'>
                        <DLText id={'category'}
                                text={category}
                                marginBottom={"none"}
                        />
                    </div>
                    <div className='px-2 border-end-black'>
                        {getFormattedNames(name)}
                    </div>
                    <div className='px-2'>
                        {renderNFTDetailsStatusBadge(status)}
                    </div>
                </div>
            </div>
        )
    };

    const renderModalError = () => {
        const {responseError} = props;
        if (responseError) {
            return (
                <div className='py-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseError}
                    </div>
                </div>
            )
        }
    };


    const renderDesktopDetails = () => {
        return (
            <div className="h-100 bg-white border-rounded p-3 "
                 style={{minHeight: 'calc(100vh - 211px)', maxHeight: 'calc(100vh - 211px)'}}>
                <div className='pt-1 ms-1'>
                    <DLText
                        id={'NFT-details-title'}
                        type={'normal'}
                        text={'Request'}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}
                        marginBottom={"none"}
                    />
                </div>
                {renderModalError()}
                <div className='h-100 d-flex ms-1'>
                    <div className='d-flex flex-column flex-grow-1'>
                        <div className='pt-3'>
                            <div className='d-flex gap-4 py-2'>
                                <div style={{minWidth: '120px'}}>
                                    <DLText
                                        id={'remark'}
                                        type={'normal'}
                                        fontSize={'xs'}
                                        text={getFormattedTitle(openDate)}
                                        fontColor={'grayDark'}
                                    />
                                    <DLText
                                        id={'remark'}
                                        type={'normal'}
                                        text={isEmpty(remark) ? '-' : remark}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="sticky-bottom-buttons gap-0">
                    {status === 'OPEN' && <DLButton id={"Remove-button"}
                              styleType={"outlined"}
                              type={"black"}
                              sideMargin={'md'}
                              padding={'none'}
                              onClick={() => {
                                  handleOpenRemoveModal();
                              }}
                              label={'Discard'}
                    />}
                    <DLButton id={'add-a_remark-button'}
                              styleType={"outlined"}
                              type={"black"}
                              sideMargin={'md'}
                              padding={'none'}
                              onClick={() => {
                                  handleOpenAddRemarkModal();
                              }}
                              label={'Add a Remark'}
                    />

                    {status === 'OPEN' && <DLButton
                        id={"assign-to-CRM-button"}
                        styleType={"outlined"}
                        type={"black"}
                        sideMargin={'md'}
                        padding={'none'}
                        onClick={() => {
                            handleOpenInitiateModal();
                        }}
                        label={'Mark as Initiated'}
                    />}

                    {status === 'IN_PROGRESS' && !isMarkAsCompleted && <DLButton
                        id={'mark-as-complete-button'}
                        styleType={"outlined"}
                        type={"black"}
                        sideMargin={'md'}
                        padding={'none'}
                        onClick={() => {
                            handleConfirmationModal(true);
                        }}
                        label={'Mark as Completed'}
                    />}

                </div>
            </div>
        )
    };

    const renderDesktopNFTData = () => {
        return (<div className="pb-4">
            <div className=" row">
                <div className='col-6'>
                    {renderDesktopDetails()}
                </div>
                <div className=' col-6'>
                    <HistoryBlock {...props}/>
                </div>
            </div>
        </div>)
    };

    const renderDesktopUI = () => {
        return (
            <div className='bg-light '>
                {renderDesktopHeader()}
                <div className='page-container page-container bg-light '>
                    {renderDesktopNFTData()}
                </div>
            </div>
        );
    };

    const renderMobileDetails = () => {
        return (
            <div className=' '>
                <div className='border-rounded bg-light p-3'>
                    <div className='text-start'>
                        <DLText
                            id={'details-assigned'}
                            type={'normal'}
                            text={getFormattedTitle(openDate) }
                            fontColor={'grayDark'}
                            marginBottom={'sm'}
                        />
                        <DLText
                            id={'remark-assigned'}
                            type={'normal'}
                            text={isEmpty(remark) ? '-' : remark}
                        />
                    </div>
                </div>
                <div className='border-top'>
                    <HistoryBlock {...props}/>
                </div>

            </div>
        )
    };

    const renderMobileHeader = () => {
        return (
            <div className='page-header'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex '>
                        <i className='fa-solid fa-chevron-left cursor pt-2'
                           style={{width: 10}}
                           onClick={() => {
                               props.history.goBack()
                           }}
                        />
                        <div className='ps-3'>
                            Non-Financial Transaction:
                            {" "+typeOfTransaction}
                        </div>
                    </div>

                </div>
                <div style={{paddingLeft: '28px'}} className='pt-1'>
                    <div className='pe-2  d-flex '>
                        <DLText id={'ac-details-name'}
                                fontWeight={'semi-bold'}
                                text={accountType + '-' + IINNumber}
                                marginBottom={"none"}
                        />
                    </div>
                    <div className=''>
                        <DLText id={'category-email'}
                                text={category}
                                marginBottom={"none"}
                        />
                    </div>
                    {getFormattedNames(name)}
                    <div className='d-flex my-2'>
                        {renderNFTDetailsStatusBadge(status)}
                    </div>
                </div>
            </div>
        )
    };

    const getOptionArray = () => {
        let array = [];
        array.push({
            label:
                <span
                    className='d-flex justify-content-between align-items-center gap-2 py-0 w-100'
                    onClick={() => {
                        handleOpenRemoveModal();
                    }}>
                    Discard
                    </span>,
            value: 'Discard'
        });
        array.push({
            label:
                <span
                    className='d-flex justify-content-between align-items-center gap-2 py-0 w-100'
                    onClick={() => {
                        handleOpenAddRemarkModal();
                    }}>
                    Add a Remark
                    </span>,
            value: 'AddARemark'
        });
        return array;
    };

    const renderMobileNFTData = () => {
        return (
            <div className="pb-4">
                <div className="pt-1 page-container">
                    {renderMobileDetails()}
                </div>
                <div className="sticky-bottom-buttons" style={{gap: 0}}>
                    <div className='d-flex align-items-center m-0 p-0'>
                        <DLMenuOptions id={'menu-opt'}
                                       anchorUi={
                                           <DLButton
                                               id={"on-hold-button"}
                                               sideMargin={'none'}
                                               styleType={"outlined"}
                                               type={"black"}
                                               onClick={() => {
                                               }}
                                               label={'More'}
                                               padding={"none"}
                                           />}
                                       onSelect={() => {
                                       }}
                                       options={getOptionArray()}
                        />
                        {status === 'OPEN' && <DLButton
                            id={"assign-to-CRM-button"}
                            styleType={"outlined"}
                            type={"black"}
                            sideMargin={'md'}
                            padding={'none'}
                            onClick={() => {
                                handleOpenInitiateModal();
                            }}
                            label={'Mark as Initiated'}
                        />}
                        {status === 'IN_PROGRESS' && !isMarkAsCompleted && <DLButton
                            id={"assign-to-CRM-button"}
                            styleType={"outlined"}
                            type={"black"}
                            sideMargin={'md'}
                            padding={'none'}
                            onClick={() => {
                                handleConfirmationModal(true);
                            }}
                            label={'Mark as Completed'}
                        />}
                    </div>
                </div>
            </div>
        );
    };

    const renderRemoveModal = () => {
        const {isMobileView, handleCloseAssignCRMModal, isRemoveModalOpen} = props;

        if (isRemoveModalOpen) {
            if (isMobileView) {
                return <div className="modal-overlay" style={{zIndex: 1100}} onClick={handleCloseAssignCRMModal}>
                    <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                        <RemoveNFTDetails {...props} isOverlay={true}/>
                    </div>
                </div>
            } else {
                return (
                    <RemoveNFTDetails {...props}/>
                )
            }
        }

    };

    const renderConfirmationModel = () => {
        return (
            <ConfirmationModel
                {...props}
            />
        );
    };

    const renderInitiateModel = () => {
        return (
            <InitiateModel
                {...props}
            />
        );
    };

    const renderMobileUI = () => {
        return (
            <div>
                {renderMobileHeader()}
                {renderMobileNFTData()}
            </div>
        );
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader || isEmpty(UIState)}/>);
    };

    if (isEmpty(UIState)) {
        return renderLoader()
    };

    return (
        <div>
            {isMobileView ? renderMobileUI() : renderDesktopUI()}
            {renderLoader()}
            {renderAddRemarkModal()}
            {renderConfirmationModel()}
            {renderRemoveModal()}
            {renderInitiateModel()}
        </div>
    );
};

export default NFTDetailsView;