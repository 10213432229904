import React, {useEffect, useState} from 'react';
import DashboardWidget from "../../../DashboardWidget";
import {connect} from "react-redux";
import {
    getManagementOnboardingAccountType,
    resetOnboardingListingFilters,
    setOnboardingAccountType, setOnboardingStatusTitle
} from "../../../HomePageActions";

const OnboardingAccountType=(props)=> {
    const [accountTypeState, setAccountTypeState] = useState({
        accountTypeData: {},
        isShowLoader: false,
    });

    useEffect(() => {
        getDetails();
    }, [props.isRefreshButtonLoader]);

    const getDetails = () => {
        setAccountTypeState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getManagementOnboardingAccountType().then(res => {
            if (res.success) {
                setAccountTypeState(prevState => ({
                    ...prevState,
                    accountTypeData: res.data,
                    isShowLoader: false,
                }));
            } else {
                setAccountTypeState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                }));
            }
        })
    };

    const setFilters = (item) => {
        resetOnboardingListingFilters();
        setOnboardingAccountType([item.key]);
        setOnboardingStatusTitle('Account Type');
        props.history.push('/onboardings');
    };


    return (
        <DashboardWidget
            {...props}
            {...accountTypeState}
            detailsObj={accountTypeState.accountTypeData}
            setFilters={setFilters}
            title={"Summary by Account Type"}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
});
export default connect(mapStateToProps, {})(OnboardingAccountType);
