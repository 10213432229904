export const headingData = ['Account', 'Category', 'Ticket Number', 'Type of Request', 'Open Date', 'Status'];

export const cellConfig = [
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    },
    {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px'
    }
];

export const statusFilterOptions = [
    {label: 'All', value: 'ALL'},
    {label: 'In Progress', value: 'IN_PROGRESS'},
    {label: 'Completed', value: 'COMPLETED'},
    {label: 'Open', value: 'OPEN'}
];