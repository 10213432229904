import React, {useEffect} from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';

import appTheme from '../../../assets/appTheme';
import {isEmpty} from '../../../utils/validations';
import {debounce} from '../../../core/components/DLComponentHelper';

import DLText from '../../../core/components/DLText/DLText';
import DLInputTextField from '../../../core/components/DLInputTextField/DLInputTextField';
import DLButton from '../../../core/components/DLButton/DLButton';
import DLDatePicker from '../../../core/components/DLDatePicker/DLDatePicker';
import DLDropDown from '../../../core/components/DLDropdown/DLDropDown';

import DLModal from '../../../core/components/DLModal/DLModal';
import DLModalTitle from '../../../core/components/DLModal/DLModalTitle/DLModalTitle';
import DLModalContainer from '../../../core/components/DLModal/DLModalContainer/DLModalContainer';
import DLModalActions from '../../../core/components/DLModal/DLModalActions/DLModalActions';
import DLRadioGroup from '../../../core/components/DLRadioGroup/DLRadioGroup';
import DLSearchAutoComplete from '../../../core/components/DLSearchAutoComplete/DLSearchAutoComplete';
import '../../../assets/css/table.css'
import DLLoader from "../../../core/components/DLLoader/DLLoader";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";

const PersonalProfileView = (props) => {

    const {getTitleValues, handleChange, isMobileView, scrollToFieldId, isTabUI} = props;


    useEffect(() => {
        if (!isEmpty(scrollToFieldId)) {
            const element = document.getElementById(scrollToFieldId);
            if (!isEmpty(element)) {
                element.scrollIntoView({behavior: 'smooth', block: 'center'});
                handleScrollChangeWithDebounce()
            }
        }
    }, [scrollToFieldId || props.selectedScreenObj]);

    const handleScrollChangeWithDebounce = debounce(() => {

        handleChange('scrollToFieldId', '')
    }, 1000);

    const renderSelfInformation = () => {
        const {selfInformation, errors, dropdownValues} = props;

        const {title, firstName, middleName, lastName, dateOfBirth, birthPlace, maritalStatus} = selfInformation;
        let selfInformationError = errors.selfInformation;

        return (
            <div id={'self-info-block'}>
                <div className='section-title'
                     id={'self-information'}>
                    Self Information
                </div>
               <div className='panel'>
                   <div className='row m-0 '>
                       <div id={'first_block'}
                            className='col-3 ps-0'>
                           <div className={''}>
                               <div id='selfInformation-title-id'>
                                   <DLSearchAutoComplete id={'title-dropDown'}
                                                         value={title}
                                                         label={'Title (Optional)'}
                                                         placeholder={''}
                                                         onSelect={(e) => handleChange('title', e, 'selfInformation')}
                                                         isMulti={false}
                                                         isRequired={true}
                                                         isClearable={false}
                                                         isSearchable={false}
                                                         disableHelperMessageHeight={selfInformationError.title === ''}
                                                         itemNotFoundText={'No options found'}
                                                         marginBottom={'none'}
                                                         error={selfInformationError.title !== ''}
                                                         helperMessage={selfInformationError.title}

                                                         defaultOptions={getTitleValues()}
                                                         noOptionsMessage={'No options found'}
                                   />
                               </div>
                           </div>
                       </div>
                       <div id='second_block'
                            className='col-3'>
                           <div id='selfInformation-firstName-id'>
                               <DLInputTextField id={'first-name-input-field'}
                                                 label={'First Name'}
                                                 value={firstName}
                                                 maxCharLength={20}
                                                 inputType={'text'}
                                                 isRequired={true}
                                                 isClearable={false}
                                                 marginBottom={selfInformationError.firstName === '' ? 'none' : 'sm'}
                                                 error={selfInformationError.firstName !== ''}
                                                 helperMessage={selfInformationError.firstName}
                                                 disableHelperMessageHeight={selfInformationError.firstName === ''}
                                                 onChangeValue={(e) => {
                                                     handleChange('firstName', e, 'selfInformation')
                                                 }}
                               />
                           </div>
                       </div>
                       <div id={'third_block'} className='col-6'>
                           <div className='row'>
                               <div className='col-6'>
                                   <div id='selfInformation-middleName-id'>
                                       <DLInputTextField id={'middle-name-input-field'}
                                                         label={'Middle Name (Optional)'}
                                                         value={middleName}
                                                         maxCharLength={20}
                                                         inputType={'text'}
                                                         isRequired={true}
                                                         isClearable={false}
                                                         error={selfInformationError.middleName !== ''}
                                                         helperMessage={selfInformationError.middleName}
                                                         disableHelperMessageHeight={selfInformationError.middleName === ''}
                                                         marginBottom={'none'}
                                                         onChangeValue={(e) => {
                                                             handleChange('middleName', e, 'selfInformation')
                                                         }}
                                       />
                                   </div>
                               </div>
                               <div className='col-6 pe-0'>
                                   <div id='selfInformation-lastName-id'>
                                       <DLInputTextField id={'last-name-input-field'}
                                                         label={'Last Name'}
                                                         value={lastName}
                                                         maxCharLength={20}
                                                         inputType={'text'}
                                                         isRequired={true}
                                                         isClearable={false}
                                                         error={selfInformationError.lastName !== ''}
                                                         helperMessage={selfInformationError.lastName}
                                                         disableHelperMessageHeight={selfInformationError.lastName === ''}
                                                         onChangeValue={(e) => {
                                                             handleChange('lastName', e, 'selfInformation')
                                                         }}
                                       />
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
                   <div className='row m-0 pb-3 mb-1'>
                       <div id={'first_block'}
                            className='col-3 ps-0'>
                           <div id='selfInformation-dateOfBirth-id'>
                               <DLDatePicker id={'date-of-birth-date-picker'}
                                             label={'Date of Birth'}
                                             value={dateOfBirth}
                                             isRequired={true}
                                             isClearable={false}
                                             maximumDate={new Date()}
                                             minimumDate={'1900-12-30'}
                                             error={selfInformationError.dateOfBirth !== ''}
                                             helperMessage={selfInformationError.dateOfBirth}
                                             disableHelperMessageHeight={selfInformationError.dateOfBirth === ''}
                                             minWidth={'100%'}
                                             onChangeValue={(e) => {
                                                 handleChange('dateOfBirth', e, 'selfInformation')
                                             }}/>
                           </div>

                       </div>
                       <div id='second_block'
                            className='col-3'>
                           <div id='selfInformation-birthPlace-id'>
                               <DLInputTextField id={'birth-place'}
                                                 label={'Birthplace'}
                                                 value={birthPlace}
                                                 maxCharLength={30}
                                                 inputType={'text'}
                                                 error={selfInformationError.birthPlace !== ''}
                                                 helperMessage={selfInformationError.birthPlace}
                                                 disableHelperMessageHeight={selfInformationError.birthPlace === ''}
                                                 isRequired={true}
                                                 isClearable={false}
                                                 marginBottom={'none'}
                                                 onChangeValue={(e) => {
                                                     handleChange('birthPlace', e, 'selfInformation')
                                                 }}
                               />
                           </div>
                       </div>
                       <div id={'third_block'} className='col-6'>
                           <div>
                               <div>
                                   <DLText
                                       id={'birth-place'}
                                       type={'normal'}
                                       text={'Marital Status'}
                                       fontColor={selfInformationError.maritalStatus === '' ? 'grayDark' : 'danger'}
                                       fontSize={'xs'}
                                   />
                               </div>
                               <div id='selfInformation-maritalStatus-id' className='ps-2 d-flex'>
                                   <DLRadioGroup
                                       id={'hours-radioBtn'}
                                       label={''}
                                       type={'inline'}
                                       radioType={'multiple'}
                                       options={dropdownValues.maritalStatusOptions}
                                       value={maritalStatus}
                                       disableHelperMessageHeight
                                       disableDescriptionMessageHeight
                                       marginBottom={'xs'}
                                       labelFontColor={'black'}
                                       helperMessageColor={'danger'}
                                       helperMessage={selfInformationError.maritalStatus}
                                       error={selfInformationError.maritalStatus !== ''}
                                       isRequired={true}
                                       onChangeValue={(e) => {
                                           handleChange('maritalStatus', e, 'selfInformation')
                                       }}
                                   />
                               </div>
                           </div>
                       </div>

                   </div>
               </div>
            </div>
        )
    };

    const renderFathersInformation = () => {
        const {fathersDetails, errors} = props;

        let fathersDetailsError = errors.fathersDetails;
        const {firstName, middleName, lastName} = fathersDetails;

        return (
            <div className='pt-2'>
                <div className='section-title'
                     id={'self-information'}>
                    Father's Information
                </div>
               <div className='panel'>
                   <div className='row m-0'>
                       <div id={'first_block'}
                            className={isTabUI() ? 'col-4 ps-0' : 'col-3 ps-0'}>
                           <div id='fathersDetails-firstName-id'>
                               <DLInputTextField id={'first-name-input-field'}
                                                 label={'First Name'}
                                                 value={firstName}
                                                 inputType={'text'}
                                                 maxCharLength={20}
                                                 isClearable={false}
                                                 isRequired={true}
                                                 marginBottom={'md'}
                                                 disableHelperMessageHeight={true}
                                                 error={fathersDetailsError.firstName !== ''}
                                                 helperMessage={fathersDetailsError.firstName}
                                                 onChangeValue={(e) => {
                                                     handleChange('firstName', e, 'fathersDetails')
                                                 }}
                               />
                           </div>
                       </div>
                       <div id={'second_block'}
                            className={isTabUI() ? 'col-4' : 'col-3'}>
                           <div id='fathersDetails-middleName-id'>
                               <DLInputTextField id={'f-middle-name-input-field'}
                                                 label={'Middle Name (Optional)'}
                                                 value={middleName}
                                                 isRequired={true}
                                                 inputType={'text'}
                                                 maxCharLength={20}
                                                 error={fathersDetailsError.middleName !== ''}
                                                 helperMessage={fathersDetailsError.middleName}
                                                 isClearable={false}
                                                 disableHelperMessageHeight={true}
                                                 onChangeValue={(e) => {
                                                     handleChange('middleName', e, 'fathersDetails')
                                                 }}
                               />
                           </div>
                       </div>
                       <div id={'third_block'}
                            className={isTabUI() ? 'col-4' : 'col-3'}>
                           <div id='fathersDetails-lastName-id'>
                               <DLInputTextField id={'f-last-name-input-field'}
                                                 label={'Last Name'}
                                                 value={lastName}
                                                 inputType={'text'}
                                                 maxCharLength={20}
                                                 isRequired={true}
                                                 error={fathersDetailsError.lastName !== ''}
                                                 helperMessage={fathersDetailsError.lastName}
                                                 isClearable={false}
                                                 disableHelperMessageHeight={true}
                                                 onChangeValue={(e) => {
                                                     handleChange('lastName', e, 'fathersDetails')
                                                 }}
                               />
                           </div>
                       </div>
                   </div>
               </div>
            </div>
        )
    };

    const renderMothersInformation = () => {
        const {mothersDetails, errors} = props;
        let mothersDetailsError = errors.mothersDetails;
        const {firstName, middleName, lastName} = mothersDetails;
        return (
            <div className='mt-2'>
                <div className='section-title'
                     id={'self-information'}>
                    Mother's Information
                </div>
                <div className='panel'>
                    <div className='row m-0'>
                        <div id={'first_block'}
                             className={isTabUI() ? 'col-4 ps-0' : 'col-3 ps-0'}>
                            <div id='mothersDetails-firstName-id'>
                                <DLInputTextField id={'m-first-name-input-field'}
                                                  label={'First Name'}
                                                  value={firstName}
                                                  inputType={'text'}
                                                  maxCharLength={20}
                                                  isClearable={false}
                                                  isRequired={true}
                                                  error={mothersDetailsError.firstName !== ''}
                                                  helperMessage={mothersDetailsError.firstName}
                                                  disableHelperMessageHeight={true}
                                                  marginBottom={'md'}
                                                  onChangeValue={(e) => {
                                                      handleChange('firstName', e, 'mothersDetails')
                                                  }}
                                />
                            </div>
                        </div>
                        <div id={'second_block'}
                             className={isTabUI() ? 'col-4' : 'col-3'}>
                            <div id='mothersDetails-middleName-id'>
                                <DLInputTextField id={'m-middle-name-input-field'}
                                                  label={'Middle Name (Optional)'}
                                                  value={middleName}
                                                  isRequired={true}
                                                  inputType={'text'}
                                                  maxCharLength={20}
                                                  isClearable={false}
                                                  error={mothersDetailsError.middleName !== ''}
                                                  helperMessage={mothersDetailsError.middleName}
                                                  disableHelperMessageHeight={true}
                                                  marginBottom={'md'}
                                                  onChangeValue={(e) => {
                                                      handleChange('middleName', e, 'mothersDetails')
                                                  }}
                                />
                            </div>
                        </div>
                        <div id={'third_block'}
                             className={isTabUI() ? 'col-4' : 'col-3'}>
                            <div id='mothersDetails-lastName-id'>
                                <DLInputTextField id={'m-last-name-input-field'}
                                                  label={'Last Name'}
                                                  value={lastName}
                                                  inputType={'text'}
                                                  maxCharLength={20}
                                                  isClearable={false}
                                                  isRequired={true}
                                                  error={mothersDetailsError.lastName !== ''}
                                                  helperMessage={mothersDetailsError.lastName}
                                                  disableHelperMessageHeight={true}
                                                  marginBottom={'md'}
                                                  onChangeValue={(e) => {
                                                      handleChange('lastName', e, 'mothersDetails')
                                                  }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderAddressDetails = () => {

        const {address, errors, dropdownValues} = props;
        let addressErrors = errors.address;
        const {addressLine1, addressLine2, addressLine3, city, zipCode, stateCode, district} = address;

        return (
            <div className='mt-2'>
                <div className='section-title'
                     id={'self-information'}>
                    Address
                </div>
                <div className='panel'>
                    <div className='row m-0'>
                        <div id={'first_block'}
                             className={isTabUI() ? 'col-9 ps-0' : 'col-6 ps-0'}>
                            <div id='address-addressLine1-id'>
                                <DLInputTextField id={'address-input-field'}
                                                  label={'Address Line 1'}
                                                  value={addressLine1}
                                                  inputType={'text'}
                                                  rows={1}
                                                  maxCharLength={50}
                                                  isMulti={true}
                                                  isRequired={true}
                                                  isClearable={false}
                                                  disableHelperMessageHeight={true}
                                                  error={addressErrors.addressLine1 !== ''}
                                                  helperMessage={addressErrors.addressLine1}
                                                  marginBottom={'sm'}
                                                  onChangeValue={(e) => {
                                                      handleChange('addressLine1', e, 'address')
                                                  }}
                                />
                            </div>
                            <div id='address-addressLine2-id'>
                                <DLInputTextField id={'address-input-field'}
                                                  label={'Address Line 2 (Optional)'}
                                                  value={addressLine2}
                                                  inputType={'text'}
                                                  maxCharLength={50}
                                                  isClearable={false}
                                                  isMulti={true}
                                                  rows={1}
                                                  error={addressErrors.addressLine2 !== ''}
                                                  helperMessage={addressErrors.addressLine2}
                                                  disableHelperMessageHeight={true}
                                                  marginBottom={'sm'}
                                                  onChangeValue={(e) => {
                                                      handleChange('addressLine2', e, 'address')
                                                  }}
                                />
                            </div>
                            <div id='address-addressLine3-id'>
                                <DLInputTextField id={'address-input-field'}
                                                  label={'Address Line 3 (Optional)'}
                                                  value={addressLine3}
                                                  inputType={'text'}
                                                  maxCharLength={50}
                                                  isClearable={false}
                                                  isMulti={true}
                                                  rows={1}
                                                  error={addressErrors.addressLine3 !== ''}
                                                  helperMessage={addressErrors.addressLine3}
                                                  disableHelperMessageHeight={true}
                                                  marginBottom={'sm'}
                                                  onChangeValue={(e) => {
                                                      handleChange('addressLine3', e, 'address')
                                                  }}
                                />
                            </div>
                            <div className={'row'}>
                                <div className={'col-6'}>
                                    <div id='address-city-id'>
                                        <DLInputTextField id={'city-input-field'}
                                                          label={'City'}
                                                          value={city}
                                                          inputType={'text'}
                                                          maxCharLength={30}
                                                          isClearable={false}
                                                          isRequired={true}
                                                          error={addressErrors.city !== ''}
                                                          helperMessage={addressErrors.city}
                                                          disableHelperMessageHeight={true}
                                                          marginBottom={'sm'}
                                                          onChangeValue={(e) => {
                                                              handleChange('city', e, 'address')
                                                          }}
                                        />
                                    </div>
                                </div>
                                <div className={'col-6'}>
                                    <div id='address-zipCode-id'>
                                        <DLInputTextField id={'pin-code-input-field'}
                                                          label={'Pin Code'}
                                                          value={zipCode}
                                                          inputType={'number'}
                                                          maxCharLength={6}
                                                          isClearable={false}
                                                          isRequired={true}
                                                          error={addressErrors.zipCode !== ''}
                                                          helperMessage={addressErrors.zipCode}
                                                          disableHelperMessageHeight={true}
                                                          marginBottom={'sm'}
                                                          onChangeValue={(e) => {
                                                              handleChange('zipCode', e, 'address')
                                                          }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={'col-6'}>
                                    <div id='address-district-id'>
                                        <DLInputTextField id={'district-input-field'}
                                                          label={'District'}
                                                          value={district}
                                                          inputType={'text'}
                                                          maxCharLength={30}
                                                          isClearable={false}
                                                          isRequired={true}
                                                          error={addressErrors.district !== ''}
                                                          helperMessage={addressErrors.district}
                                                          disableHelperMessageHeight={true}
                                                          marginBottom={'sm'}
                                                          onChangeValue={(e) => {
                                                              handleChange('district', e, 'address')
                                                          }}
                                        />
                                    </div>
                                </div>
                                <div className={'col-6'}>
                                    <div id='address-stateCode-id' className={'mb-1'}>
                                        <DLSearchAutoComplete id={'state-dropDown'}
                                                              value={stateCode}
                                                              placeholder={'Select'}
                                                              label={'State'}
                                                              onSelect={(e) => handleChange('stateCode', e, 'address')}
                                                              isRequired={true}
                                                              isClearable={false}
                                                              isSearchable={true}
                                                              error={addressErrors.stateCode !== ''}
                                                              helperMessage={addressErrors.stateCode}
                                                              itemNotFoundText={'No options found'}
                                                              disableHelperMessageHeight={addressErrors.stateCode === ''}
                                                              marginBottom={'md'}
                                                              isMulti={false}
                                                              defaultOptions={dropdownValues?.states}
                                                              noOptionsMessage={'No options found'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderContactDetails = () => {

        const {contact, errors, dropdownValues} = props;

        const {
            primaryPhoneNumberRelation, secondaryPhoneNumber, secondaryPhoneNumberRelation, officePhone, residentialPhone,
            primaryEmail, primaryPhoneNumber, primaryEmailRelation, secondaryEmail, secondaryEmailRelation
        } = contact;
        let contactErrors = errors.contact;

        return (
            <div className='mt-2'>
                <div className='section-title'
                     id={'self-information'}>
                    Contact
                </div>

                <div className='panel'>
                    <div className='row m-0'>
                        <div id={'first_block'}
                             className={isTabUI() ? 'col-9' : 'col-6'}>
                            <div className={'row'}>
                                <div className={'col-6 ps-0'}>
                                    <div id='contact-primaryEmail-id'>
                                        <DLInputTextField id={'primary-email-input-field'}
                                                          label={'Primary Email'}
                                                          value={primaryEmail}
                                                          inputType={'text'}
                                                          maxCharLength={60}
                                                          isClearable={false}
                                                          isRequired={true}
                                                          error={contactErrors.primaryEmail !== ''}
                                                          helperMessage={contactErrors.primaryEmail}
                                                          disableHelperMessageHeight={true}
                                                          marginBottom={'sm'}
                                                          onChangeValue={(e) => {
                                                              handleChange('primaryEmail', e, 'contact')
                                                          }}
                                        />
                                    </div>
                                </div>
                                <div className={'col-6'}>
                                    <div id='contact-primaryEmailRelation-id' className={'mb-1'}>
                                        <DLSearchAutoComplete id={'primary-email-dropDown'}
                                                              value={primaryEmailRelation}
                                                              label={'Email Belongs To'}
                                                              onSelect={(e) => handleChange('primaryEmailRelation', e, 'contact')}
                                                              isClearable={false}
                                                              isRequired={true}
                                                              isSearchable={false}
                                                              itemNotFoundText={'No options found'}
                                                              marginBottom={'none'}
                                                              isMulti={false}
                                                              error={contactErrors.primaryEmailRelation !== ''}
                                                              helperMessage={contactErrors.primaryEmailRelation}
                                                              defaultOptions={dropdownValues?.emailPhoneBelongsTo}
                                                              noOptionsMessage={'No options found'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={'col-6 ps-0'}>
                                    <div id='contact-secondaryEmail-id'>
                                        <DLInputTextField id={'secondary-email-input-field'}
                                                          label={'Secondary Email (Optional)'}
                                                          value={secondaryEmail}
                                                          isRequired={true}
                                                          inputType={'text'}
                                                          maxCharLength={60}
                                                          isClearable={false}
                                                          error={contactErrors.secondaryEmail !== ''}
                                                          helperMessage={contactErrors.secondaryEmail}
                                                          disableHelperMessageHeight={true}
                                                          marginBottom={'sm'}
                                                          onChangeValue={(e) => {
                                                              handleChange('secondaryEmail', e, 'contact')
                                                          }}
                                        />
                                    </div>
                                </div>
                                <div className={'col-6'}>
                                    <div id='contact-secondaryEmailRelation-id' className={'mb-1'}>
                                        <DLSearchAutoComplete id={'secondary-email-dropDown'}
                                                              value={secondaryEmailRelation}
                                                              isRequired={true}
                                                              label={'Email Belongs To (Optional)'}
                                                              onSelect={(e) => handleChange('secondaryEmailRelation', e, 'contact')}
                                                              isClearable={false}
                                                              isSearchable={false}
                                                              itemNotFoundText={'No options found'}
                                                              marginBottom={'none'}
                                                              isMulti={false}
                                                              error={contactErrors.secondaryEmailRelation !== ''}
                                                              helperMessage={contactErrors.secondaryEmailRelation}
                                                              defaultOptions={dropdownValues?.emailPhoneBelongsTo}
                                                              noOptionsMessage={'No options found'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={'col-6 ps-0'}>
                                    <div id='contact-primaryPhoneNumber-id'>
                                        <DLInputTextField id={'primary-mobile-input-field'}
                                                          label={'Primary Mobile'}
                                                          value={primaryPhoneNumber}
                                                          isClearable={false}
                                                          isRequired={true}
                                                          inputType={'number'}
                                                          maxCharLength={10}
                                                          disableHelperMessageHeight={true}
                                                          marginBottom={'sm'}
                                                          error={contactErrors.primaryPhoneNumber !== ''}
                                                          helperMessage={contactErrors.primaryPhoneNumber}
                                                          onChangeValue={(e) => {
                                                              handleChange('primaryPhoneNumber', e, 'contact')
                                                          }}
                                        />
                                    </div>
                                </div>
                                <div className={'col-6'}>
                                    <div id='contact-primaryPhoneNumberRelation-id' className={'mb-1'}>
                                        <DLSearchAutoComplete id={'primary-mobile-dropDown'}
                                                              value={primaryPhoneNumberRelation}
                                                              label={'Mobile Belongs To'}
                                                              onSelect={(e) => handleChange('primaryPhoneNumberRelation', e, 'contact')}
                                                              isClearable={false}
                                                              isSearchable={false}
                                                              isRequired={true}
                                                              error={contactErrors.primaryPhoneNumberRelation !== ''}
                                                              helperMessage={contactErrors.primaryPhoneNumberRelation}
                                                              itemNotFoundText={'No options found'}
                                                              marginBottom={'none'}
                                                              isMulti={false}
                                                              defaultOptions={dropdownValues?.emailPhoneBelongsTo}
                                                              noOptionsMessage={'No options found'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={'col-6 ps-0'}>
                                    <div id='contact-secondaryPhoneNumber-id'>
                                        <DLInputTextField id={'secondary-mobile-input-field'}
                                                          label={'Secondary Mobile (Optional)'}
                                                          value={secondaryPhoneNumber}
                                                          inputType={'number'}
                                                          isRequired={true}
                                                          maxCharLength={10}
                                                          isClearable={false}
                                                          error={contactErrors.secondaryPhoneNumber !== ''}
                                                          helperMessage={contactErrors.secondaryPhoneNumber}
                                                          disableHelperMessageHeight={true}
                                                          marginBottom={'md'}
                                                          onChangeValue={(e) => {
                                                              handleChange('secondaryPhoneNumber', e, 'contact')
                                                          }}
                                        />
                                    </div>
                                </div>
                                <div className={'col-6'}>
                                    <div id='contact-secondaryPhoneNumberRelation-id' className={'mb-1'}>
                                        <DLSearchAutoComplete id={'secondary-mobile-dropDown'}
                                                              value={secondaryPhoneNumberRelation}
                                                              label={'Mobile Belongs To (Optional)'}
                                                              onSelect={(e) => handleChange('secondaryPhoneNumberRelation', e, 'contact')}
                                                              isClearable={false}
                                                              isRequired={true}
                                                              isSearchable={false}
                                                              itemNotFoundText={'No options found'}
                                                              marginBottom={'none'}
                                                              isMulti={false}
                                                              error={contactErrors.secondaryPhoneNumberRelation !== ''}
                                                              helperMessage={contactErrors.secondaryPhoneNumberRelation}
                                                              defaultOptions={dropdownValues?.emailPhoneBelongsTo}
                                                              noOptionsMessage={'No options found'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={'col-6 ps-0'}>
                                    <div id='contact-officePhone-id'>
                                        <DLInputTextField id={'telephone-off-input-field'}
                                                          label={'Tel(Off) (Optional)'}
                                                          value={officePhone}
                                                          isRequired={true}
                                                          inputType={'number'}
                                                          maxCharLength={20}
                                                          error={contactErrors.officePhone !== ''}
                                                          helperMessage={contactErrors.officePhone}
                                                          isClearable={false}
                                                          disableHelperMessageHeight={true}
                                                          marginBottom={'sm'}
                                                          onChangeValue={(e) => {
                                                              handleChange('officePhone', e, 'contact')
                                                          }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={'col-6 ps-0'}>
                                    <div id='contact-residentialPhone-id'>
                                        <DLInputTextField id={'telephone-res-input-field'}
                                                          label={'Tel(Res) (Optional)'}
                                                          value={residentialPhone}
                                                          isRequired={true}
                                                          inputType={'number'}
                                                          maxCharLength={20}
                                                          error={contactErrors.residentialPhone !== ''}
                                                          helperMessage={contactErrors.residentialPhone}
                                                          isClearable={false}
                                                          disableHelperMessageHeight={true}
                                                          marginBottom={'md'}
                                                          onChangeValue={(e) => {
                                                              handleChange('residentialPhone', e, 'contact')
                                                          }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderProfessionalDetails = () => {

        const {errors, profession, dropdownValues} = props;
        let professionErrors = errors.profession;

        return (
            <div className='mt-2'>
                <div className='section-title'
                     id={'Profession'}>
                    Profession
                </div>

                <div className='panel'>
                    <div className='row m-0'>
                        <div id={'first_block'}
                             className='col-12'>
                            <div className={'row pb-1'}>
                                <div className={isTabUI() ? 'col-4 ps-0' : 'col-3 ps-0'}>
                                    <div id='profession-occupationCode-id'>
                                        <DLSearchAutoComplete id={'occupation-input-field'}
                                                              value={profession?.occupationCode}
                                                              label={'Occupation'}
                                                              onSelect={(e) => handleChange('occupationCode', e, 'profession')}
                                                              isClearable={false}
                                                              isSearchable={false}
                                                              itemNotFoundText={'No options found'}
                                                              marginBottom={'md'}
                                                              isMulti={false}
                                                              isRequired={true}
                                                              error={professionErrors.occupationCode !== ''}
                                                              disableHelperMessageHeight={professionErrors.occupationCode === ''}
                                                              helperMessage={professionErrors.occupationCode}
                                                              defaultOptions={dropdownValues?.occupations}
                                                              noOptionsMessage={'No options found'}
                                        />
                                    </div>
                                </div>
                                <div className={isTabUI() ? 'col-4' : 'col-3'}>
                                    <div id='profession-companyName-id'>
                                        <DLInputTextField id={'company-name-input-field'}
                                                          label={'Company name (Optional)'}
                                                          value={profession?.companyName}
                                                          isClearable={false}
                                                          maxCharLength={30}
                                                          isRequired={true}
                                                          error={professionErrors.companyName !== ''}
                                                          helperMessage={professionErrors.companyName}
                                                          disableHelperMessageHeight={true}
                                                          marginBottom={'sm'}
                                                          onChangeValue={(e) => {
                                                              handleChange('companyName', e, 'profession')
                                                          }}
                                        />
                                    </div>
                                </div>
                                <div className={isTabUI() ? 'col-4' : 'col-3'}>
                                    <div id='profession-industryType-id' className={'mb-1'}>
                                        <DLSearchAutoComplete id={'industry-type-dropDown'}
                                                              value={profession.industryType}
                                                              label={'Industry type (Optional)'}
                                                              onSelect={(e) => handleChange('industryType', e, 'profession')}
                                                              isClearable={false}
                                                              isRequired={true}
                                                              isSearchable={false}
                                                              itemNotFoundText={'No options found'}
                                                              marginBottom={'none'}
                                                              isMulti={false}
                                                              error={professionErrors.industryType !== ''}
                                                              disableHelperMessageHeight={professionErrors.industryType === ''}
                                                              helperMessage={professionErrors.industryType}
                                                              defaultOptions={dropdownValues?.industries}
                                                              noOptionsMessage={'No options found'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row m-0'>
                        <div id={'first_block'}
                             className='col-12'>
                            <div className={'row pb-1'}>
                                <div className={isTabUI() ? 'col-4 ps-0' : 'col-3 ps-0'}>
                                    <div id='profession-annualIncomeCode-id'>
                                        <DLDropDown id={'family-income-input-field'}
                                                    label={'Annual Income'}
                                                    labelPlacement={'top'}
                                                    noOptionsMessage={'No options found'}
                                                    placeholder={''}
                                                    options={dropdownValues?.annualIncomes}
                                                    minWidth={'100%'}
                                                    marginBottom={'md'}
                                                    onSelect={(e) => {
                                                        handleChange('annualIncomeCode', e, 'profession')
                                                    }}
                                                    value={profession?.annualIncomeCode}
                                                    disableHelperMessageHeight={errors.profession.annualIncomeCode === ''}
                                                    error={errors?.profession?.annualIncomeCode !== ''}
                                                    helperMessage={errors?.profession?.annualIncomeCode}
                                                    isRequired={true}
                                                    isClearable={false}
                                        />
                                    </div>
                                </div>
                                <div className={isTabUI() ? 'col-4' : 'col-3'}>
                                    <div id='profession-liabilities-id'>
                                        <DLInputTextField id={'liablities-input-field'}
                                                          label={'Liablities (Optional)'}
                                                          maxCharLength={50}
                                                          isClearable={false}
                                                          disableHelperMessageHeight={true}
                                                          marginBottom={'md'}
                                                          onChangeValue={(e) => {
                                                              handleChange('liabilities', e, 'profession')
                                                          }}
                                                          value={profession?.liabilities}
                                                          error={errors?.profession.liabilities !== ''}
                                                          helperMessage={errors?.profession.liabilities}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
    //
    const renderPoliticallyExposedDetails = () => {
        const {politicallyExposed, dropdownValues} = props;
        return (
            <div className='mt-2 pb-3'>
                <div className='section-title'
                     id={'Profession'}>
                    Politically Exposed?
                </div>
                <div id='politicallyExposed-PEPCode-id'
                     className={'mb-3 mx-1 panel'}>
                    <DLRadioGroup
                        options={dropdownValues.politicallyExposedValues}
                        id={'hours-radioBtnq'}
                        value={politicallyExposed.PEPCode}
                        hidden={false}
                        label={''}
                        type={'block'}
                        labelFontColor={'black'}
                        disableHelperMessageHeight={true}
                        disableDescriptionMessageHeight={true}
                        radioType={'multiple'}
                        onChangeValue={(e) => {
                            handleChange('PEPCode', e, 'politicallyExposed')
                        }}
                    />
                </div>
            </div>
        )
    };

    const renderMobileSelfInformation = () => {
        const {selfInformation, errors, birthPlace, dropdownValues} = props;

        const {title, firstName, middleName, lastName, dateOfBirth, maritalStatus} = selfInformation;
        let selfInformationError = errors.selfInformation;

        return (
            <div id={'self-info-block'} className='block-padding'>
                <DLText
                    id={'mobile-self-information'}
                    type={'normal'}
                    text={'Self Information'}
                    fontSize={'sm'}
                    fontWeight={'semi-bold'}
                    marginBottom={'md'}
                    fontColor={'black'}
                />


                <div id='selfInformation-title-id'>
                    <DLSearchAutoComplete id={'title-id'}
                                          value={title}
                                          label={'Title (Optional)'}
                                          placeholder={''}
                                          onSelect={(e) => handleChange('title', e, 'selfInformation')}
                                          isClearable={false}
                                          isRequired={true}
                                          isSearchable={false}
                                          itemNotFoundText={'No options found'}
                                          error={selfInformationError.title !== ''}
                                          helperMessage={selfInformationError.title}
                                          disableHelperMessageHeight={true}
                                          marginBottom={'sm'}
                                          isMulti={false}
                                          defaultOptions={getTitleValues()}
                                          noOptionsMessage={'No options found'}
                    />
                </div>

                <div id='selfInformation-firstName-id'>
                    <DLInputTextField id={'firstName-id'}
                                      label={'First Name'}
                                      value={firstName}
                                      maxCharLength={20}
                                      inputType={'text'}
                                      labelFontColor={'grayDark'}
                                      isClearable={false}
                                      isRequired={true}
                                      disableHelperMessageHeight={true}
                                      error={selfInformationError.firstName !== ''}
                                      helperMessage={selfInformationError.firstName}
                                      marginBottom={'sm'}
                                      onChangeValue={(e) => {
                                          handleChange('firstName', e, 'selfInformation')
                                      }}
                    />
                </div>

                <div id='selfInformation-middleName-id'>
                    <DLInputTextField id={'mobile-middle-name'}
                                      label={'Middle Name (Optional)'}
                                      value={middleName}
                                      maxCharLength={20}
                                      isRequired={true}
                                      inputType={'text'}
                                      labelFontColor={'grayDark'}
                                      isClearable={false}
                                      disableHelperMessageHeight={true}
                                      error={selfInformationError.middleName !== ''}
                                      helperMessage={selfInformationError.middleName}
                                      marginBottom={'sm'}
                                      onChangeValue={(e) => {
                                          handleChange('middleName', e, 'selfInformation')
                                      }}
                    />
                </div>

                <div id='selfInformation-lastName-id'>
                    <DLInputTextField id={'mobile-last-name'}
                                      label={'Last Name'}
                                      value={lastName}
                                      maxCharLength={20}
                                      inputType={'text'}
                                      labelFontColor={'grayDark'}
                                      isClearable={false}
                                      isRequired={true}
                                      disableHelperMessageHeight={true}
                                      error={selfInformationError.lastName !== ''}
                                      helperMessage={selfInformationError.lastName}
                                      marginBottom={'sm'}
                                      onChangeValue={(e) => {
                                          handleChange('lastName', e, 'selfInformation')
                                      }}
                    />
                </div>


                <div style={{marginBottom: '16px'}}>
                    <div id='selfInformation-dateOfBirth-id'>
                        <DLDatePicker id={'mobile-date-of-birth'}
                                      label={'Date of Birth'}
                                      value={dateOfBirth}
                                      maximumDate={new Date()}
                                      isRequired={true}
                                      disableHelperMessageHeight={true}
                                      error={selfInformationError.dateOfBirth !== ''}
                                      helperMessage={selfInformationError.dateOfBirth}
                                      minimumDate={'1900-12-30'}
                                      minWidth={'100%'}
                                      onChangeValue={(e) => {
                                          handleChange('dateOfBirth', e, 'selfInformation')
                                      }}/>
                    </div>
                </div>
                <div id='selfInformation-birthPlace-id'>
                    <DLInputTextField id={'mobile-birth-place'}
                                      label={'Birthplace'}
                                      value={birthPlace}
                                      maxCharLength={30}
                                      inputType={'text'}
                                      labelFontColor={'grayDark'}
                                      isClearable={false}
                                      isRequired={true}
                                      disableHelperMessageHeight={true}
                                      error={selfInformationError.birthPlace !== ''}
                                      helperMessage={selfInformationError.birthPlace}
                                      marginBottom={'sm'}
                                      onChangeValue={(e) => {
                                          handleChange('birthPlace', e, 'selfInformation')
                                      }}

                    />
                </div>
                <div>
                    <div>
                        <DLText
                            id={'birth-place'}
                            type={'normal'}
                            text={'Marital Status'}
                            fontColor={(isEmpty(selfInformationError.maritalStatus)) ? 'grayDark' : 'danger'}
                            fontSize={'xs'}
                        />
                    </div>
                    <div id='selfInformation-maritalStatus-id'>
                        <DLRadioGroup
                            options={dropdownValues.maritalStatusOptions}
                            id={'marital-status-mobile'}
                            value={maritalStatus}
                            hidden={false}
                            marginBottom={'sm'}
                            label={''}
                            removePadding={true}
                            type={'block'}
                            helperMessageColor={'danger'}
                            helperMessage={selfInformationError.maritalStatus}
                            disableHelperMessageHeight={selfInformationError.maritalStatus !== ''}
                            labelFontColor={'black'}
                            disableDescriptionMessageHeight={true}
                            radioType={'multiple'}
                            onChangeValue={(e) => {
                                handleChange('maritalStatus', e, 'selfInformation')
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderMobileFathersInformation = () => {
        const {fathersDetails, errors} = props;

        let fathersDetailsError = errors.fathersDetails;
        const {firstName, middleName, lastName} = fathersDetails;

        return (
            <div className='block-padding'>
                <DLText
                    id={'mobile-fathers-information'}
                    type={'normal'}
                    text={'Father\'s Information'}
                    fontSize={'sm'}
                    fontWeight={'semi-bold'}
                    marginBottom={'md'}
                    fontColor={'black'}
                />
                <div id='fathersDetails-firstName-id'>
                    <DLInputTextField id={'mobile-fathers-first-name'}
                                      label={'First Name'}
                                      value={firstName}
                                      inputType={'text'}
                                      maxCharLength={20}
                                      isClearable={false}
                                      isRequired={true}
                                      error={fathersDetailsError.firstName !== ''}
                                      helperMessage={fathersDetailsError.firstName}
                                      disableHelperMessageHeight={true}
                                      marginBottom={'sm'}
                                      onChangeValue={(e) => {
                                          handleChange('firstName', e, 'fathersDetails')
                                      }}
                    />
                </div>
                <div id='fathersDetails-middleName-id'>
                    <DLInputTextField id={'mobile-fathers-middle-name'}
                                      label={'Middle Name (Optional)'}
                                      value={middleName}
                                      inputType={'text'}
                                      maxCharLength={20}
                                      isRequired={true}
                                      isClearable={false}
                                      error={fathersDetailsError.middleName !== ''}
                                      helperMessage={fathersDetailsError.middleName}
                                      disableHelperMessageHeight={true}
                                      marginBottom={'sm'}
                                      onChangeValue={(e) => {
                                          handleChange('middleName', e, 'fathersDetails')
                                      }}
                    />
                </div>
                <div id='fathersDetails-lastName-id'>
                    <DLInputTextField id={'mobile-father-last-name'}
                                      label={'Last Name'}
                                      value={lastName}
                                      inputType={'text'}
                                      maxCharLength={20}
                                      isClearable={false}
                                      isRequired={true}
                                      error={fathersDetailsError.lastName !== ''}
                                      helperMessage={fathersDetailsError.lastName}
                                      disableHelperMessageHeight={true}
                                      marginBottom={'none'}
                                      onChangeValue={(e) => {
                                          handleChange('lastName', e, 'fathersDetails')
                                      }}
                    />
                </div>
            </div>
        )
    };

    const renderMobileMothersInformation = () => {
        const {mothersDetails, errors} = props;
        let mothersDetailsError = errors.mothersDetails;
        const {firstName, middleName, lastName} = mothersDetails;
        return (
            <div className='block-padding'>
                <DLText
                    id={'mobile-mothers-information'}
                    type={'normal'}
                    text={'Mother\'s Information'}
                    fontSize={'sm'}
                    fontWeight={'semi-bold'}
                    marginBottom={'md'}
                    fontColor={'black'}
                />
                <div id='mothersDetails-firstName-id'>
                    <DLInputTextField id={'mobile-mothers-first-name'}
                                      label={'First Name'}
                                      value={firstName}
                                      inputType={'text'}
                                      maxCharLength={20}
                                      isClearable={false}
                                      isRequired={true}
                                      error={mothersDetailsError.firstName !== ''}
                                      helperMessage={mothersDetailsError.firstName}
                                      disableHelperMessageHeight={true}
                                      marginBottom={'sm'}
                                      onChangeValue={(e) => {
                                          handleChange('firstName', e, 'mothersDetails')
                                      }}
                    />
                </div>
                <div id='mothersDetails-middleName-id'>
                    <DLInputTextField id={'mobile-mothers-middle-name'}
                                      label={'Middle Name (Optional)'}
                                      value={middleName}
                                      inputType={'text'}
                                      maxCharLength={20}
                                      isRequired={true}
                                      isClearable={false}
                                      error={mothersDetailsError.middleName !== ''}
                                      helperMessage={mothersDetailsError.middleName}
                                      disableHelperMessageHeight={true}
                                      marginBottom={'sm'}
                                      onChangeValue={(e) => {
                                          handleChange('middleName', e, 'mothersDetails')
                                      }}
                    />
                </div>
                <div id='mothersDetails-lastName-id'>
                    <DLInputTextField id={'mobile-mothers-last-name'}
                                      label={'Last Name'}
                                      value={lastName}
                                      inputType={'text'}
                                      maxCharLength={20}
                                      isClearable={false}
                                      isRequired={true}
                                      error={mothersDetailsError.lastName !== ''}
                                      helperMessage={mothersDetailsError.lastName}
                                      disableHelperMessageHeight={true}
                                      marginBottom={'sm'}
                                      onChangeValue={(e) => {
                                          handleChange('lastName', e, 'mothersDetails')
                                      }}
                    />
                </div>
            </div>
        )
    };

    const renderMobileAddressDetails = () => {
        const {address, errors, dropdownValues} = props;
        let addressErrors = errors.address;
        const {addressLine1, addressLine2, addressLine3, city, zipCode, stateCode, district} = address;

        return (
            <div className='block-padding'>
                <DLText
                    id={'revenue'}
                    type={'normal'}
                    text={'Address'}
                    fontSize={'sm'}
                    fontWeight={'semi-bold'}
                    marginBottom={'md'}
                    fontColor={'black'}/>
                <div className='mb-3'>
                    <div id='address-addressLine1-id'>
                        <DLInputTextField id={'address-line-1'}
                                          label={'Address Line 1'}
                                          value={addressLine1}
                                          inputType={'text'}
                                          maxCharLength={50}
                                          isClearable={false}
                                          isRequired={true}
                                          error={addressErrors.addressLine1 !== ''}
                                          helperMessage={addressErrors.addressLine1}
                                          disableHelperMessageHeight={true}
                                          marginBottom={'none'}
                                          onChangeValue={(e) => {
                                              handleChange('addressLine1', e, 'address')
                                          }}
                        />
                    </div>
                </div>
                <div id='address-addressLine2-id' className='mb-3'>
                    <DLInputTextField id={'address-line-2'}
                                      label={'Address Line 2 (Optional)'}
                                      value={addressLine2}
                                      isRequired={true}
                                      inputType={'text'}
                                      maxCharLength={50}
                                      isClearable={false}
                                      error={addressErrors.addressLine2 !== ''}
                                      helperMessage={addressErrors.addressLine2}
                                      disableHelperMessageHeight={true}
                                      marginBottom={'none'}
                                      onChangeValue={(e) => {
                                          handleChange('addressLine2', e, 'address')
                                      }}
                    />
                </div>
                <div id='address-addressLine3-id' className='mb-3'>
                    <DLInputTextField id={'address-line-3'}
                                      label={'Address Line 3 (Optional)'}
                                      value={addressLine3}
                                      inputType={'text'}
                                      maxCharLength={50}
                                      isRequired={true}
                                      isClearable={false}
                                      error={addressErrors.addressLine3 !== ''}
                                      helperMessage={addressErrors.addressLine3}
                                      disableHelperMessageHeight={true}
                                      marginBottom={'none'}
                                      onChangeValue={(e) => {
                                          handleChange('addressLine3', e, 'address')
                                      }}
                    />
                </div>
                <div id='address-city-id' className='mb-3'>
                    <DLInputTextField id={'first-name'}
                                      label={'City'}
                                      value={city}
                                      inputType={'text'}
                                      maxCharLength={30}
                                      isClearable={false}
                                      isRequired={true}
                                      error={addressErrors.city !== ''}
                                      helperMessage={addressErrors.city}
                                      disableHelperMessageHeight={true}
                                      marginBottom={'none'}
                                      onChangeValue={(e) => {
                                          handleChange('city', e, 'address')
                                      }}
                    />
                </div>
                <div id='address-zipCode-id' className='mb-3'>
                    <DLInputTextField id={'first-name'}
                                      label={'Pin Code'}
                                      value={zipCode}
                                      inputType={'number'}
                                      maxCharLength={6}
                                      isClearable={false}
                                      isRequired={true}
                                      error={addressErrors.zipCode !== ''}
                                      helperMessage={addressErrors.zipCode}
                                      disableHelperMessageHeight={true}
                                      marginBottom={'none'}
                                      onChangeValue={(e) => {
                                          handleChange('zipCode', e, 'address')
                                      }}
                    />
                </div>
                <div id='address-district-id' className='mb-3'>
                    <DLInputTextField id={'district-input-field'}
                                      label={'District'}
                                      value={district}
                                      inputType={'text'}
                                      maxCharLength={30}
                                      isClearable={false}
                                      isRequired={true}
                                      error={addressErrors.district !== ''}
                                      helperMessage={addressErrors.district}
                                      disableHelperMessageHeight={true}
                                      marginBottom={'sm'}
                                      onChangeValue={(e) => {
                                          handleChange('district', e, 'address')
                                      }}
                    />
                </div>
                <div className='mb-3'>
                    <div id='address-stateCode-id'>
                        <DLSearchAutoComplete id={'state-dropDown'}
                                              label={'State'}
                                              value={stateCode}
                                              placeholder={'Select'}
                                              onSelect={(e) => handleChange('stateCode', e, 'address')}
                                              isClearable={false}
                                              isSearchable={true}
                                              isRequired={true}
                                              error={addressErrors.stateCode !== ''}
                                              helperMessage={addressErrors.stateCode}
                                              itemNotFoundText={'No options found'}
                                              marginBottom={'none'}
                                              isMulti={false}
                                              defaultOptions={dropdownValues?.states}
                                              noOptionsMessage={'No options found'}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderMobileContactDetails = () => {
        const {contact, errors, dropdownValues} = props;
        let contactErrors = errors.contact;
        const {
            primaryEmail, primaryEmailRelation, secondaryEmail, secondaryEmailRelation, primaryPhoneNumber, primaryPhoneNumberRelation,
            secondaryPhoneNumber, secondaryPhoneNumberRelation, officePhone, residentialPhone
        } = contact;
        return (
            <div className='block-padding'>
                <DLText
                    id={'revenue'}
                    type={'normal'}
                    text={'Contact'}
                    fontSize={'sm'}
                    fontWeight={'semi-bold'}
                    marginBottom={'md'}
                    fontColor={'black'}/>
                <div id='contact-primaryEmail-id' className='mb-3'>
                    <DLInputTextField id={'primary-email'}
                                      label={'Primary Email'}
                                      value={primaryEmail}
                                      inputType={'text'}
                                      maxCharLength={60}
                                      isClearable={false}
                                      isRequired={true}
                                      error={contactErrors.primaryEmail !== ''}
                                      helperMessage={contactErrors.primaryEmail}
                                      disableHelperMessageHeight={true}
                                      marginBottom={'none'}
                                      onChangeValue={(e) => {
                                          handleChange('primaryEmail', e, 'contact')
                                      }}
                    />
                </div>
                <div id='contact-primaryEmailRelation-id' className='mb-3'>

                    <DLSearchAutoComplete id={'primary-email-dropDown'}
                                          value={primaryEmailRelation}
                                          label={'Email Belongs To'}
                                          placeholder={''}
                                          onSelect={(e) => handleChange('primaryEmailRelation', e, 'contact')}
                                          isClearable={false}
                                          isSearchable={false}
                                          isRequired={true}
                                          error={contactErrors.primaryEmailRelation !== ''}
                                          helperMessage={contactErrors.primaryEmailRelation}
                                          itemNotFoundText={'No options found'}
                                          marginBottom={'none'}
                                          isMulti={false}
                                          defaultOptions={dropdownValues?.emailPhoneBelongsTo}
                                          noOptionsMessage={'No options found'}
                    />
                </div>
                <div id='contact-secondaryEmail-id' className='mb-3'>
                    <DLInputTextField id={'first-name'}
                                      label={'Secondary Email (Optional)'}
                                      inputType={'text'}
                                      maxCharLength={60}
                                      value={secondaryEmail}
                                      isRequired={true}
                                      isClearable={false}
                                      disableHelperMessageHeight={true}
                                      error={contactErrors.secondaryEmail !== ''}
                                      helperMessage={contactErrors.secondaryEmail}
                                      marginBottom={'none'}
                                      onChangeValue={(e) => {
                                          handleChange('secondaryEmail', e, 'contact')
                                      }}
                    />
                </div>
                <div id='contact-secondaryEmailRelation-id' className='mb-3'>
                    <DLSearchAutoComplete id={'secondary-email-dropDown'}
                                          value={secondaryEmailRelation}
                                          label={'Email Belongs To (Optional)'}
                                          placeholder={''}
                                          isRequired={true}
                                          onSelect={(e) => handleChange('secondaryEmailRelation', e, 'contact')}
                                          isClearable={false}
                                          isSearchable={false}
                                          itemNotFoundText={'No options found'}
                                          marginBottom={'none'}
                                          isMulti={false}
                                          error={contactErrors.secondaryEmailRelation !== ''}
                                          helperMessage={contactErrors.secondaryEmailRelation}
                                          defaultOptions={dropdownValues?.emailPhoneBelongsTo}
                                          noOptionsMessage={'No options found'}
                    />
                </div>
                <div id='contact-primaryPhoneNumber-id' className='mb-3'>
                    <DLInputTextField id={'first-name'}
                                      label={'Primary Mobile'}
                                      value={primaryPhoneNumber}
                                      inputType={'number'}
                                      maxCharLength={10}
                                      isClearable={false}
                                      isRequired={true}
                                      error={contactErrors.primaryPhoneNumber !== ''}
                                      helperMessage={contactErrors.primaryPhoneNumber}
                                      disableHelperMessageHeight={true}
                                      marginBottom={'none'}
                                      onChangeValue={(e) => {
                                          handleChange('primaryPhoneNumber', e, 'contact')
                                      }}
                    />
                </div>
                <div id='contact-primaryPhoneNumberRelation-id' className='mb-3'>
                    <DLSearchAutoComplete id={'primary-mobile-dropDown'}
                                          value={primaryPhoneNumberRelation}
                                          label={'Mobile Belongs To'}
                                          placeholder={''}
                                          onSelect={(e) => handleChange('primaryPhoneNumberRelation', e, 'contact')}
                                          isClearable={false}
                                          isSearchable={false}
                                          isRequired={true}
                                          error={contactErrors.primaryPhoneNumberRelation !== ''}
                                          helperMessage={contactErrors.primaryPhoneNumberRelation}
                                          itemNotFoundText={'No options found'}
                                          marginBottom={'none'}
                                          isMulti={false}
                                          defaultOptions={dropdownValues?.emailPhoneBelongsTo}
                                          noOptionsMessage={'No options found'}
                    />
                </div>
                <div id='contact-secondaryPhoneNumber-id' className='mb-3'>
                    <DLInputTextField id={'secondary-mobile'}
                                      label={'Secondary Mobile (Optional)'}
                                      value={secondaryPhoneNumber}
                                      inputType={'number'}
                                      isRequired={true}
                                      maxCharLength={10}
                                      isClearable={false}
                                      error={contactErrors.secondaryPhoneNumber !== ''}
                                      helperMessage={contactErrors.secondaryPhoneNumber}
                                      disableHelperMessageHeight={true}
                                      marginBottom={'none'}
                                      onChangeValue={(e) => {
                                          handleChange('secondaryPhoneNumber', e, 'contact')
                                      }}
                    />
                </div>
                <div id='contact-secondaryPhoneNumberRelation-id' className='mb-3'>

                    <DLSearchAutoComplete id={'secondary-mobile-dropDown'}
                                          value={secondaryPhoneNumberRelation}
                                          placeholder={''}
                                          label={'Mobile Belongs To (Optional)'}
                                          onSelect={(e) => handleChange('secondaryPhoneNumberRelation', e, 'contact')}
                                          isClearable={false}
                                          isRequired={true}
                                          isSearchable={false}
                                          itemNotFoundText={'No options found'}
                                          marginBottom={'none'}
                                          isMulti={false}
                                          error={contactErrors.secondaryPhoneNumberRelation !== ''}
                                          helperMessage={contactErrors.secondaryPhoneNumberRelation}
                                          defaultOptions={dropdownValues?.emailPhoneBelongsTo}
                                          noOptionsMessage={'No options found'}
                    />
                </div>
                <div id='contact-officePhone-id' className='mb-3'>
                    <DLInputTextField id={'tel-off'}
                                      label={'Tel(Off) (Optional)'}
                                      value={officePhone}
                                      isRequired={true}
                                      inputType={'number'}
                                      maxCharLength={20}
                                      error={contactErrors.officePhone !== ''}
                                      helperMessage={contactErrors.officePhone}
                                      isClearable={false}
                                      disableHelperMessageHeight={true}
                                      marginBottom={'none'}
                                      onChangeValue={(e) => {
                                          handleChange('officePhone', e, 'contact')
                                      }}
                    />
                </div>
                <div id='contact-stateCode-id' className='mb-3'>
                    <DLInputTextField id={'tel-res'}
                                      label={'Tel(Res) (Optional)'}
                                      value={residentialPhone}
                                      isRequired={true}
                                      inputType={'number'}
                                      maxCharLength={20}
                                      error={contactErrors.residentialPhone !== ''}
                                      helperMessage={contactErrors.residentialPhone}
                                      isClearable={false}
                                      disableHelperMessageHeight={true}
                                      marginBottom={'none'}
                                      onChangeValue={(e) => {
                                          handleChange('residentialPhone', e, 'contact')
                                      }}
                    />
                </div>
            </div>
        )
    };

    const renderMobileProfessionalDetails = () => {

        const {profession, errors, dropdownValues} = props;
        let professionErrors = errors.profession;
        const {occupationCode, companyName, industryType, annualIncomeCode} = profession;

        return (
            <div className='block-padding'>
                <DLText
                    id={'revenue'}
                    type={'normal'}
                    text={'Profession'}
                    fontSize={'sm'}
                    fontWeight={'semi-bold'}
                    marginBottom={'md'}
                    fontColor={'black'}/>

                <div id='profession-occupationCode-id' className='mb-3'>

                    <DLSearchAutoComplete id={'occupation-input-field'}
                                          value={occupationCode}
                                          placeholder={''}
                                          label={'Occupation'}
                                          isRequired={true}
                                          onSelect={(e) => handleChange('occupationCode', e, 'profession')}
                                          isClearable={false}
                                          isSearchable={false}
                                          itemNotFoundText={'No options found'}
                                          marginBottom={'none'}
                                          isMulti={false}
                                          error={professionErrors.occupationCode !== ''}
                                          helperMessage={professionErrors.occupationCode}
                                          defaultOptions={dropdownValues?.occupations}
                                          noOptionsMessage={'No options found'}
                    />
                </div>
                <div id='profession-companyName-id' className='mb-3'>
                    <DLInputTextField id={'first-name'}
                                      label={'Company name (Optional)'}
                                      value={companyName}
                                      isClearable={false}
                                      isRequired={true}
                                      maxCharLength={30}
                                      error={professionErrors.companyName !== ''}
                                      helperMessage={professionErrors.companyName}
                                      disableHelperMessageHeight={true}
                                      marginBottom={'none'}
                                      onChangeValue={(e) => {
                                          handleChange('companyName', e, 'profession')
                                      }}
                    />
                </div>
                <div id='profession-industryType-id' className='mb-3'>
                    <DLSearchAutoComplete id={'industry-type-dropDown'}
                                          value={industryType}
                                          label={'Industry type (Optional)'}
                                          placeholder={''}
                                          onSelect={(e) => handleChange('industryType', e, 'profession')}
                                          isClearable={false}
                                          isRequired={true}
                                          isSearchable={false}
                                          itemNotFoundText={'No options found'}
                                          marginBottom={'none'}
                                          isMulti={false}
                                          error={professionErrors.industryType !== ''}
                                          helperMessage={professionErrors.industryType}
                                          defaultOptions={dropdownValues?.industries}
                                          noOptionsMessage={'No options found'}
                    />
                </div>

                <div id='profession-annualIncomeCode-id' className='mb-3'>
                    <DLDropDown id={'family-income-input-field'}
                                label={'Annual Income'}
                                labelPlacement={'top'}
                                noOptionsMessage={'No options found'}
                                placeholder={''}
                                options={dropdownValues?.annualIncomes}
                                minWidth={'100%'}
                                marginBottom={'md'}
                                onSelect={(e) => {
                                    handleChange('annualIncomeCode', e, 'profession')
                                }}
                                value={annualIncomeCode}
                                disableHelperMessageHeight={errors?.profession?.annualIncomeCode === ''}
                                error={errors?.profession?.annualIncomeCode !== ''}
                                helperMessage={errors?.profession?.annualIncomeCode}
                                isRequired={true}
                                isClearable={false}
                    />
                </div>

                <div id='profession-liabilities-id'>
                    <DLInputTextField id={'liablities-input-field'}
                                      llibabel={'Liablities (Optional)'}
                                      maxCharLength={50}
                                      isClearable={false}
                                      disableHelperMessageHeight={true}
                                      marginBottom={'md'}
                                      onChangeValue={(e) => {
                                          handleChange('liabilities', e, 'profession')
                                      }}
                                      value={profession?.liabilities}
                                      error={errors?.profession?.liabilities !== ''}
                                      helperMessage={errors?.profession?.liabilities}
                    />
                </div>

            </div>
        )
    };

    const renderMobilePoliticallyExposedDetails = () => {
        const {politicallyExposed, dropdownValues} = props;
        const {PEPCode} = politicallyExposed;
        return (
            <div className='block-padding'>
                <DLText
                    id={'revenue'}
                    type={'normal'}
                    text={'Politically Exposed?'}
                    fontSize={'sm'}
                    fontWeight={'semi-bold'}
                    marginBottom={'md'}
                    fontColor={'black'}/>
                <div id='politicallyExposed-PEPCode-id'
                     className={'mb-3'}>
                    <DLRadioGroup
                        options={dropdownValues.politicallyExposedValues}
                        id={'hours-radioBtn'}
                        value={PEPCode}
                        hidden={false}
                        label={''}
                        type={'block'}
                        helperMessageColor={'danger'}
                        labelFontColor={'black'}
                        disableHelperMessageHeight={false}
                        disableDescriptionMessageHeight={true}
                        radioType={'multiple'}
                        onChangeValue={(e) => {
                            handleChange('PEPCode', e, 'politicallyExposed')
                        }}
                    />
                </div>
            </div>
        )
    };

    const renderMobileFamilyDetails = () => {
        return (
            <div className='block-padding'>
                <DLText
                    id={'revenue'}
                    type={'normal'}
                    text={'Family Profile'}
                    fontSize={'sm'}
                    fontWeight={'semi-bold'}
                    marginBottom={'md'}
                    fontColor={'black'}/>

                <DLButton id={'dataImport-automaticImport-modalActions-save'}
                          label={'Add Family Member'}
                          buttonSize={'sm'}
                          fontSize={'sm'}
                          sideMargin={'sm'}
                          styleType={'outlined'}
                          onClick={() => {

                          }}
                />
                {/*{renderFamilyMemberTableUI()}*/}
            </div>
        )
    };

    const renderAddFamilyMemberModal = () => {
        const {handleCloseAddModal} = props;
        return (
            <DLModal
                history={props.history}
                maxWidth={'sm'}
                onClose={() => handleCloseAddModal()}
                open={false}
                id={'create-lead-modal'}>
                <DLModalTitle
                    id={'create-lead-title'}
                    onClose={() => handleCloseAddModal()}
                    title={'Add Family Member'}/>
                <DLModalContainer
                    id={'create-lead-container'}>
                    <div className='my-4'>
                        <div className='mb-3'>
                            <div style={{maxWidth: '277px', minWidth: '277px'}}>
                                <DLInputTextField
                                    id={'businessList-keywordSearch'}
                                    label={''}
                                    rows={1}
                                    styleType={'search'}
                                    placeholder={'Search...'}
                                    value={''}
                                    onChangeValue={(e) => handleChange(e)}
                                    isClearable={false}
                                    inputHeight={'sm'}
                                    marginBottom={'md'}
                                    debounceTimer={400}
                                    disableHelperMessageHeight={true}
                                />
                            </div>
                            <DLInputTextField
                                id={'first-name-field'}
                                label={'Full Name'}
                                labelFontColor={'grayDark'}
                                value={''}
                                marginBottom={'sm'}
                                inputType={'text'}
                                disableHelperMessageHeight={true}
                                hideCharLength={true}
                                isClearable={false}
                                onChangeValue={() => {
                                }}
                                onBlur={() => {
                                }}
                                style={{width: '552px'}}
                            />
                            <div>
                                <DLText
                                    id={'revenue'}
                                    type={'normal'}
                                    text={'Relation'}
                                    fontColor={'grayDark'}
                                />
                                <DLDropDown id={''}
                                            label={''}
                                            noOptionsMessage={'No options found'}
                                            placeholder={''}
                                            marginBottom={'sm'}
                                            options={[
                                                {
                                                    label: 'Mother',
                                                    value: 'Mother'
                                                },
                                                {
                                                    label: 'Father',
                                                    value: 'Father'
                                                }
                                            ]}
                                            minWidth={'100%'}
                                            onSelect={() => {
                                            }}
                                            value={''}
                                            style={{width: '552px'}}/>
                            </div>
                        </div>
                    </div>
                </DLModalContainer>
                <DLModalActions id={'create-lead-action'}>
                    <div className='mb-4'
                         style={{paddingTop: '7px'}}>
                        <DLButton
                            id={'create-lead-saveBtn'}
                            history={props.history}
                            label={'Add'}
                            buttonSize={'sm'}
                            fontSize={'md'}
                            sideMargin={'sm'}
                            onClick={() => {
                            }}/>
                    </div>
                </DLModalActions>
            </DLModal>
        );
    };

    const renderTableHead = () => {
        const {headingData} = props;
        return (
            <TableHead>
                <TableRow sx={{maxWidth: '100%'}}>
                    {headingData.map((heading, index) => (
                        <TableCell
                            key={index}
                            sx={{
                                py: '10px',
                                my: 0
                            }}
                            style={{
                                zIndex: (index === 0) ? 101 : 100,
                                left: 0,
                            }}
                            align={'left'}
                            padding={'normal'}
                            sortDirection={false}
                        >
                            <TableSortLabel>
                                <div className='flex-nowrap text-nowrap table-header-css'>
                                    {heading?.label}
                                </div>
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };

    const renderFamilyMemberTableUI = () => {
        return (
            <div className='bg-white mt-3 table-border'>
                <div className='mt-3 mx-3'>
                    <DLText
                        id={'family'}
                        type={'normal'}
                        text={'Family'}
                        fontSize={'lg'}
                        fontWeight={'semi-bold'}
                        fontColor={'black'}/>
                </div>
                <div className='w-100 pagination-container'>
                    <Box>
                        <Paper sx={{boxShadow: 'none'}}>
                            <TableContainer>
                                <Table className=''
                                       sx={{minWidth: 10}}
                                       aria-labelledby='tableTitle'
                                       stickyHeader>
                                    {renderTableHead()}
                                    <TableBody>
                                        {familyMembersData.map((item, index) => {
                                            return (
                                                <TableRow
                                                    role='checkbox'
                                                    tabIndex={-1}
                                                    key={index}
                                                    className={((index % 2) === 0) ? 'evenRowBg' : 'bg-white'}
                                                    sx={{
                                                        border: 'none'
                                                    }}>
                                                    <TableCell align='left'
                                                               className={'table-td-mui'}
                                                               style={{
                                                                   position: 'sticky',
                                                                   left: 0
                                                               }}>
                                                        {item?.name}
                                                    </TableCell>

                                                    <TableCell align='left'
                                                               className={'table-td-mui'}>
                                                        <div className='text-break py-2'>
                                                            {item?.email}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        className={'table-td-mui'}
                                                        align='left'>
                                                        {item?.phone}
                                                    </TableCell>
                                                </TableRow>);
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Box>
                </div>
            </div>
        )
    };

    const renderButtonUI = () => {

        const {isFromOnboardingFlow, onNextClick, onSaveAndCloseClick} = props;
        return (
            <div className='sticky-bottom-buttons'>
                {isFromOnboardingFlow ?
                    <>
                        <DLButton
                            id={'cancel-button'}
                            styleType={'outlined'}
                            borderType={'square'}
                            buttonSize={'sm'}
                            fontSize={'sm'}
                            sideMargin={'none'}
                            onClick={() => {
                                onSaveAndCloseClick()
                            }}
                            label={'Save & Close'}
                        />
                        <DLButton id={'next-button'}
                                  buttonSize={'sm'}
                                  fontSize={'sm'}
                                  sideMargin={'none'}
                                  onClick={() => {
                                      onNextClick()
                                  }}
                                  label={'Next'}/>
                    </>
                    :
                    <DLButton id={'delete-button'}
                              buttonSize={'sm'}
                              fontSize={'sm'}
                              sideMargin={'none'}
                              onClick={() => {
                                  onNextClick()
                              }}
                              label={"Save"}/>
                }
            </div>
        );

    };

    const renderAllTabs = () => {
        const {totalApplicants, isFromOnboardingFlow, changePersonTab, applicantName} = props;
        return totalApplicants?.map((item, index) => {
            if (item !== 'Common') {
                return (
                    <Tab key={'tab_' + index}
                         onClick={() => {
                             if (!isFromOnboardingFlow) {
                                 changePersonTab(item)
                             }
                         }}
                         label={item}
                         style={{
                             textTransform: 'none',
                             color: appTheme.appColor.black,
                             fontFamily: appTheme.default.fontFamily,
                             fontWeight: (applicantName === item) ? 500 : 400,
                             cursor: (!isFromOnboardingFlow) ? 'pointer' : 'auto'
                         }}
                         value={item}
                    />

                );
            }
        })
    };

    const renderApplicantTabUI = () => {
        const {isMultipleApplicant, applicantName} = props;
        if (!isMultipleApplicant) {
            return <></>
        }
        return (
            <div className={isMobileView ? 'px-3' : "px-5"}>
                <Box sx={{width: '100%'}}>
                    <TabContext value={applicantName}>
                        <Box sx={{borderBottom: 2, borderColor: appTheme.appColor.grayLight}}>
                            <TabList onChange={() => {
                            }} aria-label="lab API tabs-example">
                                {renderAllTabs()}
                            </TabList>
                        </Box>
                    </TabContext>
                </Box>
            </div>
        );
    };

    const renderDesktopUI = () => {
        const {isMultipleApplicant, isFromOnboardingFlow} = props;
        return (
            <div>
                <div>
                    {renderApplicantTabUI()}
                </div>
                <div style={{overflowY: 'auto'}}>
                    <div className={'page-container '  + (isFromOnboardingFlow ? isMultipleApplicant?'mt-3':'' : 'my-3')}
                         style={{
                             maxHeight: isMultipleApplicant ?
                             !isFromOnboardingFlow ? 'calc(100dvh - 284px)' : 'calc(100dvh - 268px)' :
                             !isFromOnboardingFlow ? 'calc(100dvh - 230px)' : 'calc(100dvh - 200px)'
                         }}>
                        {renderErrorUI()}
                        {renderSelfInformation()}
                        {renderFathersInformation()}
                        {renderMothersInformation()}
                        {renderAddressDetails()}
                        {renderContactDetails()}
                        {renderProfessionalDetails()}
                        {renderPoliticallyExposedDetails()}
                        {/*{renderFamilyDetails()}*/}
                    </div>
                </div>
                {renderButtonUI()}
            </div>
        )
    };

    const renderMobileUI = () => {
        return (
            <div style={{maxHeight: 'calc(100dvh - 254px)', overflowY: 'auto'}}>
                {renderApplicantTabUI()}
                <div className={'mb-3'}>
                    {renderErrorUI()}
                    {renderMobileSelfInformation()}
                    <div style={{height: '5px', backgroundColor: appTheme.appColor.gray}}/>
                    {renderMobileFathersInformation()}
                    <div style={{height: '5px', backgroundColor: appTheme.appColor.gray}}/>
                    {renderMobileMothersInformation()}
                    <div style={{height: '5px', backgroundColor: appTheme.appColor.gray}}/>
                    {renderMobileAddressDetails()}
                    <div style={{height: '5px', backgroundColor: appTheme.appColor.gray}}/>
                    {renderMobileContactDetails()}
                    <div style={{height: '5px', backgroundColor: appTheme.appColor.gray}}/>
                    {renderMobileProfessionalDetails()}
                    <div style={{height: '5px', backgroundColor: appTheme.appColor.gray}}/>
                    {renderMobilePoliticallyExposedDetails()}
                    {/*{renderMobileFamilyDetails()}*/}

                </div>
                {renderButtonUI()}
            </div>
        )
    };

    const renderErrorUI = () => {
        const {responseErrors, totalErrors} = props;

        if (isEmpty(responseErrors) || totalErrors === 0) {
            return '';
        }

        return (
            <div id='page-level-errors' className={isMobileView ? 'page-container' : 'my-3'}>
                {responseErrors.slice(0, 3).map((errorMessage, index) => (
                    <div key={index} className='py-2 align-items-center d-flex message-block'>
                        <div className='error-vertical-line'/>
                        <div className='error-panel'>
                            {errorMessage}
                        </div>
                    </div>
                ))}
                {totalErrors > 3 && (
                    <div className='py-2 align-items-center d-flex'>
                        {`...${totalErrors - 3} More`}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div>
            {isMobileView ? renderMobileUI() : renderDesktopUI()}

            <DLLoader isVisible={props.isLoaderVisible} type={'screen'}/>

            {renderAddFamilyMemberModal()}
        </div>
    )
};

export default PersonalProfileView;
