import React, {useEffect, useState} from 'react';
import DashboardWidget from "../../../DashboardWidget";
import {getClientCategory} from "../../../HomePageActions";

const ClientCategory=(props)=> {
    const [clientCategoryState, setClientCategoryState] = useState({
        categoryData: {},
        isShowLoader: false,
    });

    useEffect(() => {
        getDetails();
    }, [props.isRefreshButtonLoader]);

    const getDetails = () => {

        setClientCategoryState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getClientCategory().then(res => {
            if (res.success) {
                setClientCategoryState(prevState => ({
                    ...prevState,
                    categoryData: res.data,
                    isShowLoader: false,
                }));
            } else {
                setClientCategoryState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                }));
            }
        })
    };

    const setFilters = (item) => {
        // props.history.push('/leads')
    };


    return (
        <DashboardWidget
            {...props}
            {...clientCategoryState}
            detailsObj={clientCategoryState.categoryData}
            setFilters={setFilters}
            title={"Summary by Category"}
        />
    );
};

export default ClientCategory;
