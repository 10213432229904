
import {createSlice} from "@reduxjs/toolkit";

const appSlice = createSlice({
    name: 'OnboardingReducer',
    initialState: {
        onboardingObj : {},
        accountId: '',
        accountType: '',
        CRMDetails: [],
        applicationId: '',
        isRefershIINForm: false,
        isRefershACHForm: false,
        bankData: [],
        IINSubmitStatus: '',
        ECSSubmitStatus: '',
        onboardingApplicationId: ''
    },
    reducers: {
        setOnboardingData (state,action) {
            state.onboardingObj = action.payload;
        },
        setAccountId (state,action) {
            state.accountId = action.payload;
        },
        setAccountType (state,action) {
            state.accountType = action.payload;
        },
        setCRMDetails(state,action) {
            state.CRMDetails = action.payload;
        },
        setIINFormStatus(state,action) {
            state.isRefershIINForm = action.payload;
        },
        setACHFormStatus(state,action) {
            state.isRefershACHForm = action.payload;
        },
        setApplicationId(state,action) {
            state.applicationId = action.payload;
        },
        setBankData(state,action) {
            state.bankData = action.payload;
        },
        setIINSubmitStatus(state,action) {
            state.IINSubmitStatus = action.payload.status;
        },
        setECSSubmitStatus(state,action) {
            state.ECSSubmitStatus = action.payload.status;
        },
        setOnboardingApplicationId(state,action) {
            state.onboardingApplicationId = action.payload;
        },
    }
});

export const {setOnboardingData, setAccountId,setAccountType, setCRMDetails, setACHFormStatus, setIINFormStatus, setApplicationId,
    setBankData, setIINSubmitStatus, setECSSubmitStatus, setOnboardingApplicationId} = appSlice.actions;
export default appSlice.reducer

