import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import DashboardWidget from "../../../DashboardWidget";
import {getManagementCRMLeads, resetLeadFilters, setCRM, setStatus} from "../../../HomePageActions";

const CRMLeads = (props) => {
    const [leadCRMState, setLeadCRMStateState] = useState({
        CRMLeads: {},
        isShowLoader: false,
    });

    useEffect(() => {
        getDetails();
    }, [props.isRefreshButtonLoader]);

    const getDetails = () => {
         setLeadCRMStateState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getManagementCRMLeads().then(res => {
            if (res?.success) {
                setLeadCRMStateState(prevState => ({
                    ...prevState,
                    CRMLeads: res.data,
                    isShowLoader: false,
                }));
            } else {
                setLeadCRMStateState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                }));
            }
        })
    };

    const setFilters = (item) => {
        resetLeadFilters();
        setStatus(['ALL']);
        setCRM(item.key);
        props.history.push('/leads')
    };


    return (
        <DashboardWidget
            {...props}
            {...leadCRMState}
            detailsObj={leadCRMState.CRMLeads}
            setFilters={setFilters}
            title={"Leads by CRM"}
        />
    );
};


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
});
export default connect(mapStateToProps, {})(CRMLeads);
