import React from 'react';

import {getRandomId, isEmpty} from "../../../core/components/DLComponentHelper";

import DLText from "../../../core/components/DLText/DLText";
import DLLoader from "../../../core/components/DLLoader/DLLoader";
import DLButton from "../../../core/components/DLButton/DLButton";
import DLInputTextField from "../../../core/components/DLInputTextField/DLInputTextField";
import Dropdown from "react-bootstrap/Dropdown";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import appTheme from "../../../assets/appTheme";


const PortfolioCreateView = (props) => {
    const {
        isXSView, isShowLoader, isMobileView, addOneMoreParam, nodeLinks,
        setFundAllocation, removeLinking, portfolioName, errors, handleChange, handleSave, modalErrorMessage, getTotalAllocation
    } = props;

    const renderHeaderCreateButton = () => {
        const {isMobileView} = props;

        return (
            <div className={'d-flex justify-content-center pe-2 my-2' + (isMobileView ? ' page-container' : ' mx-4')}>
                <div className="mb-1">
                    <DLButton id={'cancel-button'}
                              styleType={'outlined'}
                              onClick={() => props.history.push('/portfolio-management')
                              }
                              label={'Cancel'}/>
                    <DLButton id={'save-button'}
                              styleType={'outlined'}
                              onClick={() => {handleSave()}}
                              label={'Save'}/>
                </div>
            </div>
        )

    };

    const renderHeader = () => {
        return (
            <div className='d-flex justify-content-between'>
                <div className='page-header'>
                    Portfolio Management
                </div>
                <div>
                    {renderHeaderCreateButton()}
                </div>
            </div>
        )
    };

    const renderTableUi = () => {
        return (
            <div className={'p-3 overflow-scroll overflow-x-hidden'} style={{maxHeight: 'calc(100dvh - 310px)'}}>
                {isShowLoader ?
                    <div className='d-flex align-items-center justify-content-center h-100'>
                        {renderLoader()}
                    </div>
                    :
                    renderCreateUi()
                }
            </div>
        )
    };

    const renderBottomBlock = () => {
        return(
            <div className={"py-2 border-top row"}>
                <div className='data-card-label col-2' style={{}}></div>
                <div className='data-card-label col-5' style={{}}></div>
                {getTotalAllocation() !==0 &&
                <div className='data-card-label col-3' style={{}}>{getTotalAllocation()+'%'}</div>}
                <div className='data-card-label col-2 text-center' style={{}}></div>
            </div>
        )
    };

    const renderWidget = () => {
        return (
            <div className={"py-2 px-3 border-bottom d-flex justify-content-between"}>
                <div className='d-flex align-items-center'>
                    <DLText id={'funds-title'}
                            isInline={true}
                            fontSize={'md'}
                            marginBottom={'none'}
                            fontWeight={'semi-bold'}
                            text={'Funds'}/>
                </div>
                <div>
                    <DLButton id={'add-button'}
                              isDisabled={nodeLinks?.length === 10}
                              onClick={() => {addOneMoreParam()}}
                              hidden={isEmpty(nodeLinks)}
                              label={'Add'}/>
                </div>
            </div>
        )
    };

    const renderDesktopUi = () => {
        return (
            <div className={`p-0 w-100 border-rounded panel h-100 ${isXSView ? 'mb-3' : ''}`} >
                <div className="px-0 h-100 c">
                    {renderWidget()}
                    {renderTableUi()}
                    {!isEmpty(nodeLinks) && renderBottomBlock()}
                </div>
            </div>
        )
    };

    const renderCreateUi = () => {
        if(isEmpty(nodeLinks)){
            return(
                <div className='d-flex justify-content-center align-items-center' style={{minHeight:'300px'}}>
                    <DLButton id={'add-funds-to-create-portfolio-button'}
                              onClick={() => {addOneMoreParam()}}
                              label={'Add Funds to Create Portfolio'}/>
                </div>
            )
        }
            return (
                <div>
                    {renderHeadings()}
                    {renderData()}
                </div>
            )
    };

    const getUi = (arrayIndex) => {
        const {funds} = props;
        if (isEmpty(funds)) {
            return (
                <div className="w-100" style={{height: 'calc(100vh - 555px)'}}>
                    <div className="d-flex justify-content-center align-items-center w-100 h-100">
                        <DLText id={""}
                                text={"No match found."}
                                fontColor={"lightGray"}
                                marginBottom={"none"}
                        />
                    </div>
                </div>
            );
        }
        return (
            <div>
                {!isEmpty(funds) && <div>
                    {funds.map((item, index) => {
                        return (
                            <Dropdown.Item onClick={() => setFundAllocation("fund", item?.description, arrayIndex, item?.id)} key={getRandomId()}>
                                <div className={index === 0 ? "p-2" : "p-2 border-top"}>
                                    <div>
                                        <DLText id={""}
                                                text={item?.description}
                                                fontWeight={"normal"}
                                                fontSize={"xs"}
                                                isInline={true}
                                                marginBottom={"none"}
                                        />
                                    </div>
                                </div>
                            </Dropdown.Item>
                        )
                    })}
                </div>}
            </div>
        );
    }

    const renderModalError = () => {
        if (modalErrorMessage) {
            return (
                <div className='py-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {modalErrorMessage}
                    </div>
                </div>
            )
        }
    };

    const renderSearchBar = (index, item) => {

        return (
            <div className='d-flex justify-content-center align-items-center'>
                <div className={"w-100"}>
                    <div className="resappbar-search-box-container" style={{flex: 1}}>
                        <Dropdown className='w-100 p-0' style={{borderColor : item?.fundError !== ''? appTheme.appColor.danger : '' }}>
                            <Dropdown.Toggle id="" className='py-1 '>
                                <div className="w-100"
                                     style={{color: "var(--gray-light)"}}
                                >
                                    <Paper
                                        className="res-app-paper"
                                        component=""
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                            boxShadow: "none",
                                            p: 0
                                        }}
                                    >
                                        <InputBase
                                            sx={{flex: 1, p: 0, fontSize: '14px', fontWeight: 500}}
                                            fullWidth
                                            onChange={(e) => setFundAllocation("keyword", e.target.value, index)}
                                            autoFocus={true}
                                            placeholder={"Search by name"}
                                            value={nodeLinks[index].keyword}

                                        />
                                        {
                                            <SearchIcon/>
                                        }
                                    </Paper>
                                </div>
                            </Dropdown.Toggle>
                        </Dropdown>
                    </div>
                    {
                        (!isEmpty(nodeLinks[index].keyword) && !nodeLinks[index].isSelected) &&
                    <div className='resappbar-search-box-container-options'>
                        <div style={{maxHeight: 'calc(100vh - 555px)', overflowY: "auto"}}>
                            {getUi(index)}
                        </div>
                    </div>
                    }
                </div>
            </div>
        )
    };

    const renderData = () => {
        return(
            <div style={{minHeight: 'calc(100dvh - 385px)'}}>
                {nodeLinks.map((item, index)=>{
                    return (
                        <div className={"py-2 border-bottom row"} key={index}>
                            <div className='data-card-label col-2'>{(index+1)}</div>
                            <div className='data-card-label col-5'>
                                {nodeLinks[index].isSelected ?
                                    <DLText id={''}
                                            text={nodeLinks[index]?.fund}
                                    />
                                : renderSearchBar(index, item)}
                                {item?.fundError !== '' &&
                                <div className='ps-2'>
                                    <DLText id={''}
                                            text={'Please select fund.'}
                                            fontColor={"danger"}
                                            fontSize={"xs"}
                                    />
                                </div>
                                }
                            </div>
                            <div className='data-card-label col-3'>
                                <div className='w-75'>
                                    <DLInputTextField
                                        id={'portfolio-name'}
                                        marginBottom={"none"}
                                        onChangeValue={(e) => {
                                            setFundAllocation("percentage", e, index)
                                        }}
                                        isClearable={false}
                                        error={item?.percentageError !== ''}
                                        helperMessage={item?.percentageError}
                                        value={item?.percentage}
                                        inputProps={{style: {padding: '7px 10px', maxWidth: "80px"}}}
                                        inputType={"number"}
                                        disableHelperMessageHeight={true}
                                    />
                                </div>
                            </div>
                            <div className='data-card-label col-2 text-center cursor' onClick={()=>{removeLinking(index)}}><i className="fa-regular fa-trash-can me-2"/></div>
                        </div>
                    )
            })}
            </div>
        )
    };

    const renderHeadings = () => {
        return(
            <div className={"py-2 border-bottom row"}>
                <div className='data-card-label col-2' style={{}}>{"SR No."}</div>
                <div className='data-card-label col-5' style={{}}>{"Fund"}</div>
                <div className='data-card-label col-3' style={{}}>{"Allocation in %"}</div>
                <div className='data-card-label col-2 text-center' style={{}}>{"Action"}</div>
            </div>
        )
    };

    const renderUi = () => {
        return (
            <div className={'w-100 mt-3' + ((isMobileView && !isXSView) ? '  page-container' : '')}>
                <div className='row'>
                    <div className='col-12'>
                        {renderDesktopUi()}
                    </div>
                </div>
            </div>
        )
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    if (isMobileView) {
        return (
            <div>
                <div className={'w-100'} style={{
                    background: 'rgba(0, 0, 0, 0.02)'
                }}>
                    {renderHeader()}
                    {renderUi()}
                    {renderLoader()}
                </div>
            </div>
        );
    }

    return (
        <div className={'h-100 d-flex justify-content-center bg-light'}>
            <div className={'w-100'}>
                {renderHeader()}
                <div className='page-container pt-1'>
                    {renderModalError()}
                    <div className='d-flex'>
                        <div className='search-input' style={{flex: 1.8}}>
                            <DLInputTextField id={'full-name'}
                                              label={"Portfolio Name"}
                                              value={portfolioName}
                                              maxCharLength={60}
                                              inputType={"text"}
                                              labelFontColor={'grayDark'}
                                              isClearable={false}
                                              isRequired={true}
                                              disableHelperMessageHeight={true}
                                              error={errors.portfolioName !== ''}
                                              helperMessage={errors.portfolioName}
                                              marginBottom={'sm'}
                                              onChangeValue={(e) => {
                                                  handleChange("portfolioName", e)
                                              }}
                            />
                        </div>
                    </div>
                </div>
                <div className='page-container'>
                    {renderUi()}
                </div>
                {renderLoader()}
            </div>
        </div>
    );
};

export default PortfolioCreateView;