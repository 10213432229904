import React from 'react';

import {isEmpty} from "../../../core/components/DLComponentHelper";
import {cellConfig, cellConfigMobile, headingData, headingMobileData} from "./FormAndReviewModel";

import DLText from "../../../core/components/DLText/DLText";
import DLTable from "../../../core/components/DLTable/DLTable";
import DLButton from "../../../core/components/DLButton/DLButton";
import DLLoader from "../../../core/components/DLLoader/DLLoader";
import Kyc from "./components/KYC/KYC";
import IINForm from "./components/IINForm/IINForm";
import BankMandate from "./components/bankMandate/BankMandate";
import appTheme from "../../../assets/appTheme";

const FormsAndReviewView = (props) => {
    const {
        formAndReviewList, isXSView, handleValidateForm, isShowLoader, errorCount, isXLView, generateForm, generatedFormDetails,
        isShowGenerateFormButton, isMobileView, IINNumber, setIINForm, updateGeneratedForms, isManagementUser, isLGView
    } = props;

    const formattedDocType = (type) =>{
        if(isEmpty(type)){
            return ''
        }
        switch(type) {
            case 'PAN_CARD':
                return 'PAN Card';
            case 'AADHAAR_CARD':
                return 'Aadhaar Card';
            case 'BANK_PROOF':
                return 'Bank Proof';
            case 'PHOTOGRAPH':
                return 'Photograph';
            default:
                return type;
        }

    };

    const getErrorField = (fields) => {
        return (
            <DLText id={'eroor-message'}
                    isInline={true}
                    fontSize={'xs'}
                    fontColor={"danger"}
                    text={fields.errorDetails?.message}/>
        )
    };

    const FormattedSectionField = (fields) => {
        let name = '';
        if (fields?.documentError?.otherDetails?.type !== 'NOMINEE' && fields.category !== 'Nominee') {
            name = fields.category
        }
        if (fields?.errorType === 'DOCUMENT') {
            if (!isEmpty(fields?.documentError?.otherDetails?.applicantNumber)) {
                name += " - Applicant " + fields?.documentError?.otherDetails?.applicantNumber;
            }
            if (fields?.documentError?.otherDetails?.type === 'NOMINEE') {
                let nomineeNumber = '';
                if (!isEmpty(fields?.documentError?.otherDetails?.nomineeNumber)) {
                    nomineeNumber = fields?.documentError?.otherDetails?.nomineeNumber
                }
                if (isEmpty(name)) {
                    name += "Nominee " + nomineeNumber;
                } else {
                    name += " - Nominee " + nomineeNumber;
                }
            }

            if (!isEmpty(fields?.documentError?.otherDetails?.nomineeName)) {
                name += " - " + fields?.documentError?.otherDetails?.nomineeName;
            }

            if (!isEmpty(fields?.documentError?.otherDetails?.subType)) {
                let subType = fields?.documentError?.otherDetails?.subType;
                if(subType === 'GUARDIAN'){
                    name += " - Guardian";
                }else{
                    name += " - " + subType;
                }
            }

            if (fields.documentError.documentType !== null) {
                name += " - " + formattedDocType(fields?.documentError?.documentType);
            }
        }
        if (fields?.errorType === 'FIELD') {
            if (!isEmpty(fields?.dataFieldError?.otherDetails?.applicantNumber)) {
                name += " - Applicant " + fields?.dataFieldError?.otherDetails?.applicantNumber;
            }
            if (fields?.dataFieldError?.otherDetails?.type === 'NOMINEE') {
                let nomineeNumber = '';
                if (!isEmpty(fields?.dataFieldError?.otherDetails?.nomineeNumber)) {
                    nomineeNumber = fields?.dataFieldError?.otherDetails?.nomineeNumber
                }
                if (isEmpty(name)) {
                    name += "Nominee " + nomineeNumber;
                } else {
                    name += " - Nominee " + nomineeNumber;
                }
            }

            if (!isEmpty(fields?.dataFieldError?.otherDetails?.nomineeName)) {
                name += " - " + fields?.dataFieldError?.otherDetails?.nomineeName;
            }

            if (!isEmpty(fields?.dataFieldError?.otherDetails?.subType)) {
                let subType = fields?.dataFieldError?.otherDetails?.subType;
                if(subType === 'GUARDIAN'){
                    name += " - Guardian";
                }else {
                    name += " - " + subType;
                }
            }

        }
        if (fields?.errorType === 'NOMINEE') {
            if (!isEmpty(fields?.dataFieldError?.otherDetails?.nomineeName)) {
                name += " - " + fields?.dataFieldError?.otherDetails?.nomineeName;
            }
        }

        return name;
    };

    const getSectionField = (fields) => {
        const names = FormattedSectionField(fields);

        return (
            <div className='d-flex gap-1'>
                <DLText id={"category"}
                        fontSize={"xs"}
                        text={names}
                />
            </div>
        )
    };

    const getFormattedTableData = (formAndReviewList) => {
        const {isXSView} = props;

        if (isEmpty(formAndReviewList)) {
            return []
        }

        return (
            formAndReviewList.map((fields, index) => {
                const formAndReviewFields = [
                    {isCustomUI: true, customUI: getSectionField(fields)},
                    {isCustomUI: true, customUI: getErrorField(fields)}];

                if (!isXSView) {
                    formAndReviewFields.unshift(index + 1);
                }

                return {
                    id: fields.id,
                    data: formAndReviewFields,
                };
            }))
    };

    const renderTable = () => {
        let tableData = getFormattedTableData(formAndReviewList);
        return (
            <div className={isMobileView ? "w-100 pb-4" : "w-100 pb-4"}>
                <DLTable
                    id={'desktop-table'}
                    tableMaxHeight={isXSView ? 'calc(100dvh - 328px)' : 'calc(100dvh - 876px)'}
                    tableMinHeight={isXSView ? 'calc(100dvh - 366px)' : 'calc(100dvh - 454px)'}
                    isRowClickable={false}
                    isDynamicHeight={true}
                    onRowClick={() => {
                        // redirectToDetailsScreen(rowId)
                    }}
                    cellConfig={isXSView ? cellConfigMobile : cellConfig}
                    headerData={isXSView ? headingMobileData : headingData}
                    tableData={tableData}
                    isShowPagination={false}
                />
            </div>
        )
    };
    const renderErrorUI = () => {
        const {responseErrors} = props;

        if (isEmpty(responseErrors)) {
            return '';
        }

        return (
            <div id='page-level-errors'
                 className={isXSView ? '' : 'mb-2'}>
                <div className='pb-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseErrors}
                    </div>
                </div>
            </div>
        );
    };

    const renderGenerateFormsButton = () => {
        return (
            <div className='d-flex justify-content-between align-items-center'>
                <div>
                    {!isEmpty(errorCount) &&
                    <div className='d-flex justify-content-center align-items-center mb-1 pb-1'>
                        <DLText id={''}
                                text={'Errors '}
                                fontSize={"lg"}
                                isInline={true}
                                fontWeight={"semi-bold"}
                        />
                        <div className="ms-1">
                            <DLText id={''}
                                    text={' (' + errorCount + ')'}
                                    fontColor={"disable"}
                                    fontSize={"sm"}
                                    isInline={true}
                                    fontWeight={"semi-bold"}
                            />
                        </div>
                    </div>
                    }
                </div>

                {/*{isShowGenerateFormButton() &&*/}
                {/*<div*/}
                {/*    className='border w-100 py-2 px-3 d-flex align-items-center justify-content-between border-rounded w-100 mb-3'*/}
                {/*    style={{backgroundColor: appTheme.appColor.lightBlue}}>*/}
                {/*    <DLText id={''}*/}
                {/*            text={'Everything looks good, proceed to generate forms.'}*/}
                {/*            marginBottom={"none"}*/}
                {/*    />*/}
                {/*    <DLButton*/}
                {/*        id={'generate-form-button'}*/}
                {/*        sideMargin={'none'}*/}
                {/*        onClick={() => {*/}
                {/*            generateForm()*/}
                {/*        }}*/}
                {/*        isDisabled={isManagementUser}*/}
                {/*        label={'Generate Forms'}/>*/}
                {/*</div>}*/}

                {(!isEmpty(formAndReviewList)) &&
                <div className='mb-3'>
                    <DLButton
                        id={'generate-form-button'}
                        sideMargin={'none'}
                        isDisabled={isManagementUser}
                        onClick={() => {
                            handleValidateForm()
                        }}
                        label={'Validate'}/>
                </div>
                }
            </div>
        )
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    const renderMobileUI = () => {
        return (
            <div className="m-3 mx-0">
                {renderErrorUI()}
                <div>
                    {renderGenerateFormsButton()}
                </div>
                {renderLoader()}
                {(!isEmpty(formAndReviewList)) ? renderTable() :
                    <div>
                        <div className={'section-divider p-0'}>
                            <div className='block-padding'>
                                <Kyc {...props}/>
                            </div>
                        </div>

                        <div className={isEmpty(generatedFormDetails) ? 'section-divider p-0' :'section-divider p-0 '}>
                            <div className='block-padding'>
                                <IINForm
                                    {...props}
                                    setIINForm={setIINForm}
                                    updateGeneratedForms={updateGeneratedForms}
                                    generatedFormDetails={generatedFormDetails}
                                />
                            </div>
                        </div>
                        <div className={'pb-4'+ (isMobileView ? '':'pt-4')}>
                            <div className='block-padding'>
                                <BankMandate
                                    {...props}
                                    IINNumber={IINNumber}
                                    updateGeneratedForms={updateGeneratedForms}
                                    generatedFormDetails={generatedFormDetails}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    };

    const renderDesktopUI = () => {
        return (
            <div style={{
                minHeight: 'calc(100dvh - 152px)',
                maxHeight: 'calc(100dvh - 152px)',
                overflowX: "auto"
            }}
                 className={isEmpty(props.UIState) ? 'd-flex align-items-center justify-content-center px-5' : 'page-container'}>
                {(isEmpty(props.UIState)) ?
                    <DLLoader type={"screen"} isVisible={true}/>
                    :
                <div className="m-3 mx-0">
                    {renderErrorUI()}
                    <div className='m-0'>
                        <div>
                            <div className="w-100">
                                {renderGenerateFormsButton()}
                            </div>
                            {renderLoader()}
                            {(!isEmpty(formAndReviewList)) ?
                                renderTable() :
                                <div>
                                    <div className='row p-0 m-0'>
                                        <div className='col-4 p-0 m-0'>
                                            <div className='pe-2 me-1'>
                                                <Kyc
                                                    {...props}
                                                    generatedFormDetails={generatedFormDetails}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 p-0 m-0'>
                                            <div className='pe-1 ps-1'>
                                                <IINForm
                                                    {...props}
                                                    setIINForm={setIINForm}
                                                    updateGeneratedForms={updateGeneratedForms}
                                                    generatedFormDetails={generatedFormDetails}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 p-0 m-0'>
                                            <div className='ps-2 ms-1'>
                                                <BankMandate
                                                    {...props}
                                                    IINNumber={IINNumber}
                                                    updateGeneratedForms={updateGeneratedForms}
                                                    generatedFormDetails={generatedFormDetails}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>}
            </div>
        )
    };

    return (
        <div>
            {isMobileView ? renderMobileUI() : renderDesktopUI()}
        </div>
    )
};


export default FormsAndReviewView;