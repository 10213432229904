import React from 'react';

import {isEmpty} from "../../../utils/validations";

import DLButton from "../../../core/components/DLButton/DLButton";
import DLModal from "../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLText from "../../../core/components/DLText/DLText";
import DLLoader from "../../../core/components/DLLoader/DLLoader";

const DeletePortfolioView = (props) => {

    const {
        history, isDeletePortfolioModalOpen, handleCloseDeletePortfolioModal,
        isApiInProgress, modalErrorMessage, deletePortfolio, portfolioName
    } = props;

    const renderModalError = () =>{
        if(modalErrorMessage){
            return(
                <div className='py-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {modalErrorMessage}
                    </div>
                </div>
            )
        }
    };

    const renderDeleteLeadModal = () => {
        return (
            <div>
                <DLModal
                    history={history}
                    maxWidth={"xs"}
                    onClose={() => handleCloseDeletePortfolioModal()}
                    open={isDeletePortfolioModalOpen}
                    id={'remove-modal'}>
                    <DLModalTitle
                        id={'remove-title'}
                        onClose={() => handleCloseDeletePortfolioModal()}
                        title={'Confirmation'}/>
                    <DLModalContainer
                        id={'remove-container'}>
                        <div>
                            {renderModalError()}
                            <div className='text-center'>
                                {!isEmpty(portfolioName) &&
                                    <>
                                        <DLText id={''}
                                                text={'Are you sure you want to remove'}
                                                fontSize={"sm"}
                                                fontWeight={"normal"}
                                        />
                                        <DLText id={''}
                                                text={portfolioName +" ?"}
                                                fontSize={"sm"}
                                                fontWeight={"semi-bold"}
                                        />
                                    </>
                                }
                            </div>
                        </div>
                    </DLModalContainer>
                    <DLModalActions id={'remove-action'}>
                        <div className='mb-4' style={{paddingTop: '7px'}}>
                            <DLButton
                                id={'remove-cancelBtn'}
                                history={history}
                                label={'Cancel'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                styleType={"outlined"}
                                backgroundColor={"grey"}
                                sideMargin={"sm"}
                                onClick={() => handleCloseDeletePortfolioModal()}/>
                            <DLButton
                                id={'remove-assignBtn'}
                                history={props.history}
                                label={'Remove'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                sideMargin={"sm"}
                                type={"danger"}
                                onClick={() => deletePortfolio()}
                            />
                        </div>
                    </DLModalActions>
                </DLModal>
            </div>
        );
    };

    const overlayDeleteLead = () => {
        return (
            <div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='p-3'>
                        <DLText
                            id={'confirmation'}
                            fontSize={'md'}
                            marginBottom={"none"}
                            fontWeight={'semi-bold'}
                            text={'Confirmation'}
                        />
                    </div>
                    <div className='p-3'>
                        <i className="fa-solid fa-xmark cursor default-icon"
                           onClick={() => handleCloseDeletePortfolioModal()}/>
                    </div>
                </div>
                <div className='px-3'
                     style={{
                         maxHeight: 'calc(100dvh - 190px)',
                         overflow: 'auto',
                     }}>
                    <div className='pb-5'>
                        {renderModalError()}
                        <div className='text-center my-4'>
                            <div className='my-4'>
                                <DLText id={''}
                                        text={'Are you sure you want to remove'}
                                        fontSize={"sm"}
                                        fontWeight={"normal"}
                                />
                                {!isEmpty(portfolioName) &&
                                <DLText id={''}
                                        text={portfolioName + " ?"}
                                        fontSize={"sm"}
                                        fontWeight={"semi-bold"}
                                />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='fixed-bottom mb-2'>
                    <div className='mb-2 d-flex justify-content-center bg-white'
                         style={{paddingTop: '7px', minHeight: '43px'}}>
                        <DLButton
                            id={'remove-cancelBtn'}
                            history={history}
                            label={'Cancel'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            sideMargin={"sm"}
                            onClick={() => handleCloseDeletePortfolioModal()}/>
                        <DLButton
                            id={'remove-assignBtn'}
                            history={props.history}
                            label={'Remove'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            sideMargin={"sm"}
                            type={"danger"}
                            onClick={() => deletePortfolio()}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderLoader = () => {
        return (
            <DLLoader type={"screen"} isVisible={isApiInProgress}/>
        )
    }

    return (
        <div>
            {renderLoader()}
            {props.isOverlay ? overlayDeleteLead() : renderDeleteLeadModal()}
        </div>
    );
};

export default DeletePortfolioView;