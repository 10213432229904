import React from "react";
import appTheme from "../../assets/appTheme";
import {isEmpty} from "../../utils/validations";

export const renderNFTDetailsStatusBadge = (status) => {
    if (isEmpty(status)) {
        return '-'
    }
    switch (status) {
        case 'IN_PROGRESS':
            return (<div className="d-flex align-items-center badge-amber ms-0">
                <span style={{color: appTheme.appColor.white}}>In progress</span>
            </div>);
        case 'OPEN':
            return (<div className="d-flex align-items-center badge-amber ms-0">
                <span style={{color: appTheme.appColor.white}}>Open</span>
            </div>);
        case 'COMPLETED':
            return (<div className="d-flex align-items-center badge-green ms-0">
                <span style={{color: appTheme.appColor.white}}>Completed</span>
            </div>);
        case 'DISCARDED':
            return (<div className="d-flex align-items-center badge-red ms-0">
                <span style={{color: appTheme.appColor.white}}>Discarded</span>
            </div>);
        default: return '';
    }
};

export const renderTransactionStatusDot = (status) => {
    if (isEmpty(status)) {
        return '-'
    }
    switch (status) {
        case 'OPEN':
            return <span className="status-dot warning"/>;

        case 'COMPLETED':
            return <span className="status-dot success"/>;

        case 'IN_PROGRESS':
            return <span className="status-dot warning"/>;

        case 'DISCARDED':
            return <span className="status-dot danger"/>;
    }
};

export const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
        event.preventDefault();
        document.activeElement?.blur();
    }
};
